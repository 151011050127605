import { useEffect } from 'react';
import { useSelector } from 'react-redux';

import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import Nav from 'react-bootstrap/Nav';
import logo from 'logo_landscape_white.png';

import {
	getUserGroupAuth,
	actionCognitoSignout,
	actionRefreshTokens,
	isSuperuser,
	isOwner,
} from 'pages/auth/redux';

import { LinkContainer } from 'react-router-bootstrap';
import { BANNER } from 'services/urls.js';

const StaffMenu = (props) => {
	if (isSuperuser(props.currentUser)) {
		return (
			<LinkContainer to="/staff">
				<Nav.Link href={'#'}>Staff</Nav.Link>
			</LinkContainer>
		);
	} else {
		return null;
	}
};

const SessionManageMenu = (props) => {
	const { currentUser, clientId, authorisation } = props;

	if (getUserGroupAuth(currentUser, clientId, authorisation, 'admin')) {
		return (
			<LinkContainer to="/manage/session">
				<Nav.Link href={'#'}>Manage Sessions</Nav.Link>
			</LinkContainer>
		);
	} else {
		// no authorisation
		return (
			<Nav.Link href={'#'} disabled>
				Manage Sessions
			</Nav.Link>
		);
	}
};

const Banner = (props) => {
	if (BANNER.length > 0) {
		return (
			<Nav.Link>
				<span style={{ backgroundColor: 'yellow', color: 'blue' }}>
					{BANNER}
				</span>
			</Nav.Link>
		);
	} else {
		return null;
	}
};

const UserMenu = (props) => {
	if (props.authLoading) {
		return null;
	}
	const { currentUser, authorisation } = props;

	const is_logged_in = currentUser.username.length > 3;
	const is_owner = is_logged_in && isOwner(currentUser, authorisation);

	return (
		<>
			{is_logged_in && <Nav.Link>{currentUser.username}</Nav.Link>}
			<NavDropdown title="Account" id="collasible-nav-dropdown" align="end">
				{is_logged_in && (
					<>
						{is_owner && (
							<LinkContainer to="/manage/accounts">
								<NavDropdown.Item href={'#'}>Manage Accounts</NavDropdown.Item>
							</LinkContainer>
						)}
						<LinkContainer to="/manage/emails">
							<NavDropdown.Item href="#">Daily Email</NavDropdown.Item>
						</LinkContainer>
						<NavDropdown.Item onClick={actionCognitoSignout}>
							Logout
						</NavDropdown.Item>
					</>
				)}
				{is_logged_in || (
					<NavDropdown.Item onClick={actionRefreshTokens}>
						Login
					</NavDropdown.Item>
				)}
				<NavDropdown.Divider />
				<LinkContainer to="/privacy">
					<NavDropdown.Item href="#">Privacy</NavDropdown.Item>
				</LinkContainer>
			</NavDropdown>
		</>
	);
};

export const MenuBarMain = (props) => {
	const { showMenu } = props;
	const currentUser = useSelector((state) => state.currentUser);
	const clientId = useSelector((state) => state.clientId);
	const authorisation = useSelector((state) => state.authorisation);
	const authLoading = useSelector((state) => state.authLoading);

	useEffect(() => {
		if (!authLoading && currentUser.username.length < 3) {
			actionRefreshTokens();
		}
	}, [currentUser, authLoading]);

	return (
		<Navbar collapseOnSelect expand="lg" variant="dark">
			<Navbar.Brand href="#home">
				<img
					src={logo}
					height="30"
					className="d-inline-block align-top"
					alt="Optiweigh"
				/>
			</Navbar.Brand>

			<Navbar.Toggle aria-controls="responsive-navbar-nav" />
			{showMenu && (
				<Navbar.Collapse id="responsive-navbar-nav">
					<Nav className="me-auto">
						<NavDropdown title="Reports" id="collasible-nav-dropdown">
							<LinkContainer to="/report/WeightChart">
								<NavDropdown.Item href="/report/WeightChart">
									Weight chart
								</NavDropdown.Item>
							</LinkContainer>
							<LinkContainer to="/report/weightDistribution">
								<NavDropdown.Item href="#">
									Weight distribution
								</NavDropdown.Item>
							</LinkContainer>

							<LinkContainer to="/report/sessionData2">
								<NavDropdown.Item href="#">Session data</NavDropdown.Item>
							</LinkContainer>
							<LinkContainer to="/report/longTermData">
								<NavDropdown.Item href="#">Data over time</NavDropdown.Item>
							</LinkContainer>
						</NavDropdown>
						<SessionManageMenu
							currentUser={currentUser}
							clientId={clientId}
							authorisation={authorisation}
						/>
						<Banner />
					</Nav>
					<Nav className="ms-auto">
						<UserMenu
							currentUser={currentUser}
							authorisation={authorisation}
							authLoading={authLoading}
						/>
						<StaffMenu currentUser={currentUser} />
					</Nav>
				</Navbar.Collapse>
			)}
		</Navbar>
	);
};

import API from 'services/api.js';
import { URL_GET_DBSYS_LOG } from 'services/urls.js';

//export const actionFetchSessionAvg = (sessionId, sessionStartDate, sessionEndDate, showMessage, setLoading) => {
export const actionFetchDbSyslog = (showMessage, setLoading) => {
	console.log('running actionFetchDbsysLog()');

	let resp = [];
	const params = {
		params: {},
		//headers: {
		//'x-opw-format': 'c3',
		//}
	};
	return API.get(URL_GET_DBSYS_LOG, params)
		.then((response) => {
			console.log('response actionFetchDbSyslog()', response);
			resp = response.data;
		})
		.catch((error) => {
			showMessage('Err fetching DbSyslog: ' + error);
		})
		.then(() => {
			setLoading(false);
			return resp;
		});
};

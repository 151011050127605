import { ReactNode } from 'react';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import DatePicker from 'react-datepicker';
import { SessionCloseModel } from './sessionCloseModel';
import Loading from 'components/loading';

/**
 *
 * This is a modal popup window which displays open sessions
 * and asks the user to close the session.
 *
 * The user can select a session close time
 *
 * This is called when
 * - a session close button is clicked
 * - a new session is started when there is a session open
 */

export const SessionCloseModal = (props: { model: SessionCloseModel }) => {
	const {
		isLoading,
		openSessions,
		endTime,
		timezone,
		setSessionEndTime,
		onAcceptClicked,
		onCancelClicked,
	} = props.model;

	return (
		<Template
			show={openSessions.length > 0}
			header={'Close current session'}
			footer={
				<>
					<Loading loading={isLoading} />
					<Button
						id="btnCloseSessions"
						onClick={onAcceptClicked}
						{...(isLoading && { disabled: true })}
					>
						Accept
					</Button>
					<Button
						id="btnQuit"
						variant="outline-primary"
						onClick={onCancelClicked}
						{...(isLoading && { disabled: true })}
					>
						Exit without changes
					</Button>
				</>
			}
		>
			<MainMessage openSessions={openSessions} />

			<Row style={{ height: '37.883px' }}>
				<Col sm="3">
					<p style={{ paddingTop: '0.3em' }}>Session Close</p>
				</Col>
				<Col sm="9">
					<DatePicker
						id="sessionChangeTime"
						className={'datepickerxx form-control'}
						dateFormat="dd MMM yyyy HH:mm"
						selected={endTime}
						showTimeSelect
						todayButton="Now"
						timeIntervals={10}
						scrollableYearDropdown={true}
						showYearDropdown={true}
						onChange={setSessionEndTime}
						style={{ width: '15em' }}
					/>
					<span style={{ paddingLeft: '3em' }}>{timezone}</span>
				</Col>
			</Row>
		</Template>
	);
};

const MainMessage = (props: {
	openSessions: {
		SessionID: number;
		SessionName: string;
	}[];
}) => {
	const postMsg =
		'What time did you move the weigher? Please carefully set the time for the session close. All times are in the session time zone as indicated.';

	return (
		<>
			<p>You are about to close the session:</p>
			<div style={{ textAlign: 'center', fontWeight: 'bold' }}>
				{props.openSessions.length > 0 ? (
					<>
						{props.openSessions.map((row) => (
							<p key={row.SessionID}>
								{row.SessionName} [{row.SessionID}]
							</p>
						))}
					</>
				) : (
					<p>There are NO sessions currently open for this weigher.</p>
				)}
			</div>
			<hr />
			{postMsg}
			<br />
		</>
	);
};

const Template = (props: {
	show?: boolean;
	onHide?: () => void;
	header?: ReactNode;
	children?: ReactNode;
	footer?: ReactNode;
}) => {
	return (
		<Modal
			show={props.show}
			onHide={props.onHide}
			size="lg"
			aria-labelledby="contained-modal-title-vcenter"
			centered
		>
			<Modal.Header closeButton>
				<Modal.Title id="contained-modal-title-vcenter">
					{props.header}
				</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				{props.children}
				<div style={{ height: '5em' }} />
			</Modal.Body>
			<Modal.Footer>{props.footer}</Modal.Footer>
		</Modal>
	);
};

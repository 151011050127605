import React, { useEffect } from 'react';

import { useSelector } from 'react-redux';

import LoginLogoutFlashScreen from 'pages/auth/loginLogoutFlashScreen.js';

import Card from 'react-bootstrap/Card';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import Header from 'components/header.js';

import { ClientSessionSelect } from 'components/clientSessionSelect/clientSessionSelect.js';
import { SessionSummary } from 'components/sessionSummary/sessionSummary.js';
import { ReportsCard } from 'components/reportsCard.js';
import Footer from 'components/footer.js';
import OPWBreadcrumb from 'components/breadcrumb.js';

const TITLE = 'Optiweigh';
const BC = OPWBreadcrumb;

export const Home = (props) => {
	/**
	 * Main product detail page
	 * - the slug is passed and loaded from the products array
	 * This is so it can be loaded from a url or direct in the code
	 */
	const currentUser = useSelector((state) => state.currentUser);
	const authLoading = useSelector((state) => state.authLoading);

	useEffect(() => {
		document.title = TITLE;
	}, []);

	if (authLoading || currentUser.username.length < 3) {
		return <LoginLogoutFlashScreen action={'login'} />;
	} else {
		return (
			<Template>
				<Row>
					<Col sm="9">
						<Card body>
							<Card.Title>Session Summary</Card.Title>
							<Card.Body>
								<ClientSessionSelect
									layout={'select'}
									hideSessionSelect={false}
									handleBeforeSessionChange={() => {}}
									includeYardWeights={true}
									lock={false}
								/>
								<SessionSummary />
							</Card.Body>
						</Card>
					</Col>
					<Col sm="3">
						<ReportsCard />
					</Col>
				</Row>
			</Template>
		);
	}
};

const Template = (props) => (
	<>
		<Header>
			<BC trail="Home" />
		</Header>
		<Container fluid className="body">
			{props.children}
		</Container>
		<Container fluid>
			<Row>
				<Col sm="12">
					<Footer />
				</Col>
			</Row>
		</Container>
	</>
);

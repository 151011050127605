import React from //useEffect, //useState,
'react';

import {
	useSelector,
	//useDispatch,
} from 'react-redux';
import Alert from 'react-bootstrap/Alert';

const msgStyle = {
	margin: '10px auto 0 auto',
	width: '90%',
	textAlign: 'center',
	//backgroundColor: 'rgb(233,236,239)',//blue
	backgroundColor: 'rgb(250,255,18, 0.2)', //yellow with opacity
	//backgroundColor: 'yellow',
};

export const Messages = (props) => {
	//const [visible, setVisible] = useState(true);			//the selected product - state variable
	const messages = useSelector((state) => state.messages); // get from redux store
	//var messages = []
	//useEffect(() => {
	//	const messages = useSelector(state => state.messages);	// get from redux store
	//},[])
	//const store = useStore()
	//const messages = []

	//state = { visible: true }
	//const { messages, header } = this.props
	if (messages.length === 0) {
		return null;
	} else {
		return (
			<div style={{ padding: '1em 1em 1em 1em', marginBottom: '1em' }}>
				<Alert variant={'secondary'} style={msgStyle}>
					{/* <b>{ props.header }</b> */}
					<>
						{messages.map((val, idx) => (
							<p key={idx}>{val}</p>
						))}
					</>
				</Alert>
			</div>
		);
	}
};

// const mapStateToProps = state => {
//   const { messages } = state
//   return {
//     messages
//   }
// }

// export default connect(mapStateToProps)(Messages)

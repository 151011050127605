import React from 'react';
//import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import packageInfo from '../../package.json';

const Footer = () => {
	return (
		<div className="footer text-center">
			<Row>
				<Col className={'pull-left'}>Version: {packageInfo.version}</Col>
				<Col>
					<strong>1300 OPTIWEIGH</strong> - 1300 678 493
				</Col>
				<Col>&copy; 2023 Optiweigh - All rights reserved.</Col>
			</Row>
		</div>
	);
};

export default Footer;

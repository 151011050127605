/**
 *
 * This handles callbacks from the Amazon cognito authentication system
 *
 */

import { useNavigate } from 'react-router-dom';
import { actionLogoutUser, actionFetchTokens } from './redux';

/**
 * Callback is called by the initial caspio login request.
 * It returns a code
 *
 * Keep this sync to avoid the "page flash" keeping the divert last.
 *
 * @param {*} props
 */
export const Callback = () => {
	const navigate = useNavigate();

	const parseSearch = () => {
		const parts = /\/auth\/callback\?code=(.+)$/.exec(window.location.href);
		if (parts.length === 2) return parts[1];
		return null;
	};

	// const parseHash = () => {
	// 	var params = {}
	// 	const hash = props.location.hash.replace(/^#/g, '');//strip # from start of string
	// 	hash.split('&').map(hk => {
	// 		let temp = hk.split('=');
	// 		params[temp[0]] = temp[1]
	// 		return (null)
	// 	});
	// 	return params
	// }

	const handleLogin = async () => {
		//const response = parseHash()
		const code = parseSearch(); //	extract code from returned string
		try {
			await actionFetchTokens(code); //get the tokens from ow auth api using the cognito code
			navigate('/', { replace: true });
		} catch (error) {
			console.log('error in login', error);
			actionLogoutUser();
		}
	};
	handleLogin(); // when all tasks are done sequentially the load the home page
};

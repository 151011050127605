import React from 'react';
import { MenuBarMain } from 'components/menubar.js';
import { Messages } from 'components/messages/messages.js';

const Header = (props) => {
	const showMenu = props.hideMenu === undefined ? true : false;
	return (
		<div className={'no-print banner'}>
			<MenuBarMain showMenu={showMenu} />
			{props.children}
			<Messages />
		</div>
	);
};

export default Header;

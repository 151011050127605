import { combineReducers } from 'redux';
import {
	authLoading,
	currentUser,
	authorisation,
	tokens,
	Authorisations,
	CurrentUser,
} from 'pages/auth/redux';

import {
	clientId,
	clientIds,
	clientIdsLoaded,
	ClientItem,
} from 'components/clientSessionSelect/clientSelect/redux';
import {
	WeigherInfo,
	weighers,
	weighersLoaded,
} from 'components/weighers/reduxSlice';
import { timezones, timezonesLoaded } from 'components/timezones/redux';
import { messages } from 'components/messages/reducers.js';
import { next_path, prev_path } from 'components/utils/reducers.js';
import {
	Session,
	session,
	sessionIds,
} from 'components/clientSessionSelect/sessionSelect/redux';

import { sessionSummary } from 'components/sessionSummary/reducers.js';
import { JwtPayload } from 'jwt-decode';

export interface AppState {
	authLoading: boolean;
	authorisation: Authorisations;
	clientId: number;
	clientIds: ClientItem[];
	clientIdsLoaded: boolean;
	currentUser: CurrentUser;
	messages: string[];
	next_path: string;
	prev_path: string;
	session: Session | null;
	sessionIds: Session[];
	sessionSummary: any;
	tokens: JwtPayload;
	weighers: WeigherInfo[];
	weighersLoaded: boolean;
	timezones: string[];
	timezonesLoaded: boolean;
}

export const initialState: AppState = {
	authLoading: false,
	authorisation: [],
	clientId: 0,
	clientIds: [],
	clientIdsLoaded: false,
	currentUser: { username: '', email: '', groups: [] },
	messages: [],
	next_path: '',
	prev_path: '',
	session: null,
	sessionIds: [],
	sessionSummary: {},
	tokens: {},
	weighers: [],
	weighersLoaded: false,
	timezones: [],
	timezonesLoaded: false,
};

export const rootReducer = combineReducers({
	authLoading,
	authorisation,
	clientId,
	clientIds,
	clientIdsLoaded,
	currentUser,
	messages,
	next_path,
	prev_path,
	session,
	sessionIds,
	sessionSummary,
	tokens,
	weighers,
	weighersLoaded,
	timezones,
	timezonesLoaded,
});

//export default rootReducer

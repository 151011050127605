import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { AppState } from 'reducers';
import { getUserGroupAuth } from './redux';

/**
 * Use this React component to limit access to a page based on user group membership
 * @param {*} props
 */
export const CheckAuth = (props: { groupName: string }) => {
	const currentUser = useSelector((state: AppState) => state.currentUser);
	const authorisation = useSelector((state: AppState) => state.authorisation);
	const clientId = useSelector((state: AppState) => state.clientId);
	const { groupName } = props;
	const navigate = useNavigate();
	useEffect(() => {
		if (!getUserGroupAuth(currentUser, clientId, authorisation, groupName)) {
			navigate('/');
		}
	}, [currentUser, authorisation, clientId, groupName, navigate]);
	return null;
};

import React from 'react';
import { Link } from 'react-router-dom';
import Card from 'react-bootstrap/Card';
import { getUserGroupAuth } from 'pages/auth/redux';
import {
	useSelector,
	//useDispatch
} from 'react-redux';

export const ReportsCard = () => {
	return (
		<Card body>
			<Card.Title>Reports & Links</Card.Title>
			<Card.Body>
				<ul>
					<li>
						<Link to="/report/WeightChart">Weight chart</Link>
					</li>
					{/* <li><Link to = "/report/distributionByWeek">Weight Distribution</Link></li> */}
					<li>
						<Link to="/report/weightDistribution">Weight Distribution</Link>
					</li>
					<li>
						<Link to="/report/sessionData2">Session data</Link>
					</li>
					<li>
						<Link to="/report/longTermData">Data over time</Link>
					</li>
					<SessionManageLink />
				</ul>
			</Card.Body>
		</Card>
	);
};

const SessionManageLink = () => {
	const currentUser = useSelector((state) => state.currentUser);
	const clientId = useSelector((state) => state.clientId);
	const authorisation = useSelector((state) => state.authorisation);

	if (getUserGroupAuth(currentUser, clientId, authorisation, 'admin')) {
		return (
			<>
				<hr />
				<li>
					<Link to="/manage/session" disabled>
						Session Management
					</Link>
				</li>
			</>
		);
	} else {
		return (
			<>
				<hr />
				<li>Login as admin user to manage sessions</li>
			</>
		);
	}
};

import API from 'services/api.js';
import { URL_SESSION_DETAIL, URL_GET_SESSION_CLOSE } from 'services/urls.js';
import { toZonedTime } from 'date-fns-tz';
import { fromZonedTime } from 'date-fns-tz';

export type SessionDetailResponse = {
	SessionID: number;
	Client: number;
	SessionName: string;
	timezone: string | null;
	Head: number | null;
	Paddock: number | null;
	Sex: string | null;
	Age: string | null;
	Size: string | null;
	BreedType: string | null;
	displayUnits: string;
	sessionStartObj: Date;
	sessionEndObj: Date | null;
	WoolGrowth: string | null;
	isOpen: number;
	weighers: number[];
	isYardWeights: boolean;
	species: string;
};
export type SessionValues = {
	SessionName: string;
	sessionStart: Date;
	sessionEnd?: Date | null;
	timezone: string;
	Head?: number;
	Paddock?: number;
	Sex: string;
	Age: string;
	BreedType: string;
	displayUnits?: 'm' | 'i';
	WoolGrowth?: string;
	adjustType?: number;
	adjustFactor?: number;
};
export type SessionUpdateValue = SessionValues & {
	SessionID: number;
};
export type SessionInsertValue = SessionValues & {
	Client: number;
	weighers: number[];
};

export const actionFetchSessionDetail = async (
	sessionId: number,
	clientId: number,
	showMessage?: (msg: string) => void
): Promise<SessionDetailResponse | null> => {
	const params = {
		params: { clientId, sessionId },
		headers: {
			'x-opw-format': '',
		},
	};

	return API.get(URL_SESSION_DETAIL, params)
		.then((response) => {
			if (response.data !== undefined && response.data.length === 1) {
				return response.data[0];
			}
		})
		.catch((error) => {
			if (showMessage)
				showMessage('Err fetching session detail data: ' + error);
			return null;
		})
		.then((resp) => {
			const sessionObjectStart = toZonedTime(
				resp.sessionStartUtc,
				resp.timezone
			);

			const sessionEndObj =
				resp.sessionEndUtc === null || resp.sessionEndUtc === undefined
					? null
					: toZonedTime(resp.sessionEndUtc, resp.timezone);
			const dateObj = {
				sessionStartObj: sessionObjectStart,
				sessionEndObj: sessionEndObj,
			};
			const modified = { ...resp, ...dateObj }; // merge data
			return modified;
		});
};

const convert_dates = (s: SessionInsertValue | SessionUpdateValue) => {
	const obj: any = s;
	obj.sessionStartUtc = fromZonedTime(s.sessionStart, s.timezone).toISOString();
	delete obj.sessionStart;

	if (s.sessionEnd === null) {
		obj.sessionEndUtc = null;
		delete obj.sessionEnd;
	} else if (s.sessionEnd) {
		obj.sessionEndUtc = fromZonedTime(s.sessionEnd, s.timezone).toISOString();
		delete obj.sessionEnd;
	}
	return obj;
};

export const actionInsertSession = async (s: SessionInsertValue) => {
	const config = {
		headers: {
			'Content-Type': 'application/json',
		},
	};

	const json = JSON.stringify(convert_dates(s));
	return API.post(URL_SESSION_DETAIL, json, config).then((response) => {
		if (response.data[0].affectedRows === 0) {
			throw new Error(response.data[0].msg);
		}
	});
};

export const actionUpdateSession = async (s: SessionUpdateValue) => {
	/**
	 * PUT = UPDATE
	 * POST = INSERT
	 */
	const config = {
		headers: {
			'Content-Type': 'application/json',
		},
	};
	const json = JSON.stringify(convert_dates(s));
	return API.put(URL_SESSION_DETAIL, json, config).then((response) => {
		if (response.data[0].updatedRows === 0) {
			throw new Error(response.data[0].msg);
		}
	});
};

export const actionCloseSession = async (
	sessionId: number,
	close: Date,
	timezone: string
) => {
	const params = {
		params: {
			sessionId,
			sessionCloseUtc: fromZonedTime(close, timezone).toISOString(),
		},
	};
	return await API.get(URL_GET_SESSION_CLOSE, params).then((response) => {
		if (response.data[0].updatedRows === 0)
			throw new Error(
				`Cannot close session ${sessionId}. msg: ${JSON.stringify(response)}`
			);
	});
};

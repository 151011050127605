import { useState, useEffect, useCallback, useMemo } from 'react';
import {
	TableControl,
	TableStyles,
	EditableCell,
} from 'components/tableControl.js';
import { NoColumnFilter } from 'components/utils/tableFilters.js';
import styled from 'styled-components';
import { useSelector, useDispatch } from 'react-redux';
import Spinner from 'react-bootstrap/Spinner';
import { actionFetchWeigherIds, actionUpdateWeighers } from './reduxSlice';

import {
	actionSetMessage,
	//actionResetMessages
} from 'components/messages/actions.js';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Loading from 'components/loading.js';
import { actionFetchTimezones } from 'components/timezones/redux';

const WeigherTableStyles = styled.div`
	/* th#visId input {max-width: 4rem;} */
	/* th#eid input {max-width: 8rem;} */
	/* tr {
		td {
			padding-left: 0.2rem;
			padding-right: 0.2rem;
		}
	} */
`;

const showRecCount = 50; // default records to display

export const Weigher = (props) => {
	const { showPulse } = props; // if
	const weighers = useSelector((state) => state.weighers); // grab data from store - should be already loaded
	const weighersLoaded = useSelector((state) => state.weighersLoaded); //initial load event has completed
	const [weighersEdited, setWeighersEdited] = useState([]); // data copy used for editing. synced after save
	const [wloading, setWloading] = useState(false);
	const [dataModified, setDataModified] = useState(false);
	const dispatch = useDispatch();
	const [show, setShow] = useState(false);
	const handleClose = () => setShow(false);
	const { clientId } = props;

	useEffect(() => {
		if (showPulse) {
			setWeighersEdited(weighers);
			setShow(true);
		}
	}, [showPulse, weighers]);

	const showMessage = useCallback(
		(data) => dispatch(actionSetMessage(data)),
		[dispatch]
	);

	useEffect(() => {
		if (clientId > 0 && !weighersLoaded) {
			setWloading(true); //reset loading flag
			dispatch(actionFetchWeigherIds(clientId, setWloading, showMessage));
		}
	}, [weighersLoaded, clientId, showMessage, setWloading, dispatch]);

	useEffect(() => {
		setWeighersEdited(weighers);
	}, [weighers]);

	const handleSave = () => {
		const modifiedSets = weighersEdited.filter((weigher, idx) => {
			return weigher.modified === true;
		});
		if (modifiedSets.length > 0) {
			console.log(`dispatching actionUpdateWeighers`);
			setWloading(true); //reset loading flag
			dispatch(
				actionUpdateWeighers(modifiedSets, clientId, setWloading, showMessage)
			); //this also loads the weigher ids again after updates
		}
		setDataModified(false); //reset flag
	};

	const handleCellUpdate = (rowIndex, columnId, value) => {
		// We also turn on the flag to not reset the page
		//setSkipPageReset(true)
		if (weighersEdited[rowIndex][columnId] !== value) {
			// a change has been made to the data
			setWeighersEdited((old) =>
				old.map((row, index) => {
					if (index === rowIndex) {
						setDataModified(true); //note a change is made
						return {
							...old[rowIndex],
							[columnId]: value,
							modified: true,
						}; // note: added a new element 'modified' to track changed records later
					}
					return row;
				})
			);
		}
	};

	return (
		<Modal
			show={show}
			onHide={handleClose}
			dialogClassName="weigher-edit-modal"
		>
			<Modal.Header closeButton>
				<Modal.Title>Edit weigher unit settings</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				{/* {JSON.stringify(weighersEdited)} */}

				<WeigherTable
					data={weighersEdited}
					handleCellUpdate={handleCellUpdate}
				/>
			</Modal.Body>
			<Modal.Footer>
				<Button variant="secondary" onClick={handleClose}>
					Close
				</Button>
				<Button variant="primary" onClick={handleSave} disabled={!dataModified}>
					{wloading && (
						<Spinner
							as="span"
							animation="border"
							size="sm"
							role="status"
							aria-hidden="true"
						/>
					)}{' '}
					Save Changes
				</Button>
			</Modal.Footer>
		</Modal>
	);
};

function WeigherTable(props) {
	const { data, handleCellUpdate } = props;

	const columns = useMemo(
		() => [
			{
				//Header: 'Animal (every animal ever recorded in this session)',
				Header: 'Weighers',
				Footer: '',
				columns: [
					{
						Header: 'ID',
						Footer: '',
						accessor: 'WeigherID',
						sortType: 'basic',
						style: { textAlign: 'right' },
						Filter: NoColumnFilter,
					},
					{
						Header: 'Name',
						Footer: '',
						accessor: 'name',
						sortType: 'basic',
						Filter: NoColumnFilter,
						style: { textAlign: 'left' },
						Cell: EditableCell,
					},
					{
						Header: 'Default Timezone',
						Footer: '',
						accessor: 'defaultTimezone',
						sortType: 'basic',
						Filter: NoColumnFilter,
						style: { textAlign: 'left' },
						Cell: TzSelectCell,
					},
				],
			},
		],
		[]
	);
	return (
		<TableStyles className="sessionDataTable">
			<WeigherTableStyles>
				<TableControl
					columns={columns}
					data={data}
					showRecCount={showRecCount}
					handleCellUpdate={handleCellUpdate}
				/>
			</WeigherTableStyles>
		</TableStyles>
	);
}

/**
 * a customised table cell with a timezone select control
 */
export const TzSelectCell = ({
	value: initialValue,
	row: { index },
	column: { id },
	handleCellUpdate, // This is a custom function that we supplied to our table instance
}) => {
	const timezones = useSelector((state) => state.timezones);
	const timezonesLoaded = useSelector((state) => state.timezonesLoaded);
	const [wloading, setWloading] = useState(false);
	// We need to keep and update the state of the cell normally
	const [value, setValue] = useState(initialValue);
	const dispatch = useDispatch();

	const showMessage = useCallback(
		(data) => dispatch(actionSetMessage(data)),
		[dispatch]
	);

	useEffect(() => {
		// load the weighers from the api if they havent been loaded
		if (!timezonesLoaded) {
			setWloading(true); //reset loading flag
			dispatch(actionFetchTimezones(setWloading, showMessage));
		}
	}, [timezonesLoaded, showMessage, setWloading, dispatch]);

	const handleChange = (evt) => {
		evt.persist();
		handleCellUpdate(index, id, evt.target.value);
	};

	// If the initialValue is changed external, sync it up with our state
	useEffect(() => {
		setValue(initialValue);
	}, [initialValue]);

	return (
		<>
			{wloading ? (
				<Loading loading={wloading} />
			) : (
				<Form.Control as="select" onChange={handleChange} value={value}>
					<option key={-1} value={''}>
						{'Select timezone ...'}
					</option>
					{timezones.map((val) => {
						return (
							<option key={val.id} value={val.timezone}>
								{val.timezone}
							</option>
						);
					})}
				</Form.Control>
			)}
		</>
	);
};

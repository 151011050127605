import Spinner from 'react-bootstrap/Spinner';

const Loading = (props) => {
	if (props.loading) {
		return (
			<Spinner
				animation="border"
				size={props.size}
				style={{
					color: '#007bff',
					marginTop: '0.3em',
				}}
			/>
		);
	} else {
		return null;
	}
};

export default Loading;

import React, { useState, useEffect, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
	actionSetMessage,
	actionResetMessages,
} from 'components/messages/actions.js';
import { CheckAuth } from 'pages/auth/CheckAuth';
import Header from 'components/header.js';
import Footer from 'components/footer.js';
import Container from 'react-bootstrap/Container';
import OPWBreadcrumb from 'components/breadcrumb.js';
//import Form from 'react-bootstrap/Form';
import Card from 'react-bootstrap/Card';
import { actionFetchWeightChartData, getDownloadFile } from './actions.js';
import { ClientSessionSelect } from 'components/clientSessionSelect/clientSessionSelect.js';
import { GenericDictSelect } from 'components/genericDictSelect.js';
import Loading from 'components/loading.js';
import c3 from 'c3';
import 'c3/c3.min.css';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Alert from 'react-bootstrap/Alert';
//import { Link } from "react-router-dom";
import RptBtns from 'components/rptBtns.js';
import { saveAs } from 'file-saver';
import './weightChart.css';
import { kgToLbs } from 'components/utils/convert-units.js';
//import Checkbox from 'components/checkbox.js';

const TITLE = 'Weight chart';
//const SUBTITLE = 'Average Daily Gain (ADG - shaded area)'
const BC = OPWBreadcrumb;

//var chart02_colors = ['#007bff','#fd7e14', '#dc3545', '#28a745']

/*= () =>{
	return (
		<Breadcrumb>
			<Breadcrumb.Item linkAs={Link} linkProps={{to: '/'}}>Home</Breadcrumb.Item>
			<Breadcrumb.Item linkAs={Link} linkProps={{to: '/'}}>Reports</Breadcrumb.Item>
			<Breadcrumb.Item active>Weight Chart</Breadcrumb.Item>
		</Breadcrumb>
	)
}*/

const ParamPanel = (props) => {
	//const {loading, handleDownload, downloading, timePeriod, setTimePeriod, includeCurrentDay, setIncludeCurrentDay} = props;
	const {
		loading,
		handleDownload,
		downloading,
		timePeriod,
		setTimePeriod,
		showOnlySession,
		setShowOnlySession,
	} = props;

	const handleChange = () => {
		setShowOnlySession((showOnlySession) => !showOnlySession);
	};
	// const handleCurrentDayChange = (evt) => {
	// 	setIncludeCurrentDay(evt.target.checked);
	// }

	return (
		<Card className={'no-print'} body>
			<Card.Title>{TITLE}</Card.Title>
			<Card.Body>
				<Row>
					<Col sm={4}>
						<ClientSessionSelect
							layout={'select'}
							hideSessionSelect={false}
							handleBeforeSessionChange={() => {}}
							lock={loading}
							includeYardWeights={false}
						/>
					</Col>
					<Col sm={4}>
						<TimePeriodSelect
							setTimePeriod={setTimePeriod}
							value={timePeriod}
						/>
						<Row>
							<Col sm={1}>
								<input
									placeholder="Config value"
									defaultChecked={showOnlySession}
									type="checkbox"
									onChange={handleChange}
								/>
							</Col>
							<Col sm={11}>
								<label className="dropdownLabel">Only show session data</label>
								{/* <label>{showOnlySession ? "checked" : "not checked"}</label> */}
							</Col>
						</Row>
					</Col>
					<Col sm={4}>
						<div className={'text-right report-buttons'}>
							<RptBtns
								enablePrint={true}
								enableHelp={false}
								enableDownload={true}
								//handleDownload = {() => { alert('Optiweigh feature coming soon - thanks for your patience.') }}
								handleDownload={() => handleDownload()}
								downloading={downloading}
							/>
						</div>
					</Col>
				</Row>
			</Card.Body>
		</Card>
	);
};

export const TimePeriodSelect = (props) => {
	const { setTimePeriod, value } = props;
	const timePeriodChoices = {
		Date30: 'Last 30 days',
		Date90: 'Last 90 days',
		Date180: 'Last 180 days',
		DateAll: 'All days',
	};
	const handleChangeEvt = (evt) => {
		evt.persist();
		setTimePeriod(evt.target.value);
	};

	return (
		<>
			<GenericDictSelect
				id="moving-avg-timebase"
				label="Date Period"
				dataDict={timePeriodChoices}
				cols={{ lhs: 4, rhs: 8 }}
				controlStyle={{ width: '15em' }}
				handleChange={(evt) => handleChangeEvt(evt)}
				value={value}
				required={'sm'}
				selectDotDotDot={false}
				loading={false}
			/>
		</>
	);
};

export const WeightChart = (props) => {
	const [columns, setColumns] = useState([]); //	local data for graph or report
	const [data01, setData01] = useState([]); //	data for chart01
	const [data02, setData02] = useState([]); //	data for chart02
	const [loading, setLoading] = useState(false); //	local data for graph or report
	const [downloading, setDownloading] = useState(false);
	const session = useSelector((state) => state.session);
	const [c3Package01, setC3Package01] = useState(null); //	package for the c3 graph
	const [c3Package02, setC3Package02] = useState(null); //	package for the c3 graph
	const [timePeriod, setTimePeriod] = useState('Date30');
	const [includeCurrentDay, setIncludeCurrentDay] = useState(true);
	const [showOnlySession, setShowOnlySession] = useState(true);
	const pounds = session && session.displayUnits === 'i';
	const is_sheep = session?.Species?.toUpperCase() === 'SHEEP';
	const wt_decimals = is_sheep ? 1 : 0;
	const adg_decimals = is_sheep ? 2 : 1;

	const dispatch = useDispatch();
	useEffect(() => {
		document.title = TITLE;
	}, []);

	const showMessage = useCallback(
		(data) => dispatch(actionSetMessage(data)),
		[dispatch]
	);

	useEffect(() => {
		dispatch(actionResetMessages());
		async function fetchData() {
			const resp = await actionFetchWeightChartData(
				session.SessionID,
				timePeriod,
				includeCurrentDay,
				showOnlySession,
				showMessage,
				setLoading
			);
			setColumns(resp);
		}
		if (session.SessionID > 0) {
			setLoading(true);
			fetchData();
		}
	}, [
		session,
		timePeriod,
		includeCurrentDay,
		showOnlySession,
		showMessage,
		dispatch,
	]);

	const handleDownload = async () => {
		setDownloading(true);
		const { data } = await getDownloadFile(
			session.SessionID,
			timePeriod,
			includeCurrentDay,
			showOnlySession
		);
		await saveAs(data, 'weight_chart.csv');
		setDownloading(false);
	};

	useEffect(() => {
		// we need to split the api response data into 2 sets for presentation
		const tmpData01 = columns.reduce(function (res, set) {
			if (set[0] === 'date') {
				res.push(set);
			} else if (
				set[0] === 'avg_wt' ||
				set[0] === 'ma5' ||
				set[0] === 'yardWt'
			) {
				res.push([
					set[0],
					...set.slice(1).map((x) => {
						let i = x;
						if (i) {
							if (pounds) {
								i = kgToLbs(i);
							}
							i = i.toFixed(wt_decimals);
						}
						return i;
					}),
				]);
			} else if (set[0] === 'MA5adg') {
				res.push([
					set[0],
					...set.slice(1).map((x) => {
						let i = x;
						if (i) {
							if (pounds) {
								i = kgToLbs(i);
							}
							i = i.toFixed(adg_decimals);
						}
						return i;
					}),
				]);
			}
			return res;
		}, []);
		setData01(tmpData01);

		const tmpData02 = columns.reduce(function (res, set) {
			if (
				set[0] === 'date' ||
				set[0] === 'CountWt' ||
				set[0] === 'animalCount' ||
				set[0] === 'ma5EIDCount' ||
				set[0] === 'recordsPerAnimal'
			) {
				res.push(set);
			}
			return res;
		}, []);
		setData02(tmpData02);
	}, [columns, pounds, wt_decimals, adg_decimals]);

	useEffect(() => {
		const axis01 = {
			x: {
				type: 'timeseries',
				label: {
					text: 'Measurement Date',
					position: 'outer-center',
				},
				tick: {
					format: '%Y-%m-%d',
					rotate: 0,
					multiline: true,
				},
			},
			y: {
				label: {
					text: `Weight (${pounds ? 'lb' : 'kg'})`,
					position: 'outer-middle',
				},
			},
			y2: {
				show: true,
				label: {
					text: `Average daily gain[${pounds ? 'lb' : 'kg'}/day]`,
					position: 'outer-middle',
				},
			},
		};

		const config01 = {
			x: 'date',
			types: {
				avg_wt: 'line',
				MA5adg: 'area-spline',
			},
			axes: {
				ma5: 'y',
				avg_wt: 'y',
				yardWt: 'y',
				MA5adg: 'y2',
			},
			zoom: {
				enabled: true,
			},
			names: {
				MA5adg: 'Mob ADG past 5 days',
				avg_wt: `Raw weight [${pounds ? 'lb' : 'kg'}]`,
				yardWt: `Yard weight [${pounds ? 'lb' : 'kg'}]`,
				ma5: `Rolling mob average[${pounds ? 'lb' : 'kg'}]`,
			},
			hide: ['MA5adg'],
			transition: {
				duration: 1000,
			},
			colors: {
				MA5adg: '#d62728', // red
				ma5: '#2ca02c', // green
				avg_wt: '#2a7ab3', // blue
				yardWt: '#fd7e14', // orange
			},
		};

		setC3Package01({
			bindto: '#chart01',
			data: {
				...{ columns: data01 },
				...config01,
			},
			axis: axis01,
		});
	}, [data01, pounds]);

	useEffect(() => {
		if (c3Package01 !== null) {
			try {
				c3.generate(c3Package01);
			} catch (error) {
				console.log('ERR initialising chart01', error);
				dispatch(
					actionSetMessage('ERR initialising chart: ' + JSON.stringify(error))
				);
			}
		}
	}, [c3Package01, dispatch]);

	useEffect(() => {
		const axis02 = {
			x: {
				type: 'timeseries',
				label: {
					text: 'Measurement Date',
					position: 'outer-center',
				},
				tick: {
					format: '%Y-%m-%d',
					rotate: 0,
					multiline: true,
				},
			},
			y: {
				label: {
					text: 'Number',
					position: 'outer-middle',
				},
			},
			y2: {
				show: true,
				default: [''],
				label: {
					text: '   ',
					position: 'outer-middle',
				},
				// tick: {
				// 	format: function (d) {
				// 		return (parseInt(d) === d) ? d : null;
				// 	}
				// },
			},
		};

		// const size = {height: 240, width: 480}
		// const padding = {top: 40,right: 100,bottom: 40,left: 100,}

		const config02 = {
			x: 'date',
			// type: 'bar',
			types: {
				CountWt: 'bar',
				animalCount: 'bar',
				ma5EIDCount: 'spline',
				recordsPerAnimal: 'bar',
				//animalsInADG: 'spline',
			},
			axes: {
				CountWt: 'y',
				animalCount: 'y',
				ma5EIDCount: 'y',
				recordsPerAnimal: 'y',
				//animalsInADG: 'y2',
			},
			zoom: {
				enabled: true,
			},
			names: {
				CountWt: 'Weight count',
				animalCount: 'Animal count',
				ma5EIDCount: 'Animals in mob average',
				recordsPerAnimal: 'Records/animal',
				//animalsInADG: 'Animals in 7 day ADG',
			},
			transition: {
				duration: 1000,
			},
			colors: {
				CountWt: '#2a7ab3', // blue
				animalCount: '#fd7e14', // orange
				ma5EIDCount: '#cc00ff', // purple
				recordsPerAnimal: '#28a745', // green
			},
		};
		setC3Package02({
			bindto: '#chart02',
			data: { ...{ columns: data02 }, ...config02 },
			axis: axis02,
		});
	}, [data02]);

	useEffect(() => {
		if (c3Package02 !== null) {
			try {
				c3.generate(c3Package02);
			} catch (error) {
				console.log('ERR initialising chart02', error);
				dispatch(
					actionSetMessage('ERR initialising chart: ' + JSON.stringify(error))
				);
			}
		}
	}, [c3Package02, dispatch]);

	return (
		<Template
			loading={loading}
			downloading={downloading}
			handleDownload={handleDownload}
			timePeriod={timePeriod}
			setTimePeriod={setTimePeriod}
			includeCurrentDay={includeCurrentDay}
			setIncludeCurrentDay={setIncludeCurrentDay}
			showOnlySession={showOnlySession}
			setShowOnlySession={setShowOnlySession}
		>
			{columns.length === 0 ? (
				<Alert
					className={'text-center'}
					variant={'secondary'}
					style={{ backgroundColor: 'rgb(233,236,239)' }}
				>
					<Loading loading={loading} />
					<h2>{loading ? 'Loading data...' : 'No data for this session.'}</h2>
				</Alert>
			) : (
				<>
					<h2 className={'only-print'}>Optiweigh - Weight chart</h2>
					<Loading loading={loading} />
					<div id="chart01" />
					<br />
					<hr />
					<br />
					<div id="chart02" />
				</>
			)}
		</Template>
	);
};

const Template = (props) => (
	<div>
		<Header>
			<BC trail='[["Home", "/"], ["reports", "/"], ["weight chart", ""]]' />
		</Header>
		<Container fluid className="body">
			<CheckAuth groupName={'user'} />
			{/*<h2 className='text-center'>{ TITLE }</h2>*/}
			<ParamPanel
				{...props}
				//loading = { props.loading }
				//downloading = { props.downloading }
				//handleDownload = { props.handleDownload }
			/>
			{/* <h3 style={{backgroundColor: 'yellow'}}>** we are currently working on this - we appreciate your patience (30/11/2020)</h3> */}
			{/* <h3 className='text-center'>{ SUBTITLE }</h3> */}
			{props.children}
		</Container>
		<Container fluid>
			<Row>
				<Col sm="12">
					<Footer />
				</Col>
			</Row>
		</Container>
	</div>
);

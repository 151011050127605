/**
 *
 * This handles callbacks from the Amazon cognito authentication system - logout
 *
 */

import React from 'react';
import { actionLogoutUser } from './redux';
import LoginLogoutFlashScreen from './loginLogoutFlashScreen.js';

export const Signout = (props) => {
	console.log('Cognito logout callback complete - Signout component', props);
	actionLogoutUser();
	return <LoginLogoutFlashScreen action={'logout'} />;
};

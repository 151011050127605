import { useState } from 'react';
import { useSelector } from 'react-redux';
import { AppState } from 'reducers';

import Card from 'react-bootstrap/Card';
import Container from 'react-bootstrap/Container';
import OPWBreadcrumb from 'components/breadcrumb.js';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import { CheckAuth } from 'pages/auth/CheckAuth';
import Header from 'components/header.js';

import { Weigher } from 'components/weighers/weigher.js'; //:UNDO:

import 'react-datepicker/dist/react-datepicker.css';
import Footer from 'components/footer.js';
import { ClientSessionSelect } from 'components/clientSessionSelect/clientSessionSelect.js';
import { SessionFilteredList } from './sessionFilteredList';
import { ShowOpenSessionsOnly } from './controls';
import { AiOutlineEdit, AiFillPlusCircle } from 'react-icons/ai';
import { useSessionListModel } from './sessionListModel';
import { SessionCloseModal } from './sessionCloseModal';
import { QueryClient, QueryClientProvider } from 'react-query';

const queryClient = new QueryClient({
	defaultOptions: { queries: { refetchOnWindowFocus: false } },
});

const SessionList = () => {
	const clientId = useSelector((state: AppState) => state.clientId);
	const model = useSessionListModel();

	const [showWeigherModalPulse, setShowWeigherModalPulse] = useState(false);
	const resetShowWeigherModalPulse = () => {
		setShowWeigherModalPulse(false);
	}; //workaround as setTimout cannot call with parameter
	const handleShowWeigherModal = () => {
		// Pulse showWeigherModalPulse high to open modal
		setShowWeigherModalPulse(true);
		setTimeout(resetShowWeigherModalPulse, 1000); //turn off after 1 sec. only needs a pulse to open then all controls are handled by the component
	};
	return (
		<>
			<Card body>
				<Card.Title>Manage Sessions</Card.Title>
				<Card.Body>
					<ClientSessionSelect
						layout="filtered-list"
						handleBeforeSessionChange={() => {}}
						hideSessionSelect={true}
						includeYardWeights={true}
						lock={false}
					>
						<Row className="inputRow">
							<Col sm="3"></Col>
							<Col sm="9">
								<Button
									variant="outline-primary"
									onClick={handleShowWeigherModal}
									size="sm"
									title={'Edit weighers'}
								>
									<AiOutlineEdit size={'20'} /> Edit Weighers
								</Button>
							</Col>
						</Row>
						<Weigher
							setWeigherId={() => {}}
							showPulse={showWeigherModalPulse}
							clientId={clientId || null}
						/>
					</ClientSessionSelect>
				</Card.Body>
			</Card>

			<Card body>
				<Card.Body className="filteredSessionListCard">
					<Button
						size="sm"
						variant="outline-primary"
						disabled={false}
						onClick={model.onNewSession}
						title={'start new session'}
					>
						<AiFillPlusCircle size={'32'} /> New session
					</Button>
					<ShowOpenSessionsOnly
						value={model.showOpenOnly}
						onChange={model.setShowOpenOnly}
					/>

					<SessionFilteredList model={model} />
					{model.sessionCloseModel && (
						<SessionCloseModal model={model.sessionCloseModel} />
					)}
				</Card.Body>
			</Card>
		</>
	);
};

export const SessionManagement = () => {
	return (
		<QueryClientProvider client={queryClient}>
			<CheckAuth groupName={'admin'} />
			<Header>
				<OPWBreadcrumb trail='[["Home", "/"], ["Session management", ""]]' />
			</Header>
			<Container fluid className="body">
				<SessionList />
			</Container>
			<Container fluid>
				<Row>
					<Col sm="12">
						<Footer />
					</Col>
				</Row>
			</Container>
		</QueryClientProvider>
	);
};

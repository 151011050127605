import { CSSProperties, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { QueryClient, QueryClientProvider } from 'react-query';
import { CheckAuth } from 'pages/auth/CheckAuth';
import Card from 'react-bootstrap/Card';
import Container from 'react-bootstrap/Container';
import OPWBreadcrumb from 'components/breadcrumb.js';
import Header from 'components/header.js';
import Footer from 'components/footer.js';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import Button from 'react-bootstrap/Button';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { GenericDictSelect } from 'components/genericDictSelect';
import { GenericListSelect } from 'components/genericListSelect';
import { GenericModal } from 'components/genericModal';
import {
	SessionName,
	SessionEndDate,
	HeadCount,
	PaddockSize,
} from './controls';
import {
	useSessionDetailsModel,
	SessionDetailsModel,
} from './sessionDetailsModel';
import Loading from 'components/loading';
import { Alert } from 'react-bootstrap';

const queryClient = new QueryClient({
	defaultOptions: { queries: { refetchOnWindowFocus: false } },
});

export const SessionDetail = () => {
	const cols = { lhs: 4, rhs: 8 };
	const location = useLocation();

	return (
		<QueryClientProvider client={queryClient}>
			<CheckAuth groupName={'admin'} />
			<Header>
				<OPWBreadcrumb
					trail={
						'[["Home", "/"], ["Session management", "/manage/session"], ["Edit", ""]]'
					}
				/>
			</Header>
			<Container fluid className="body">
				<SessionDetailControls
					cols={cols}
					style={{ width: '20rem' }}
					sessionId={location.state?.edit}
				/>
			</Container>
			<Container fluid>
				<Row>
					<Col sm="12">
						<Footer />
					</Col>
				</Row>
			</Container>
		</QueryClientProvider>
	);
};

const Controls = (props: {
	model: SessionDetailsModel;
	disabled?: boolean;
}) => {
	const { onSubmit, onCancel } = props.model;

	return (
		<div className="formButtons">
			<ButtonGroup>
				<Button
					type="submit"
					variant="outline-primary"
					title="click to save changes"
					{...((props.disabled || !onSubmit) && {
						disabled: true,
						variant: 'outline-secondary',
					})}
					onClick={onSubmit}
				>
					Save changes
				</Button>
				<Button
					variant="outline-primary"
					onClick={onCancel}
					{...(props.disabled && { disabled: true })}
				>
					Exit without saving
				</Button>
			</ButtonGroup>
		</div>
	);
};

/**
 * This is all the detail form controls for setting data in a session
 * @param {} props
 */
const SessionDetailControls = (props: {
	sessionId?: number;
	cols: { lhs: number; rhs: number };
	style?: CSSProperties;
}) => {
	const { sessionId, cols, style } = props;
	const model = useSessionDetailsModel(sessionId);
	const [more, set_more] = useState(false);

	return (
		<Card body>
			<Card.Title>{model.name.value}</Card.Title>
			<Card.Body>
				<SessionName
					cols={cols}
					style={style}
					required={true}
					onChange={model.name.set}
					value={model.name.value}
				/>

				{model.weighers.map((row, idx) => {
					const style =
						idx === model.weighers.length - 1 ? { marginBottom: '20px' } : {};

					return (
						<Row className="inputRow" key={row.id} style={style}>
							<Col sm={cols.lhs}>{idx === 0 && 'Weigher(s):'}</Col>
							<Col sm={cols.rhs} style={{ paddingLeft: '1.6rem' }}>
								{model.setWeigher && (
									<input
										type="checkbox"
										id="Weigher"
										value={row.id}
										checked={row.checked}
										disabled={!row.allowed}
										onChange={(event) => {
											model.setWeigher!(row.id, event.target.checked);
										}}
									/>
								)}
								&nbsp;{row.name} [s/n: {row.id}]
							</Col>
						</Row>
					);
				})}

				{(model.timezone.value !== undefined || model.timezone.set) && (
					<GenericListSelect
						id="timezone"
						label="Timezone"
						dataArray={model.timezone.options}
						cols={cols}
						controlStyle={style}
						handleChange={(evt: any) => {
							if (model.timezone.set) model.timezone.set(evt.target.value);
						}}
						value={model.timezone.value}
						required={!!model.timezone.set}
						invalidMsg="Select a time zone"
						lock={!model.timezone.set}
					/>
				)}

				<Row className="inputRow">
					<Col sm={cols.lhs}>Session start:</Col>
					<Col sm={cols.rhs}>
						<DatePicker
							className={'datepickerxx form-control'}
							dateFormat={'dd MMM yyyy HH:mm'}
							selected={model.startTime.value}
							showTimeSelect
							todayButton={'Now'}
							timeIntervals={15}
							scrollableYearDropdown={true}
							showYearDropdown={true}
							onChange={(date: Date) => {
								if (model.startTime.set) model.startTime.set(date);
							}}
							style={style}
							disabled={!model.startTime.set}
						/>
					</Col>
				</Row>
				{model.endTime && (
					<SessionEndDate
						cols={cols}
						value={model.endTime.value ?? null}
						onChange={model.endTime.set}
						style={style}
						lock={!model.endTime.set}
					/>
				)}
				{model.headCount && (
					<HeadCount
						cols={cols}
						style={style}
						required={true}
						onChange={model.headCount.set}
						value={model.headCount.value}
					/>
				)}
				{model.paddockSize && (
					<PaddockSize
						cols={cols}
						style={style}
						required={true}
						lock={!model.paddockSize?.set}
						onChange={(evt) => {
							if (model.paddockSize?.set) model.paddockSize.set(evt);
						}}
						value={model.paddockSize.value}
					/>
				)}
				{model.breed?.options ? (
					<GenericListSelect
						id="BreedType"
						label="Breed"
						dataArray={model.breed.options}
						cols={cols}
						controlStyle={style}
						handleChange={(evt: any) => {
							if (model.breed?.set) model.breed.set(evt.target.value);
						}}
						value={model.breed.value}
						required={true}
						invalidMsg="Select a Breed type!"
					/>
				) : (
					model.breed && (
						<Row className="inputRow">
							<Col sm={props.cols.lhs}>Breed:</Col>
							<Col sm={props.cols.rhs}>
								<Form.Control
									id="BreedType"
									placeholder="Enter breed..."
									required={true}
									onChange={(evt) => {
										if (model.breed?.set) model.breed.set(evt.target.value);
									}}
									style={style}
									value={model.breed?.value ?? ''}
								/>
							</Col>
						</Row>
					)
				)}
				{model.sex && (
					<GenericDictSelect
						id="Sex"
						label="Sex"
						required={true}
						dataDict={model.sex.options}
						cols={cols}
						controlStyle={style}
						handleChange={(evt: any) => {
							if (model.sex?.set) model.sex.set(evt.target.value);
						}}
						value={model.sex.value}
						invalidMsg="Select Animal sex!"
						selectDotDotDot={true}
						loading={false}
					/>
				)}
				{model.age && (
					<GenericListSelect
						id="Age"
						label="Age"
						dataArray={model.age.options}
						cols={cols}
						controlStyle={style}
						handleChange={(evt: any) => {
							if (model.age?.set) model.age.set(evt.target.value);
						}}
						value={model.age.value}
						required={true}
						invalidMsg="Select Animal age!"
					/>
				)}
				{model.woolGrowth?.set && (
					<GenericDictSelect
						cols={cols}
						controlStyle={{ ...{ width: '15em' }, ...style }}
						required={true}
						label="Wool growth"
						dataDict={model.woolGrowth.options}
						selectDotDotDot={true}
						handleChange={(evt: any) =>
							model.woolGrowth!.set!(evt.target.value)
						}
						value={model.woolGrowth.value}
					/>
				)}
				<Row className="inputRow">
					<Col sm={cols.lhs}>
						<Button variant="link" onClick={() => set_more(!more)}>
							{more ? '- Show less' : '+ Show more'}
						</Button>
					</Col>
				</Row>
				{more && (
					<GenericDictSelect
						label="Display units"
						cols={cols}
						controlStyle={style}
						dataDict={model.displayUnits.options}
						value={model.displayUnits.value}
						handleChange={(evt: any) =>
							model.displayUnits.set!(evt.target.value)
						}
					/>
				)}

				{model.errorMsg && (
					<Row className="inputRow">
						<Col sm={cols.lhs + cols.rhs}>
							<Alert variant="warning">{model.errorMsg}</Alert>
						</Col>
					</Row>
				)}
				{model.autoCloseSessions && (
					<Row className="inputRow">
						<Col sm={cols.lhs + cols.rhs}>
							<Alert>
								The following session{model.autoCloseSessions.length > 1 && 's'}{' '}
								will be automatically closed at the time this new session
								starts:
								<br />
								<ul>
									{model.autoCloseSessions.map((s) => (
										<li key={s}>{s}</li>
									))}
								</ul>
							</Alert>
						</Col>
					</Row>
				)}

				{model.modalMessage && <GenericModal {...model.modalMessage} />}
				<Row className="inputRow">
					<Col sm={cols.lhs}></Col>
					<Col sm={cols.rhs}>
						<Loading loading={model.isLoading} />
					</Col>
				</Row>
				<Row className="inputRow">
					<Col sm={cols.lhs}></Col>
					<Col sm={cols.rhs}>
						<Controls model={model} disabled={model.isLoading} />
					</Col>
				</Row>
			</Card.Body>
		</Card>
	);
};

import { useMutation, useQuery, useQueryClient } from 'react-query';
import API from 'services/api.js';
import { URL_EMAIL_SUBS } from 'services/urls.js';

export type EmailOptions = {
	dailyEmail: boolean;
};
export type EmailSubsModel = {
	isLoading: boolean;
	emails?: EmailOptions;
	changeDailyEmail: (on: boolean) => void;
};

function getEmailSubscriptions(email: string): Promise<EmailOptions> {
	const params = { user: email };
	return API.get(URL_EMAIL_SUBS, { params }).then((resp) => {
		if (resp.data && resp.data.length > 0) return resp.data[0];
		throw Error('bad response');
	});
}

function updateEmailSubscriptions(
	email: string,
	dailyEmail: boolean
): Promise<boolean> {
	const params = { user: email };
	const data = { dailyEmail };
	return API.post(URL_EMAIL_SUBS, data, { params }).then((resp) => {
		console.log(`daily email change response ${resp.status}`);
		return resp.status === 200;
	});
}

export const useEmailSubsModel = (
	email?: string | null,
	initial_value?: EmailOptions | null
): EmailSubsModel => {
	const queryClient = useQueryClient();
	const query = useQuery({
		queryKey: ['get-email-subs', email],
		queryFn: async () => {
			return await getEmailSubscriptions(email!);
		},
		enabled: !!email,
		...(initial_value ? { initialData: initial_value, staleTime: 60000 } : {}),
	});

	const post = useMutation({
		mutationFn: async (enable: boolean) => {
			await updateEmailSubscriptions(email!, enable);
		},
		onSettled: async () => {
			await queryClient.invalidateQueries(['get-email-subs', email]);
		},
	});

	return {
		isLoading: query.isLoading || post.isLoading,
		emails: query.data,
		changeDailyEmail: post.mutate,
	};
};

import { QueryClient, QueryClientProvider } from 'react-query';
import Header from 'components/header.js';
import Footer from 'components/footer.js';
import OPWBreadcrumb from 'components/breadcrumb.js';
import Container from 'react-bootstrap/Container';
import Card from 'react-bootstrap/Card';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import Table from 'react-bootstrap/Table';
import { CrmLink } from 'components/crmLink';
import Loading from 'components/loading';
import Form from 'react-bootstrap/Form';

import { DataItem, UserEditItem, useWeigherModel, WeigherModel } from './model';
import { ReactNode } from 'react';
import { useSearchParams } from 'react-router-dom';

const queryClient = new QueryClient({
	defaultOptions: { queries: { refetchOnWindowFocus: false } },
});

const LinkValue = (v: { target?: string; children: ReactNode }) => {
	if (v.target) {
		return <CrmLink target={v.target}>{v.children}</CrmLink>;
	}

	return v.children;
};
const ValueRow = (props: DataItem) => {
	const { label, current, update } = props;

	return (
		<tr>
			<td>{label}:</td>
			<td>
				<LinkValue target={current.link}>
					{update ? (
						<span style={{ color: 'grey', textDecoration: 'line-through' }}>
							{current.text}
						</span>
					) : (
						<>{current.text}</>
					)}
				</LinkValue>
			</td>
			<td>
				{update && (
					<LinkValue target={update.link}>
						<>{update.text}</>
					</LinkValue>
				)}
				{update?.error && (
					<p style={{ color: 'red', fontSize: '0.75em' }}>{update.error}</p>
				)}
			</td>
		</tr>
	);
};
const TextEdit = (props: { value?: string; setValue: (a: string) => void }) => {
	const { value, setValue } = props;

	return (
		<input
			style={{ width: '16em' }}
			type="text"
			value={value}
			onChange={(e) => setValue(e.target.value)}
		/>
	);
};
const ListEdit = (props: {
	options: string[];
	value?: string;
	setValue: (a: string) => void;
}) => {
	const { options, value, setValue } = props;

	return (
		<Form.Control
			as="select"
			style={{ appearance: 'listbox', width: '16em', display: 'inline' }}
			onChange={(e) => setValue(e.target.value)}
			value={value}
		>
			{options.map((o) => (
				<option key={o}>{o}</option>
			))}
		</Form.Control>
	);
};
const CancelEditButton = (props: any) => {
	return (
		<Button size="sm" variant="outline-primary" {...props}>
			Cancel
		</Button>
	);
};
const EditButton = (props: any) => {
	return (
		<Button size="sm" variant="outline-primary" {...props}>
			Edit
		</Button>
	);
};
const EditableRow = (props: UserEditItem) => {
	const { label, current, update, options, setValue } = props;

	return (
		<tr>
			<td>{label}:</td>
			<td>
				{update ? (
					<span style={{ color: 'grey', textDecoration: 'line-through' }}>
						{current}
					</span>
				) : (
					current
				)}
			</td>
			<td>
				{update != null ? (
					<>
						{Array.isArray(options) ? (
							<ListEdit options={options} value={update} setValue={setValue} />
						) : (
							<TextEdit value={update} setValue={setValue} />
						)}{' '}
						<CancelEditButton
							onClick={() => {
								setValue(null);
							}}
						/>
					</>
				) : (
					<EditButton
						onClick={() => {
							if (update == null) setValue(current);
						}}
					/>
				)}
			</td>
		</tr>
	);
};

const PageTemplate = (props: { children: ReactNode }) => {
	return (
		<QueryClientProvider client={queryClient}>
			<Header>
				<OPWBreadcrumb trail='[["Home", "/"], ["Weigher admin", ""]]' />
			</Header>
			<Container fluid className="body">
				<Row>
					<Col sm="8">
						<Card body style={{ minHeight: '30em' }}>
							{props.children}
						</Card>
					</Col>
				</Row>
			</Container>
			<Container fluid>
				<Footer />
			</Container>
		</QueryClientProvider>
	);
};
const WeigherTable = (props: WeigherModel) => {
	const { canSave, isNew, crmValues, userValues, isSaving, saveHandler } =
		props;

	return (
		<Table>
			<thead>
				<tr>
					<th style={{ width: '10em' }}></th>
					<th style={{ width: '16em' }}>{isNew ? '' : 'Current'}</th>
					<th>Update</th>
				</tr>
			</thead>
			<tbody>
				{crmValues?.map((row) => {
					return <ValueRow key={row.label} {...row} />;
				})}
				{userValues?.map((row) => {
					return <EditableRow key={row.label} {...row} />;
				})}
				<tr>
					<td></td>
					<td></td>
					<td>
						{isSaving ? (
							<Loading loading={true} />
						) : (
							<Button disabled={!canSave} onClick={saveHandler}>
								{isNew ? 'Add weigher' : 'Save Changes'}
							</Button>
						)}
					</td>
				</tr>
			</tbody>
		</Table>
	);
};
const WeigherMain = (props: { weigherId: number }) => {
	const model = useWeigherModel(props.weigherId);

	if (model.isLoading) return <Loading loading={model.isLoading} />;

	if (model.isError) return <>{model.error}</>;

	return (
		<>
			<CrmLink target={model.crmLink} />
			<br />
			<br />
			<WeigherTable {...model} />
		</>
	);
};
export const WeigherAdmin = () => {
	const [params] = useSearchParams();
	const weigher_id = Number(params.get('id'));

	return (
		<PageTemplate>
			<h4>Weigher {weigher_id}</h4>
			<WeigherMain weigherId={weigher_id} />
		</PageTemplate>
	);
};

import API from 'services/api.js';
import { URL_LONG_TERM_DATA } from 'services/urls.js';

export const actionFetchLongTermData = (
	timePeriod,
	dateFrom,
	dateTo,
	sessionId,
	eid,
	oFormat,
	showMessage,
	setLoading
) => {
	let resp = [];
	const params = {
		params: {
			sessionId: sessionId,
			timePeriod: timePeriod,
			dateFrom: dateFrom,
			dateTo: dateTo,
			eid: eid,
		},
		headers: {
			'x-opw-format': oFormat,
		},
	};
	return API.get(URL_LONG_TERM_DATA, params)
		.then((response) => {
			resp = response.data;
		})
		.catch((error) => {
			showMessage('Err fetching session average data: ' + error);
			resp = [];
		})
		.then(() => {
			setLoading(false);
			return resp;
		});
};

export const getDownloadFile = async (
	timePeriod,
	dateFrom,
	dateTo,
	sessionId,
	eid
) => {
	const params = {
		params: {
			sessionId: sessionId,
			timePeriod: timePeriod,
			dateFrom: dateFrom,
			dateTo: dateTo,
			eid: eid,
		},
		responseType: 'blob',
		timeout: 30000,
		headers: {
			'x-opw-format': 'csv',
		},
	};
	return API.get(URL_LONG_TERM_DATA, params)
		.then((response) => {
			return response;
		})
		.catch((error) => {
			//showMessage('Err getDownloadFile: ' + error)
		});
};


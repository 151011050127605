import React from //useEffect, //useState,
//useMemo,
'react';

// import {
// 	useSelector,
// 	useDispatch
// } from 'react-redux';

import { GenericDictSelect } from 'components/genericDictSelect.js';

export const TimeSelectTypes = {
	//MA_7: 'Last 7 days',
	MA_14: 'Last 14 days',
	MA_30: 'Last 30 days',
	MA_90: 'Last 90 days',
	MA_180: 'Last 180 days',
	//MA_30_90: '30-90 days',
	//MA_90_180: '90-180 days',
	MA_all: 'All Data',
};

export const MovingAvgTimeSelect = (props) => {
	const { cols, controlStyle, handleChange, value, size } = props;

	return (
		<>
			<GenericDictSelect
				id="moving-avg-timebase"
				label="ADG Calculation Period"
				dataDict={TimeSelectTypes}
				cols={cols}
				controlStyle={controlStyle}
				handleChange={(evt) => handleChange(evt)}
				value={value}
				required={size}
				selectDotDotDot={true}
				//pattern = {'^\d+$'}	// integer
				//pattern = {'^[a-zA-Z0-9-+]+$'}
				//invalidMsg = 'Select Animal size!'
				loading={false}
			/>
		</>
	);
};

/**
 * Select the date
 * direct edit of calendar
 */
import {
	getLastDayOfWk,
	getFirstDayOfMth,
	//getLastDayOfMth
} from 'components/utils/dateLib.js';
import DatePicker from 'react-datepicker';
import isValid from 'date-fns/isValid';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

export const stdDateFormatStr = 'yyyy-MM-dd';

const processMinMax = (dateIn, min, max) => {
	if (isValid(min) && dateIn.getTime() < min.getTime()) {
		return min;
	}
	if (isValid(max) && dateIn.getTime() > max.getTime()) {
		return max;
	} else {
		return dateIn;
	}
};

/*
	change a date to being appropriate for it's time period
	Always returns a valid date. Returns now if given a bad date
*/
export const shoeBoxDate = (dateIn, timePeriod, min, max) => {
	if (isValid(dateIn)) {
		switch (timePeriod) {
			case 'week':
				//return getLastDayOfWk(dateIn);
				return processMinMax(getLastDayOfWk(dateIn), min, max);
			case 'month':
				return processMinMax(getFirstDayOfMth(dateIn), min, max);
			case 'day': //fallthrough
			default:
				return processMinMax(dateIn, min, max);
		}
	} else {
		return shoeBoxDate(new Date(), timePeriod, min, max);
	}
};

export const DateParamControl = (props) => {
	const {
		dateParam,
		setDateParam,
		lowerDate,
		//setLowerDate,
		upperDate,
		//setUpperDate,
		timePeriod,
		//session,
		loading,
		dayLabel,
	} = props;

	const handleDateChange = (dateIn) => {
		console.log('handleDateChange', shoeBoxDate(dateIn, timePeriod));
		setDateParam(shoeBoxDate(dateIn, timePeriod, lowerDate, upperDate));
	};

	const filterDays = (date) => {
		// Disable all days except for week end
		if (date.getDay() === 0) {
			return true;
		} else {
			return false;
		}
	};

	switch (timePeriod) {
		case 'day':
			return (
				<Row className="inputRow">
					<Col sm={5}>
						<label className="dropdownLabel">{dayLabel}</label>
					</Col>
					<Col sm={7}>
						<DatePicker
							className={'datepicker form-control'}
							dateFormat={stdDateFormatStr}
							selected={dateParam}
							startDate={lowerDate}
							minDate={lowerDate}
							maxDate={upperDate}
							scrollableYearDropdown={true}
							showYearDropdown={true}
							onChange={handleDateChange}
							//style = {{textAlign: 'center'}}
							{...(loading && { disabled: true })}
						/>
					</Col>
					{/* <div className='form-text'>{ help }</div> */}
				</Row>
			);
		case 'week':
			return (
				<Row>
					<Col sm={5}>
						<p style={{ paddingTop: '0.3em' }}>Week Ending:</p>
					</Col>
					<Col sm={7}>
						<DatePicker
							className={'datepicker'}
							dateFormat={stdDateFormatStr}
							selected={dateParam}
							filterDate={filterDays}
							scrollableYearDropdown={true}
							showYearDropdown={true}
							onChange={handleDateChange}
							//style = {{textAlign: 'center'}}
							{...(loading && { disabled: true })}
						/>
					</Col>
					{/* <div className='form-text'>{ help }</div> */}
				</Row>
			);
		case 'month':
			return (
				<Row>
					<Col sm={5}>
						<p style={{ paddingTop: '0.3em' }}>Month:</p>
					</Col>
					<Col sm={7}>
						<DatePicker
							className={'datepicker'}
							//dateFormat = { formatStr }
							dateFormat="MMM yyyy"
							selected={dateParam}
							startDate={lowerDate}
							minDate={lowerDate}
							maxDate={upperDate}
							scrollableYearDropdown={true}
							showYearDropdown={true}
							onChange={handleDateChange}
							showMonthYearPicker
							//style = {{textAlign: 'center'}}
							{...(loading && { disabled: true })}
						/>
					</Col>
					{/* <div className='form-text'>{ help }</div> */}
				</Row>
				// <MonthControl
				// 	reqd = {false}
				// 	cols = {{lhs: 4, rhs: 8}}
				// 	handleChange = {(evt) => handleDateChange( evt )}
				// 	value = { dateParam || '2020-01-01' }
				// 	lock = { loading }
				// />
			);
		default:
			return null;
	}
};

import React, {
	//useState,
	useEffect,
	//useMemo,
	//useCallback,
} from 'react';

import Header from 'components/header.js';
import Footer from 'components/footer.js';
import Container from 'react-bootstrap/Container';
import Breadcrumb from 'react-bootstrap/Breadcrumb';
import Alert from 'react-bootstrap/Alert';
import { Link } from 'react-router-dom';
import { CheckAuth } from 'pages/auth/CheckAuth';
/**
 * YardWeights - a help and information screen for the manual entering of yard weights
 */

const TITLE = 'Yard Weights';
//const showRecCount = 50	// default records to display
const BC = () => {
	return (
		<Breadcrumb>
			<Breadcrumb.Item linkAs={Link} linkProps={{ to: '/' }}>
				Home
			</Breadcrumb.Item>
			<Breadcrumb.Item linkAs={Link} linkProps={{ to: '/staff' }}>
				Staff
			</Breadcrumb.Item>
			<Breadcrumb.Item active>Yard Weights</Breadcrumb.Item>
		</Breadcrumb>
	);
};

export const YardWeights = (props) => {
	useEffect(() => {
		document.title = TITLE;
	}, []);
	//const cols = {lhs: 3, rhs: 9}
	return (
		<Template>
			<YardWeightsContent />
		</Template>
	);
};

const Template = (props) => (
	<Container fluid>
		<CheckAuth groupName={'superuser'} />
		<Header>
			<BC />
		</Header>

		{props.children}
		<Footer />
	</Container>
);

export const YardWeightsContent = (props) => {
	return (
		<Alert
			className="sessSummary container"
			variant={'secondary'}
			style={{ backgroundColor: 'rgb(233,236,239)' }}
		>
			<h5>Yard Weights: manual entry</h5>
			<p>
				Yard weights are manual animals weights recorded outside the optiweights
				system eg. on yard scales. The weights by animal can be imported into
				the database to be included in aggregate reporting.
			</p>
			<p>
				The following outlines the requirements to manually enter yard weights.
			</p>
			<hr />
			<p>
				Currently all weights need to be recorded against a session which then
				links to a client etc. this case of session is a "special" perpetual{' '}
				<i>yardweight session</i> which has no start or end dates. It needs to
				contain the client ID and set a flag "sysYardWeights". There should only
				be a single <i>yardweight session</i> like this per client to link all
				yard weights.
			</p>
			Example:
			<pre>
				INSERT INTO Sessions (Client, SessionName, sysYardWeights) VALUES (20,
				'system use - yard weights', true);
			</pre>
			<hr />
			<p>
				Once a <i>yardweight session</i> for the client is established, the
				weight data can be inserted. Note that the raw weight needs to be
				calculated using the same calculation (refer xxx) as the wbw field. This
				can be done in the sql as per the example below.
			</p>
			<p>
				It is important to set the usefilter and lockUsefilter to avoid the
				readings being later altered by system filters.
			</p>
			Example:
			<pre>
				INSERT INTO optiweights (weigherId, sourceRef, rawWeight,
				dateRecordedUTC, eid, useFilter, useFilterNotes, lockUsefilter, session)
				VALUES (NULL, 'manual', (190/xxxx), '2017-01-01 14:00:00', '982
				123474076889', 1, 'manually set', 1, 676);
			</pre>
		</Alert>
	);
};

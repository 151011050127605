import React from 'react';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import Spinner from 'react-bootstrap/Spinner';
import Button from 'react-bootstrap/Button';
import {
	AiOutlineDownload,
	AiOutlinePrinter,
	AiOutlineQuestion,
} from 'react-icons/ai';

const RptBtns = (props) => {
	const {
		enablePrint,
		enableDownload,
		enableHelp,
		handleDownload,
		downloading,
	} = props;
	return (
		<ButtonGroup className="mb-2">
			<Button
				variant="outline-primary"
				disabled={!enablePrint}
				onClick={() => window.print()}
			>
				<AiOutlinePrinter /> Print
			</Button>
			<Button
				variant="outline-primary"
				disabled={!enableHelp}
				onClick={() => {}}
			>
				<AiOutlineQuestion /> Help
			</Button>
			<Button
				variant="outline-primary"
				disabled={!enableDownload}
				onClick={() => handleDownload()}
			>
				{downloading && (
					<Spinner
						as="span"
						animation="border"
						size="sm"
						role="status"
						aria-hidden="true"
					/>
				)}
				<AiOutlineDownload />
				Download
			</Button>
		</ButtonGroup>
	);
};
export default RptBtns;

import Header from 'components/header.js';
import Footer from 'components/footer.js';
import OPWBreadcrumb from 'components/breadcrumb.js';
import Container from 'react-bootstrap/Container';
import Card from 'react-bootstrap/Card';
import { Button } from 'react-bootstrap';
import { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from 'hooks';
import {
	actionSyncAccountFromZoho,
	actionFetchZohoAccount,
	ClientDetails,
} from './actions';
import Loading from 'components/loading.js';
import {
	actionResetMessages,
	actionSetMessage,
} from 'components/messages/actions.js';
import { useSearchParams } from 'react-router-dom';

const ACCOUNTS_PATH = '/manage/accounts';

const redirect_to_accounts = () => {
	window.location.replace(window.location.origin + ACCOUNTS_PATH);
};
const jump_to_accounts = (zoho_id: bigint | null) => {
	window.location.href =
		window.location.origin +
		ACCOUNTS_PATH +
		(zoho_id ? `?zohoCrmId=${zoho_id}` : '');
};

const AddAccount = (props: {
	name?: string;
	link?: string;
	handleAdd?: () => void;
	handleCancel?: () => void;
}) => {
	const { link, name, handleAdd, handleCancel } = props;
	return (
		<div style={{ maxWidth: '35rem' }}>
			<p>
				Would you like to add&nbsp;
				<a href={link} target="_blank" rel="noreferrer">
					{name}
				</a>{' '}
				to the database?
			</p>
			<p style={{ float: 'right' }}>
				<Button onClick={handleCancel} variant="outline-secondary">
					Cancel
				</Button>
				&nbsp;
				<Button onClick={handleAdd} variant="primary">
					Add {name}
				</Button>
			</p>
		</div>
	);
};

export const NewAccount = () => {
	const [params] = useSearchParams();
	const zoho_id = params.get('zohoCrmId');
	const dispatch = useAppDispatch();
	const currentUser = useAppSelector((state) => state.currentUser);
	const [is_loading, set_loading] = useState(false);
	const [account, set_account] = useState<ClientDetails | null>(null);

	useEffect(() => {
		if (currentUser.username.length <= 3) return;
		if (is_loading) return;
		if (account) return;
		set_loading(true);
		if (zoho_id) {
			dispatch(actionResetMessages());
			actionFetchZohoAccount(BigInt(zoho_id))
				.then((data) => {
					if (data.length > 0) set_account(data[0]);
					else {
						dispatch(actionSetMessage('Error: Account not found'));
					}
				})
				.finally(() => set_loading(false));
		} else {
			redirect_to_accounts();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [dispatch, currentUser]);
	const handle_add = () => {
		dispatch(actionResetMessages());
		if (account?.ZohoCrmId) {
			const id = BigInt(account.ZohoCrmId);
			actionSyncAccountFromZoho(id)
				.then(() => jump_to_accounts(id))
				.catch((err) => {
					dispatch(actionSetMessage(err));
				});
		}
	};

	return (
		<>
			<Header>
				<OPWBreadcrumb trail='[["Home", "/"], ["Account management", "/manage/accounts"], ["New account", ""]]' />
			</Header>
			<Container fluid className="body">
				<Card body>
					<Card.Title>New Account</Card.Title>
					<Card.Body>
						{account && (
							<AddAccount
								name={account.TradingName}
								link={account.link}
								handleAdd={handle_add}
								handleCancel={() => jump_to_accounts(null)}
							/>
						)}
						<Loading loading={is_loading} />
					</Card.Body>
				</Card>
			</Container>
			<Container fluid>
				<Footer />
			</Container>
		</>
	);
};

import ListGroup from 'react-bootstrap/ListGroup';
import { AiOutlineEdit, AiOutlineCloseCircle } from 'react-icons/ai';
import Button from 'react-bootstrap/Button';
import { SessionListModel } from './sessionListModel';

/**
 * This is a custom session list select for the session management
 * - filter by open sessions only or all
 * - filter by a single weigherId
 * - Selection to callback fn instead of setting store sessionId
 *
 * @param {*} props
 */
export const SessionFilteredList = (props: { model: SessionListModel }) => {
	const { sessions, onSessionEdit, onSessionClose } = props.model;

	if (sessions.length === 0) {
		return (
			<div className="filteredSessionListNotAvailable">
				<h6>No sessions available</h6>
			</div>
		);
	}

	return (
		<ListGroup as="ul" className="filteredSessionListUL">
			{sessions.map((row) => {
				const ws = row.weighers.join(',');
				return (
					<ListGroup.Item
						key={row.SessionID}
						className={row.isOpen ? 'openSession' : ''}
					>
						<div className="sessionDetails">
							<h6>{row.SessionName}</h6>{' '}
							<span className="note">
								ID: {row.SessionID}
								{ws && ', s/n: ' + ws}
							</span>
							<br />
							<p className="sessionStatus">Status: {row.status}</p>
						</div>
						<div className="sessionActions">
							<Button
								size="sm"
								variant="outline-primary"
								disabled={false}
								onClick={() => onSessionEdit(row.SessionID)}
								title={'Edit session'}
							>
								<AiOutlineEdit size={'20'} />
								Edit
							</Button>{' '}
							{!!row.isOpen && (
								<Button
									size="sm"
									variant="outline-primary"
									disabled={false}
									onClick={() => onSessionClose(row.SessionID)}
									title={'Close session'}
								>
									<AiOutlineCloseCircle size={'20'} />
									Close
								</Button>
							)}
						</div>
					</ListGroup.Item>
				);
			})}
		</ListGroup>
	);
};

import API from 'services/api.js';
import axios from 'axios';
import { jwtDecode, JwtPayload } from 'jwt-decode';
import { store } from 'index';
import {
	URL_AUTH_LOGIN,
	URL_AUTH_LOGOUT,
	URL_GET_USER_AUTHORISATION,
	URL_OWAUTH_LOGIN,
} from 'services/urls.js';

import { actionSetMessage } from 'components/messages/actions.js';
import { redirect } from 'react-router-dom';
import { AppState } from 'reducers';
import { Dispatch } from 'redux';

const SET_AUTH_LOADING = 'SET_AUTH_LOADING';
const UPDATE_USER = 'UPDATE_USER';
const RESET_USER = 'RESET_USER';
const UPDATE_TOKENS = 'UPDATE_TOKENS';
const RESET_TOKENS = 'RESET_TOKENS';
const UPDATE_AUTH = 'UPDATE_AUTH';
const RESET_AUTH = 'RESET_AUTH';

export type CurrentUser = {
	username: string;
	email: string;
	groups?: string[];
};
export type Authorisations = { clientId: number; groupCode: string }[];

/**
 * Checks if an auth token exists and is expired.
 * Refreshed the token if expired.
 *
 * returns true if successful
 * diverts to login if failed
 */
export const checkTokenExpiration = async () => {
	/**
	 * This is non standard code as the store should be accessed outside of the react component
	 * https://daveceddia.com/access-redux-store-outside-react/
	 */
	try {
		const state = store.getState() as AppState;
		if (
			state.tokens.exp === undefined ||
			state.tokens.exp - 60 < Date.now() / 1000
		) {
			// expired
			await actionRefreshTokens();
		}
		return true;
	} catch (err) {
		console.log('Error getting user token exp - log out user', err);
		await actionLogoutUser();
	}
	return false;
};

const actionResetUserObject = () => ({
	type: 'RESET_USER',
});

const actionUpdateUserObject = (userObj: CurrentUser) => ({
	type: 'UPDATE_USER',
	payload: userObj,
});

const actionResetTokens = () => ({
	type: 'RESET_TOKENS',
});

const actionUpdateTokens = (tokens: JwtPayload) => ({
	type: 'UPDATE_TOKENS',
	payload: tokens,
});

export const actionCognitoSignout = () => {
	window.location.href = URL_AUTH_LOGOUT; //	initiate Cognito login
};

export const actionLogoutUser = () => {
	store.dispatch(actionSetAuthLoading(true));

	return axios
		.delete(URL_OWAUTH_LOGIN, { withCredentials: true })
		.then(() => {
			store.dispatch(actionResetUserObject());
			store.dispatch(actionResetTokens());
		})
		.catch((error) => {
			console.log('actionLogoutUser() error', error);
			console.log('Error msg', error.message);
			if (error.response) {
				// The request was made and the server responded with a status code
				// that falls out of the range of 2xx
				console.log('e data', error.response.data);
				console.log('e status', error.response.status);
				console.log('e headers', error.response.headers);
			}
		})
		.finally(() => {
			store.dispatch(actionSetAuthLoading(false));
		});
};

export const isSuperuser = (currentUser: CurrentUser) => {
	return currentUser.groups?.includes('superusers');
};
/** Is the user an 'owner' for any client account
 */
export function isOwner(
	currentUser: CurrentUser,
	authorisation: Authorisations
) {
	return (
		isSuperuser(currentUser) ||
		authorisation.reduce((res, set) => {
			return (
				res || set.groupCode === 'owner' || set.groupCode === 'techsupport'
			);
		}, false)
	);
}

const getGroups = (authorisation: Authorisations, clientId: number) => {
	if (clientId > 0 && authorisation.length > 0) {
		return authorisation.reduce(function (res: string[], set) {
			if (set.clientId === Number(clientId)) {
				res.push(set.groupCode);
			}
			return res;
		}, []);
	}
	return [];
};

const isUserInGroup = (
	authorisation: Authorisations,
	clientId: number,
	groupName: string
) => {
	const groups = getGroups(authorisation, clientId);

	if (groups.includes(groupName)) return true;

	const is_tech_support = groups.includes('techsupport');
	const is_owner = is_tech_support || groups.includes('owner');
	const is_admin = is_owner || groups.includes('admin');
	const is_user = is_admin || groups.includes('user');

	if (groupName === 'owner') return is_owner;
	if (groupName === 'admin') return is_admin;
	if (groupName === 'user') return is_user;
	return false;
};

/**
 *
 * Use this method to return true/false if user has permission
 * to groupName priveledges for the clientId
 */

export const getUserGroupAuth = (
	currentUser: CurrentUser,
	clientId: number,
	authorisation: Authorisations,
	groupName: string
) => {
	if (currentUser.username.length < 3) {
		//fail
		return false;
	}
	if (
		isSuperuser(currentUser) ||
		isUserInGroup(authorisation, clientId, groupName)
	) {
		return true;
	} else {
		// no privileges
		return false;
	}
};

/**
 * AuthLoading flag is a global used to hide screens etc while the token
 * loading system completes. It is used to avoid page flicker etc
 */

export const actionSetAuthLoading = (status: boolean) => {
	return { type: SET_AUTH_LOADING, status };
};

/**
 * ##### user authorisation - from db mapping table
 *
 */

export const actionResetAuthorisation = () => {
	return { type: RESET_AUTH };
};

export const actionSetAuthorisation = (data: Authorisations) => {
	return {
		type: UPDATE_AUTH,
		data,
	};
};

export const actionFetchAuthorisation = () => {
	return (dispatch: Dispatch) => {
		return API.get(URL_GET_USER_AUTHORISATION)
			.then((response) => {
				dispatch(actionSetAuthorisation(response.data));
			})
			.catch((error) => {
				console.log('error', error);
				dispatch(
					actionSetMessage('Error fetching user authorisation: ' + error)
				);
			});
	};
};

export const actionLoginUser = () => {
	window.location.href = URL_AUTH_LOGIN; //	initiate Cognito login
};

const setCurrentUser = (idTokenObj: any) => {
	store.dispatch(
		actionUpdateUserObject({
			username: idTokenObj['cognito:username'],
			email: idTokenObj.email,
			groups: idTokenObj['cognito:groups'],
		})
	);
};

/**
 * actionFetchTokens()
 * Calls the auth API (POST) to get the real tonens and user object.
 * The tokens are saved automatically in the cookies and sent with the browser in each call to the auth api domain
 *
 * Need to store the user user objects separately to the token/expiries so the tokens can be refreshed
 * without triggering the listeners on the change of user. Without this loop behavior occurs.
 *
 * @param {*} code
 */

export const actionFetchTokens = (code: string) => {
	store.dispatch(actionSetAuthLoading(true));
	const config = {
		headers: {
			'Content-Type': 'application/json', // Overwrite Axios's automatically set Content-Type
		},
		withCredentials: true,
	};
	const json = JSON.stringify({ authorisation_code: code });
	return axios
		.post(URL_OWAUTH_LOGIN, json, config)
		.then((response) => {
			const idTokenObj = jwtDecode(response.data.tokens.id_token);
			setCurrentUser(idTokenObj);
			store.dispatch(
				actionUpdateTokens({
					...response.data.tokens,
					...{ exp: idTokenObj.exp },
				})
			); // include the expiry in the token data
		})
		.catch((error) => {
			console.log('actionFetchTokens() error', error);
			if (error.response) {
				// The request was made and the server responded with a status code
				// that falls out of the range of 2xx
				if (error.response.status === 400) {
					// auth error
					console.log('400 error', error);
					actionLoginUser();
				} else if (error.response.status === 500) {
					// server error
					console.log('500 error', error);
					store.dispatch(actionSetMessage('Server error (500): ' + error));
				} else {
					//	unknown app error
					console.log('not 400 or 500 error', error);
					store.dispatch(actionSetMessage('App error: ' + error));
				}
			} else if (error.request) {
				// The request was made but no response was received
				// `error.request` is an instance of XMLHttpRequest in the browser and an instance of
				// http.ClientRequest in node.js
				store.dispatch(
					actionSetMessage('No server response: ' + error.request)
				);
			} else {
				// Something happened in setting up the request that triggered an Error
				console.log('Error msg', error.message);
				store.dispatch(actionSetMessage('Error (unknown): ' + error.message));
			}
		})
		.then(() => {
			store.dispatch(actionSetAuthLoading(false));
			// return (<Redirect  to="/" />)
		});
};

/**
 * actionRefreshTokens()
 *
 */
export const actionRefreshTokens = async () => {
	const state = store.getState() as AppState;
	return await axios
		.get(URL_OWAUTH_LOGIN, { withCredentials: true })
		.then((response) => {
			const idTokenObj = jwtDecode(response.data.tokens.id_token);
			if (state.currentUser.username.length < 3) {
				setCurrentUser(idTokenObj);
			}
			//do NOT store exp with user - listeners will loop
			store.dispatch(
				actionUpdateTokens({
					...response.data.tokens,
					...{ exp: idTokenObj.exp },
				})
			); // include the expiry in the token data
			return redirect('/');
		})
		.catch((error) => {
			console.log('error actionRefreshTokens()', error);
			if (error.response) {
				// The request was made and the server responded with a status code
				// that falls out of the range of 2xx
				if (error.response.status === 400) {
					//	auth error
					console.log('400 error', error);
					actionLoginUser();
				} else if (error.response.status === 500) {
					//	server error
					console.log('500 error', error);
					store.dispatch(actionSetMessage('Server error (500): ' + error));
				} else {
					console.log('not 400 or 500 error');
					store.dispatch(actionSetMessage('App error: ' + error));
				}
			} else if (error.request) {
				// The request was made but no response was received
				// `error.request` is an instance of XMLHttpRequest in the browser and an instance of
				// http.ClientRequest in node.js
				console.log('e resuest', error.request);
				store.dispatch(
					actionSetMessage('No server response: ' + error.request)
				);
			} else {
				// Something happened in setting up the request that triggered an Error
				console.log('Error msg', error.message);
				store.dispatch(actionSetMessage('Error (unknown): ' + error.message));
			}
		});
};

export function authLoading(
	state = false,
	action: { type: string; status?: boolean }
) {
	switch (action.type) {
		case SET_AUTH_LOADING:
			return action.status;
		default:
			return state;
	}
}

export function currentUser(
	state: CurrentUser = { username: '', email: '', groups: [] },
	action: { type: string; payload?: CurrentUser }
) {
	switch (action.type) {
		case UPDATE_USER:
			return { ...state, ...action.payload };
		case RESET_USER:
			return { username: '', email: '', groups: [] };
		default:
			return state;
	}
}

export function tokens(
	state = {},
	action: { type: string; payload?: JwtPayload }
) {
	switch (action.type) {
		case UPDATE_TOKENS:
			return { ...state, ...action.payload };
		case RESET_TOKENS:
			return {};
		default:
			return state;
	}
}

export function authorisation(
	state: Authorisations = [],
	action: { type: string; data?: Authorisations }
) {
	switch (action.type) {
		case UPDATE_AUTH:
			return action.data;
		case RESET_AUTH:
			return [];
		default:
			return state;
	}
}

import React, { useState, useEffect, useMemo, useCallback } from 'react';
import {
	//useSelector,
	useDispatch,
} from 'react-redux';
import {
	actionSetMessage,
	actionResetMessages,
} from 'components/messages/actions.js';
import {
	NoColumnFilter,
	SelectColumnFilter,
	//GlobalFilter,
	//DefaultColumnFilter,
	//fuzzyTextFilterFn
} from 'components/utils/tableFilters.js';
import { CheckAuth } from 'pages/auth/CheckAuth';
import Header from 'components/header.js';
import Footer from 'components/footer.js';
import Container from 'react-bootstrap/Container';
import Breadcrumb from 'react-bootstrap/Breadcrumb';
import Button from 'react-bootstrap/Button';
import Loading from 'components/loading.js';

import { TableControl, TableStyles } from 'components/tableControl.js';

import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Alert from 'react-bootstrap/Alert';
import { Link } from 'react-router-dom';
import { actionFetchDbSyslog } from './actions.js';

const TITLE = 'Session Data';
const showRecCount = 50; // default records to display
const BC = () => {
	return (
		<Breadcrumb>
			<Breadcrumb.Item linkAs={Link} linkProps={{ to: '/' }}>
				Home
			</Breadcrumb.Item>
			<Breadcrumb.Item linkAs={Link} linkProps={{ to: '/staff' }}>
				Staff
			</Breadcrumb.Item>
			<Breadcrumb.Item active>DBsys Log</Breadcrumb.Item>
		</Breadcrumb>
	);
};

//:TODO: add totals on footer https://react-table.tanstack.com/docs/examples/footers

const ParamPanel = (props) => {
	const { loading, handleReload } = props;
	//const cols = {lhs:4, rhs:8}
	//const controlStyle = {width: '15em'}

	return (
		<Alert
			className={'no-print'}
			variant={'secondary'}
			style={{ backgroundColor: 'rgb(233,236,239)' }}
		>
			<Row>
				<Col sm={4}>
					<Button variant="info" onClick={() => handleReload()}>
						Reload data
					</Button>
				</Col>
				<Col sm={4}>
					Note: data retention: 7 days. Logs include database trigger
					information.
					<div className={'w-50 mx-auto'}>
						<Loading loading={loading} />
					</div>
				</Col>
				<Col sm={4}></Col>
			</Row>
		</Alert>
	);
};

const NoData = () => <h1>No data</h1>;

// pagination
//https://medium.com/better-programming/an-introduction-to-react-table-6ebd34d8059e
export const DbsysLog = (props) => {
	/**
	 *
	 *
	 *
	 */

	const [data, setData] = useState([]); //	local data for graph or report
	const [loading, setLoading] = useState(false);
	const dispatch = useDispatch();

	useEffect(() => {
		document.title = TITLE;
	}, []);

	const showMessage = useCallback(
		(data) => dispatch(actionSetMessage(data)),
		[dispatch]
	);

	const handleReload = () => {
		async function fetchData() {
			dispatch(actionResetMessages());
			const resp = await actionFetchDbSyslog(showMessage, setLoading);
			setData(resp);
		}
		setLoading(true);
		fetchData();
	};

	useEffect(() => {
		async function fetchData() {
			dispatch(actionResetMessages());
			const resp = await actionFetchDbSyslog(showMessage, setLoading);
			setData(resp);
		}

		setLoading(true);
		fetchData();
	}, [showMessage, setLoading, dispatch]);

	if (data === undefined) {
		return (
			<Template loading={loading} handleReload={handleReload}>
				<NoData />
			</Template>
		);
	} else {
		return (
			<Template loading={loading} handleReload={handleReload}>
				<h2 className={'only-print'}>Optiweigh - Session data</h2>
				<DataTable data={data} />
			</Template>
		);
	}
};

const Template = (props) => (
	<Container fluid>
		<CheckAuth groupName={'superuser'} />
		<Header>
			<BC />
		</Header>
		<ParamPanel loading={props.loading} handleReload={props.handleReload} />
		{props.children}
		<Footer />
	</Container>
);

function DataTable(props) {
	const { data } = props;
	console.log('props', props);

	const columns = useMemo(
		() => [
			{
				Header: 'DB Sys Logs',
				Footer: '',
				columns: [
					{
						Header: 'id',
						Footer: '',
						accessor: 'id',
						sortType: 'basic',
						Filter: NoColumnFilter,
						style: { textAlign: 'center' },
					},
					{
						Header: 'msg',
						Footer: '',
						accessor: 'msg',
						sortType: 'basic',
						//filter: 'fuzzyText',
						style: { textAlign: 'left' },
					},
					{
						Header: 'keyword',
						Footer: '',
						accessor: 'keyword',
						sortType: 'basic',
						Filter: SelectColumnFilter,
						filter: 'includes',
						//filter: 'fuzzyText',
						style: { textAlign: 'left' },
					},
					{
						Header: 'timestamp',
						Footer: '',
						accessor: 'timestamp',
						sortType: 'basic',
						//filter: 'fuzzyText',
						style: { textAlign: 'center' },
					},
				],
			},
		],
		[]
	);
	return (
		<TableStyles>
			<TableControl
				columns={columns}
				data={data}
				showRecCount={showRecCount}
				NoDataComponent={NoData}
			/>
		</TableStyles>
	);
}

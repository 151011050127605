import { URL_GET_SESSION_SUMMARY,
	URL_GET_SESSION_WEIGHERS } from 'services/urls.js';
import API from 'services/api.js';
import {
	actionSetMessage,
	//actionResetMessage
} from 'components/messages/actions.js';

export const SET_SESSION_SUMMARY = 'SET_SESSION_SUMMARY';
export const RESET_SESSION_SUMMARY = 'RESET_SESSION_SUMMARY';

export const actionSetSessionSummaryData = (data) => {
	return {
		type: SET_SESSION_SUMMARY,
		data,
	};
};

export const actionFetchSummaryData = (sessionId, setLoading) => {
	return (dispatch) => {
		//return API.get('mrbs_pmi_edit.php?mode=get_pmi_json&id=' + pmi_id)
		const params = {
			params: {
				sessionId: sessionId,
			},
		};
		return API.get(URL_GET_SESSION_SUMMARY, params)
			.then((response) => {
				console.log('actionFetchSummaryData(): response.data', response.data);
				if (response.data.length === 0) {
					// empty set
					dispatch(actionSetSessionSummaryData({}));
				} else {
					dispatch(actionSetSessionSummaryData(response.data[0]));
				}
			})
			.catch((error) => {
				dispatch(actionSetMessage('Err getting summary data: ' + error));
			})
			.then(() => {
				setLoading(false);
			});
	};
};

export const actionResetSessionSummaryData = () => {
	return { type: RESET_SESSION_SUMMARY };
};




import API from 'services/api.js';
import {
	URL_GET_METHANE_LIST,
	URL_GET_METHANE_DATA,
	URL_GET_METHANE_REPORT,
} from 'services/urls.js';

export const actionGetMethaneList = (sessionId) => {
	const config = {
		params: {
			sessionId,
		},
	};
	return API.get(URL_GET_METHANE_LIST, config);
};

const download_csv = (url, sessionId, date) => {
	const params = {
		params: {
			sessionId,
			date,
		},
		responseType: 'blob',
		timeout: 60000,
		headers: {
			'x-opw-format': 'csv',
		},
	};
	return API.get(url, params);
};

export const actionDownloadMethaneData = (sessionId, date) => {
	return download_csv(URL_GET_METHANE_DATA, sessionId, date);
};

export const actionDownloadMethaneReport = (sessionId, date) => {
	return download_csv(URL_GET_METHANE_REPORT, sessionId, date);
};

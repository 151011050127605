import React, {
	useState,
	//useEffect,
	useMemo,
} from 'react';
import {
	useSelector,
	// 	useDispatch,
} from 'react-redux';

import styled from 'styled-components';
import {
	//NoColumnFilter,
	//GlobalFilter,
	DefaultColumnFilter,
	fuzzyTextFilterFn,
} from './utils/tableFilters.js';

import {
	AiOutlinePlusSquare,
	//AiOutlineMinusSquare,
	//AiOutlineSortAscending,
	//AiOutlineSortDescending,
	//AiOutlineEdit,
	//AiOutlineCloseCircle,
	//AiOutlinePlusCircle,
	//AiFillPlusCircle,
} from 'react-icons/ai'; //https://react-icons.github.io/react-icons/icons?name=ai
//import ButtonGroup from 'react-bootstrap/ButtonGroup'
//import Button from 'react-bootstrap/Button'

import {
	useTable,
	useRowSelect,
	useFilters,
	useSortBy,
	useGlobalFilter,
	usePagination,
} from 'react-table';
//import NavbarCollapse from 'react-bootstrap/NavbarCollapse';

export const TableStyles = styled.div`
	padding: 1rem;
	table {
		border-spacing: 0;
		border: 1px solid black;
		tr {
			:last-child {
				td {
					border-bottom: 0;
					/*padding-left: 0rem;*/
				}
			}
		}
		.selected {
			backgroundcolor: yellow !important;
		}
		th,
		td {
			margin: 0;
			padding: 0.5rem;
			border-bottom: 1px solid black;
			border-right: 1px solid black;
			:last-child {
				border-right: 0;
			}
		}
	}
`;

/**
 * We have modified the total to show at the top of the table. It still uses the footer hooks.
 * Visability is set by the user clicking on an icon
 */
const PaintTotals = (props) => {
	/**
	 * The totals bar only shows if the showTotals is set.
	 * The bar can be hidden by the operator.
	 */
	const {
		footerGroups,
		showTotals,
		//setShowTotals
	} = props;
	const styleCss = {
		textAlign: 'right',
		fontWeight: 'bold',
		borderBottom: 'double',
		borderTop: 'double',
	};
	if (showTotals) {
		return (
			<thead>
				{footerGroups.map((group, idx) => {
					if (idx === 0) {
						return (
							<tr {...group.getFooterGroupProps()} style={styleCss}>
								{group.headers.map((column, idx2) => {
									//const hideMe = (idx2===0 && showTotals) ? <><AiOutlineMinusSquare title = 'Hide totals bar' onClick={ () => setShowTotals(!showTotals) } size={'20'} /> </>:'';
									return (
										// <td {...column.getFooterProps()}>{ hideMe }{column.render('Footer')}</td>
										<td {...column.getFooterProps()}>
											{column.render('Footer')}
										</td>
									);
								})}
							</tr>
						);
					} else {
						return null;
					}
				})}
			</thead>
		);
	} else {
		return null;
	}
};

export function TableControl({
	columns,
	data,
	showRecCount,
	fetchData,
	loading,
	pageCount: controlledPageCount,
	handleCellUpdate,
}) {
	//function Table({ columns, data, selectSet }) {
	const [showTotals, setShowTotals] = useState(true); //	local data for graph or report

	const filterTypes = useMemo(
		() => ({
			// Add a new fuzzyTextFilterFn filter type.
			fuzzyText: fuzzyTextFilterFn,
			// Or, override the default text filter to use
			// "startWith"
			text: (rows, id, filterValue) => {
				return rows.filter((row) => {
					const rowValue = row.values[id];
					return rowValue !== undefined
						? String(rowValue)
								.toLowerCase()
								.startsWith(String(filterValue).toLowerCase())
						: true;
				});
			},
		}),
		[]
	);

	const defaultColumn = useMemo(
		() => ({
			// Let's set up our default Filter UI
			Filter: DefaultColumnFilter,
		}),
		[]
	);

	const defaultPropGetter = () => ({});

	const {
		getTableProps,
		getTableBodyProps,
		getColumnProps = defaultPropGetter,
		//getRowProps = defaultPropGetter,
		getCellProps = defaultPropGetter,
		headerGroups,
		footerGroups,
		//getHeaderProps = defaultPropGetter,
		//rows,
		prepareRow,
		//state,
		page,
		canPreviousPage,
		canNextPage,
		pageOptions,
		pageCount,
		gotoPage,
		nextPage,
		previousPage,
		setPageSize,
		// Get the state from the instance
		state: { pageIndex, pageSize },
		//visibleColumns,
		//preGlobalFilteredRows,
		//setGlobalFilter,
		//state: { selectedRowIds },
	} = useTable(
		{
			columns,
			data,
			initialState: { pageIndex: 0, pageSize: showRecCount }, // Pass our hoisted table state
			//initialState: { pageIndex: 0, }, // Pass our hoisted table state
			manualPagination: false, // Tell the usePagination
			// hook that we'll handle our own data fetching
			// This means we'll also have to provide our own
			// pageCount.
			//pageCount: controlledPageCount,
			defaultColumn, // Be sure to pass the defaultColumn option
			filterTypes,
			handleCellUpdate,
		},
		useFilters, // useFilters!
		useGlobalFilter,
		useSortBy,
		usePagination
	);

	// // Listen for changes in pagination and use the state to fetch our new data
	// useEffect(() => {
	// 	fetchData({ pageIndex, pageSize })
	// }, [fetchData, pageIndex, pageSize])

	// We don't want to render all of the rows for this example, so cap
	// it for this use case
	//const firstPageRows = rows.slice(0, 10)
	const auto_appt_idx = useSelector((state) => state.auto_appt_idx);
	return (
		<>
			{showTotals ? (
				''
			) : (
				<>
					<AiOutlinePlusSquare
						onClick={() => setShowTotals(!showTotals)}
						size={'20'}
					/>{' '}
					show totals
				</>
			)}
			<table {...getTableProps()} style={{ margin: '0 auto' }}>
				<thead>
					{headerGroups.map((headerGroup) => (
						<tr {...headerGroup.getHeaderGroupProps()}>
							{headerGroup.headers.map((column) => {
								return (
									// <th {...column.getHeaderProps()}>
									<th
										{...column.getHeaderProps([
											{
												//className: cell.column.className,
												style: column.style,
												id: column.id,
												//caption: column.caption,
											},
											column.getSortByToggleProps(),
										])}
									>
										{column.render('Header')}
										<span>
											{/* {column.isSorted ? (column.isSortedDesc ? ' 🔽' : ' 🔼') : ' ▽'} */}
											{column.canSort
												? column.isSorted
													? column.isSortedDesc
														? ' 🔽'
														: ' 🔼'
													: ' ▽'
												: ''}
											{/* &nbsp;{column.canSort ? column.isSorted ? (column.isSortedDesc ? <AiOutlineSortAscending /> : <AiOutlineSortDescending />) : ' ▽': ''} */}
										</span>
										{/* Render the columns filter UI */}
										<div>
											{column.canFilter ? column.render('Filter') : null}
										</div>
									</th>
								);
							})}
						</tr>
					))}
					{/* <tr>
						<th
							colSpan = {visibleColumns.length}
							style = {{textAlign: 'left',}}
						>
						<GlobalFilter
							preGlobalFilteredRows={preGlobalFilteredRows}
							globalFilter={state.globalFilter}
							setGlobalFilter={setGlobalFilter}
						/>
						</th>
					</tr> */}
				</thead>

				{/* showTotals: { showTotals } */}
				{/* <span>
						{showTotals ? <AiOutlinePlusSquare onClick= {() =>setShowTotals(!showTotals) } size={'20'}/> : <AiOutlineMinusSquare onClick={ () => setShowTotals(!showTotals) } size={'20'}/>}
					</span> */}

				<PaintTotals
					footerGroups={footerGroups}
					showTotals={showTotals}
					setShowTotals={setShowTotals}
				/>
				<tbody {...getTableBodyProps()}>
					{/* {firstPageRows.map((row, i) => { */}
					{/* {rows.map((row, i) => { */}
					{page.map((row, i) => {
						prepareRow(row);
						let highlight = {};
						//if (Object.keys(selectedRowIds)[0] === String(i)){
						if (auto_appt_idx === i) {
							highlight = { backgroundColor: 'rgba(255, 255, 0, 0.4)' };
						}
						// now paint the main table data row
						return (
							<tr
								{...row.getRowProps()}
								//onClick={(e) => selectSet(i)}
								style={highlight}
								// style = {{backgroundColor: 'yellow'}}
							>
								{row.cells.map((cell) => {
									return (
										<td
											{...cell.getCellProps([
												{
													className: cell.column.className,
													style: cell.column.style,
												},
												getColumnProps(cell.column),
												getCellProps(cell),
											])}
										>
											{cell.render('Cell')}
										</td>
									);
								})}
							</tr>
						);
					})}
				</tbody>
			</table>

			{/*
				Pagination can be built however you'd like.
				This is just a very basic UI implementation:
				*/}
			<br />
			{/* <div style={{margin: '0 auto',textAlign: 'center',}}> */}

			<center>
				<div>
					<div className="pagination">
						<button onClick={() => gotoPage(0)} disabled={!canPreviousPage}>
							{'<<'}
						</button>{' '}
						<button onClick={() => previousPage()} disabled={!canPreviousPage}>
							{'<'}
						</button>{' '}
						<button onClick={() => nextPage()} disabled={!canNextPage}>
							{'>'}
						</button>{' '}
						<button
							onClick={() => gotoPage(pageCount - 1)}
							disabled={!canNextPage}
						>
							{'>>'}
						</button>{' '}
						<span>
							&nbsp;Page {pageIndex + 1} of {pageOptions.length}{' '}
						</span>
						<span>&nbsp;| Go to page: &nbsp;</span>
						<span>
							<input
								type="number"
								defaultValue={pageIndex + 1}
								onChange={(e) => {
									const page = e.target.value ? Number(e.target.value) - 1 : 0;
									gotoPage(page);
								}}
								style={{ width: '100px' }}
							/>
						</span>
						&nbsp;{'  '}
						<select
							value={pageSize}
							onChange={(e) => {
								setPageSize(Number(e.target.value));
							}}
						>
							{[10, 20, 30, 40, 50].map((pageSize) => (
								<option key={pageSize} value={pageSize}>
									Show {pageSize}
								</option>
							))}
						</select>
					</div>
				</div>
			</center>
		</>
	);
}

// has LOTS of trouble getting a single row select - ended up with custom reducer
const useFixedRowSelect = (hooks) => {
	useRowSelect(hooks);
	hooks.stateReducers.pop(); // Remove the damaged reducer

	function reducer(state, action, previousState, instance) {
		const { type, id } = action;
		if (type === 'toggleRowSelected') {
			const selectedRowIds = Object.assign({});
			selectedRowIds[id] = true; //set the selectd id
			return {
				...state,
				selectedRowIds,
			};
		} else {
			return {
				...state,
				selectedRowIds: {},
			};
		}
	}
	hooks.stateReducers.push(reducer);
};

//https://github.com/tannerlinsley/react-table/blob/master/examples/editable-data/src/App.js
export const EditableCell = ({
	value: initialValue,
	row: { index },
	column: { id },
	handleCellUpdate, // This is a custom function that we supplied to our table instance
}) => {
	// We need to keep and update the state of the cell normally
	const [value, setValue] = React.useState(initialValue);

	const onChange = (e) => {
		setValue(e.target.value);
	};

	// We'll only update the external data when the input is blurred
	const onBlur = () => {
		handleCellUpdate(index, id, value);
	};

	// If the initialValue is changed external, sync it up with our state
	React.useEffect(() => {
		setValue(initialValue);
	}, [initialValue]);

	return <input value={value} onChange={onChange} onBlur={onBlur} />;
};

//export default EditableCell

useFixedRowSelect.pluginName = useRowSelect.pluginName;

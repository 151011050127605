exports.convertToCSV = convertToCSV;

function convertToCSV(tableData, delimiter = ',') {
	if (!Array.isArray(tableData) || !tableData.length) {
		throw new Error(
			'Table data should be a non-empty array of column/value rows'
		);
	}

	const colNames = tableData.reduce((columns, row) => {
		for (const c in row) {
			if (!columns.includes(c)) columns.push(c);
		}
		return columns;
	}, []);
	colNames.sort();

	const rows = tableData.map((rowData) => {
		const row = colNames.map((col) => {
			if (!Object.hasOwn(rowData, col)) return null;

			// I noticed that members column is an array, not sure how you handle it
			// but here it joins the members with comma and wraps the whole thing in
			// double quotes
			if (Array.isArray(rowData[col]) && rowData[col].length)
				return '"' + rowData[col].join(',') + '"';

			return rowData[col];
		});
		return row.join(delimiter);
	});

	return colNames.join(delimiter) + '\n' + rows.join('\n');
}

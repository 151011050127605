import { Session } from 'components/clientSessionSelect/sessionSelect/redux';
import { useState, useMemo, useCallback } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { AppState } from 'reducers';
import { SessionCloseModel, useSessionCloseModel } from './sessionCloseModel';

export type SessionListModel = {
	loading: boolean;
	showOpenOnly: boolean;
	setShowOpenOnly: (o: boolean) => void;

	sessions: Session[];
	sessionCloseModel?: SessionCloseModel;

	onNewSession: () => void;
	onSessionEdit: (id: number) => void;
	onSessionClose: (id: number) => void;
};

export const useSessionListModel = (): SessionListModel => {
	const navigate = useNavigate();
	const all_sessions = useSelector((state: AppState) => state.sessionIds);
	const [loading, setLoading] = useState(false);
	const [showOpenOnly, setShowOpenOnly] = useState(false);
	const [session_to_close, set_session_to_close] = useState<number | null>(
		null
	);
	const close_model = useSessionCloseModel(
		all_sessions.filter((s) => s.SessionID === session_to_close),
		() => {
			set_session_to_close(null);
		}
	);
	const sessions = useMemo(() => {
		return all_sessions.filter((session) => {
			return session.isOpen || !showOpenOnly;
		});
	}, [all_sessions, showOpenOnly]);

	const onNewSession = useCallback(
		() => navigate('/manage/session/edit'),
		[navigate]
	);
	const onSessionEdit = useCallback(
		(id: number) => {
			navigate('/manage/session/edit', { state: { edit: id } });
		},
		[navigate]
	);

	return {
		loading,
		sessions,
		...(session_to_close && { sessionCloseModel: close_model }),
		showOpenOnly,
		setShowOpenOnly,
		onNewSession,
		onSessionEdit,
		onSessionClose: set_session_to_close,
	};
};

import React from //useState,
//useEffect,
//useMemo,
'react';
import { GenericListSelect } from './genericListSelect';

const SummaryTimePeriodSelect = (props) => {
	const { value, cols, handleChange, required, controlStyle, lock, label } =
		props;
	const types = ['Past 5 Days', 'Past 10 Days', 'Session Average'];

	return (
		<>
			<GenericListSelect
				id="summaryTimePeriod"
				label={label}
				dataArray={types}
				cols={cols}
				controlStyle={controlStyle}
				handleChange={(evt) => handleChange(evt)}
				value={value}
				required={required}
				lock={lock}
				//pattern = {'^\d+$'}	// integer
				//pattern = {'^[a-zA-Z]+[a-zA-Z0-9_ -]+$'}
				//invalidMsg = 'Select a Breed type!'
			/>
		</>
	);
};

export default SummaryTimePeriodSelect;

import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { NoColumnFilter } from 'components/utils/tableFilters.js';

import {
	actionSetMessage,
	actionResetMessages,
} from 'components/messages/actions.js';

import RptBtns from 'components/rptBtns.js';
import { TableControl, TableStyles } from 'components/tableControl.js';
import { CheckAuth } from 'pages/auth/CheckAuth';
import { ClientSessionSelect } from 'components/clientSessionSelect/clientSessionSelect.js';
import Header from 'components/header.js';
import Footer from 'components/footer.js';
import Container from 'react-bootstrap/Container';
import Card from 'react-bootstrap/Card';
import OPWBreadcrumb from 'components/breadcrumb.js';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Loading from 'components/loading.js';
import {
	TimePeriodSelect,
	TimePeriodSelectV2,
} from 'components/timePeriodSelect.js';
import { actionFetchLongTermData } from './actions.js';
import { format } from 'date-fns';
import isValid from 'date-fns/isValid';
import { stdDateFormatStr } from 'components/dateParamControl.js';
import 'react-datepicker/dist/react-datepicker.css';
import { saveAs } from 'file-saver';
import styled from 'styled-components';
import { DateRangeControl } from 'components/dateRangeControl.js';
import { convertToCSV } from 'components/utils/csvConvert.js';
import { kgToLbs } from 'components/utils/convert-units.js';

const TITLE = 'Data over time';
const showRecCount = 50; // default records to display

const BC = OPWBreadcrumb;

// this is specifically for this table and overwrites TableStyles.
const LongTermDataTableStyles = styled.div`
	th#visId input {
		max-width: 4rem;
	}
	th#eid input {
		max-width: 8rem;
	}
	tr {
		td {
			padding-left: 0.2rem;
			padding-right: 0.2rem;
		}
	}
`;
const ORANGE = { color: '#fd7e14' };

const ParamPanel = (props) => {
	const {
		timePeriod,
		setTimePeriod,
		timeSpan,
		setTimeSpan,
		loading,
		handleDownload,
		downloading,
	} = props;

	const handleTimePeriodChange = (evt) => {
		setTimePeriod(evt.target.value);
	};

	const handleTimeSpanChange = (evt) => {
		setTimeSpan(evt.target.value);
	};

	return (
		<Card className={'no-print'} body>
			<Card.Title>{TITLE}</Card.Title>
			<Card.Body>
				<Row>
					<Col sm={4}>
						<ClientSessionSelect
							lock={loading}
							layout={'select'}
							hideSessionSelect={false}
							handleBeforeSessionChange={() => {}}
							includeYardWeights={true}
						/>
					</Col>
					<Col sm={4}>
						<TimePeriodSelectV2
							value={timeSpan}
							cols={{ lhs: 4, rhs: 8 }}
							handleChange={(evt) => handleTimeSpanChange(evt)}
							controlStyle={{}}
							required={true}
							lock={loading}
							className="inputRow"
						/>
						<DateRangeControl {...props} />
						<TimePeriodSelect
							label="View data by"
							value={timePeriod}
							cols={{ lhs: 4, rhs: 8 }}
							handleChange={(evt) => handleTimePeriodChange(evt)}
							controlStyle={{}}
							required={true}
							lock={loading}
							className="inputRow"
						/>
						<Loading loading={loading} />
					</Col>
					<Col sm={4}>
						<div className={'text-right report-buttons'}>
							<RptBtns
								enablePrint={true}
								enableHelp={true}
								enableDownload={true}
								handleDownload={() => handleDownload()}
								downloading={downloading}
							/>
						</div>
						<div className={'dt-leftMargin note'}>
							Notes:
							<ol>
								<li>
									Animals are selected by having session membership. Weight data
									is shown for the selected animals across ALL sessions.
								</li>
								<li>
									visualId may be empty if yard weight data has never been
									recorded.
								</li>
								<li>
									Animals with zero avgWt or count belong to the Session but did
									not register a reading in the date range
								</li>
							</ol>
						</div>
					</Col>
				</Row>
			</Card.Body>
		</Card>
	);
};

const Template = (props) => (
	<div>
		<Header>
			<BC trail='[["Home", "/"], ["reports", "/"], ["data over time", ""]]' />
		</Header>
		<Container fluid className="body">
			<CheckAuth groupName={'user'} />
			<ParamPanel {...props} />
			{props.children}
		</Container>
		<Container fluid>
			<Row>
				<Col sm="12">
					<Footer />
				</Col>
			</Row>
		</Container>
	</div>
);

export const LongTermData = (props) => {
	const [dateRange, setDateRange] = useState([]);
	const [timePeriod, setTimePeriod] = useState('day'); //	day, week, month
	const [timeSpan, setTimeSpan] = useState('Last Day');
	const [loading, setLoading] = useState(false);
	const [xTabFields, setXTabFields] = useState([]); //	list of fields for a xtab query supplied by the server
	const [data, setData] = useState([]);
	const [downloading, setDownloading] = useState(false);
	const session = useSelector((state) => state.session);
	const dispatch = useDispatch();

	const pounds = session && session.displayUnits === 'i';

	const species = session && session.Species && session.Species.toUpperCase();
	const decimals = species === 'SHEEP' ? 1 : 0;

	const showMessage = useCallback(
		(data) => dispatch(actionSetMessage(data)),
		[dispatch]
	);

	const getOFormat = (timePeriod) => {
		switch (timePeriod) {
			case 'week':
			case 'month':
			case 'day': //fallthrough
				return 'xtab';
			default:
				return '';
		}
	};

	const handleDownload = async () => {
		setDownloading(true);
		const csv = convertToCSV(data);
		const blob = new Blob([csv], { type: 'text/csv;charset=utf-8' });
		await saveAs(blob, 'session_data.csv');
		setDownloading(false);
	};

	function SetDates(offset) {
		const d1 = new Date();
		d1.setHours(0, 0, 0, 0);
		const d2 = new Date();
		d2.setHours(0, 0, 0, 0);
		d2.setDate(d2.getDate() - offset);
		setDateRange([d2, d1]);
	}

	useEffect(() => {
		//set upper and lower date based on time span selection
		if (timeSpan === 'Last Day') {
			SetDates(0);
		} else if (timeSpan === 'Last 7 Days') {
			SetDates(6);
		} else if (timeSpan === 'Last 14 Days') {
			SetDates(13);
		} else if (timeSpan === 'Last 30 Days') {
			SetDates(29);
		} else if (timeSpan === 'Last 90 Days') {
			SetDates(89);
		} else if (timeSpan === 'Last 180 Days') {
			SetDates(179);
		} else {
			const dates = dateRange.map((d) => new Date(d)); // deep copy

			const dstart = new Date(session.SessionStart);
			dstart.setHours(0, 0, 0, 0);
			if (isValid(dstart)) dates[0] = dstart;

			const dend = new Date(session.SessionEnd);
			dend.setHours(0, 0, 0, 0);
			if (session.SessionEnd === null) {
				dates[1] = new Date();
			} else if (isValid(dend)) {
				dates[1] = dend;
			}

			if (dates !== dateRange) setDateRange(dates);
		}
	}, [timeSpan, session]);

	useEffect(() => {
		document.title = TITLE + ' - ' + timePeriod;
	}, [timePeriod]);

	useEffect(() => {
		async function fetchData() {
			dispatch(actionResetMessages());
			const eid = 'tba'; //use only for animal view
			const oFormat = getOFormat(timePeriod);
			const resp = await actionFetchLongTermData(
				timePeriod,
				format(dateRange[0], stdDateFormatStr),
				format(dateRange[1], stdDateFormatStr),
				session.SessionID,
				eid,
				oFormat,
				showMessage,
				setLoading
			);
			if (oFormat === 'xtab' && resp.xTabFields) setXTabFields(resp.xTabFields);

			const rows = oFormat === 'xtab' && resp.xTabData ? resp.xTabData : resp;
			setData(
				rows.map((r) => {
					for (const key in r) {
						if (key !== 'eid' && key !== 'visId')
							if (pounds) {
								r[key] = Number(r[key])
									? +kgToLbs(r[key]).toFixed(decimals)
									: r[key];
							} else {
								r[key] = Number(r[key]) ? +r[key].toFixed(decimals) : r[key];
							}
					}
					return r;
				})
			);
		}

		if (
			session.SessionID > 0 &&
			timePeriod.length > 0 &&
			dateRange.length === 2 &&
			isValid(dateRange[0]) &&
			isValid(dateRange[1])
		) {
			setData([]); //clear as lots of column changes and looks wierd
			setLoading(true);
			fetchData();
		}
	}, [timePeriod, session, dateRange, pounds, decimals, showMessage, dispatch]);

	// :TODO: get sensible starting date for dat week month
	// const getDefaultDateParam = (timePeriod, dateParam) => {
	// 	const def = new Date()
	// 	return format(def, formatStr)
	// }

	const masterProps = {
		// ParamPanel
		timePeriod,
		setTimePeriod,
		timeSpan,
		setTimeSpan,
		loading,
		handleDownload,
		downloading,
		// DateRangeControl
		lowerDate: dateRange[0],
		setLowerDate: (ld) => {
			setDateRange([ld, dateRange[1]]);
		},
		upperDate: dateRange[1],
		setUpperDate: (ud) => {
			setDateRange([dateRange[0], ud]);
		},
	};

	if (timePeriod === 'day') {
		return (
			<Template {...masterProps}>
				<LongTermDataTableWeek
					data={data}
					xTabFields={xTabFields}
					session={session}
					pounds={pounds}
					decimals={decimals}
				/>
			</Template>
		);
	} else if (timePeriod === 'week') {
		return (
			<Template {...masterProps}>
				<LongTermDataTableWeek
					data={data}
					xTabFields={xTabFields}
					session={session}
					pounds={pounds}
					decimals={decimals}
				/>
			</Template>
		);
	} else if (timePeriod === 'month') {
		return (
			<Template {...masterProps}>
				<LongTermDataTableMonth
					data={data}
					xTabFields={xTabFields}
					session={session}
					pounds={pounds}
					decimals={decimals}
				/>
			</Template>
		);
	} else {
		//return ( <>Error - unknown timePeriod: { timePeriod }</> )
		return <>Error - unknown timePeriod</>;
	}
};

const NoData = () => <h1>No data</h1>;

const LongTermDataTableWeek = (props) => {
	const { session, data, xTabFields, pounds, decimals } = props;

	let weekAverage = null;
	const dataWithTotals = data.reduce(function (res, set) {
		let dsum = 0;
		let dcount = 0;
		for (let i = 0; i < xTabFields.length; i++) {
			if (Object.hasOwn(set, xTabFields[i])) {
				dsum += set[xTabFields[i]];
				dcount += 1;
			}
		}
		if (dcount > 0) {
			weekAverage = (dsum / dcount).toFixed(decimals);
		} else {
			weekAverage = null;
		}
		res.push({ ...set, ...{ weekAvg: weekAverage } });
		return res;
	}, []);
	const buildColumns = (xTabFields) => {
		const columnsPre = [
			{
				Header: 'RFID',
				accessor: 'eid',
				sortType: 'basic',
				style: { textAlign: 'center' },
				Footer: `Average (${pounds ? 'lb' : 'kg'})`,
			},
			{
				Header: 'Visual ID',
				Footer: '',
				accessor: 'visId',
				sortType: 'basic',
				style: { textAlign: 'center' },
			},
		];

		const columnsXTab = xTabFields.map((colIdentifier) => {
			return {
				Header: colIdentifier,
				Footer: (info) => {
					const avgObj = React.useMemo(
						() =>
							info.rows.reduce(
								(dataObj, row) => {
									if (
										row.values[colIdentifier] == null ||
										isNaN(row.values[colIdentifier])
									) {
										//only include numeric in calcs
										return dataObj;
									} else {
										return {
											sum: row.values[colIdentifier] + dataObj.sum,
											count: dataObj.count + 1,
										};
									}
								},
								{ sum: 0, count: 0 }
							),
						[info.rows]
					);
					const avg =
						avgObj.count > 0
							? parseFloat(
									(avgObj.sum / avgObj.count).toFixed(decimals)
								).toLocaleString('en')
							: '';
					return (
						<span style={colIdentifier.includes('yard weight') ? ORANGE : {}}>
							{avg}
						</span>
					);
				},

				accessor: colIdentifier,
				sortType: 'basic',
				Filter: NoColumnFilter,
				style: {
					textAlign: 'right',
					maxWidth: '8rem',
					...(colIdentifier.includes('yard weight') ? ORANGE : {}),
				},
			};
		});

		const columnsPost = [
			{
				Header: 'Avg',
				Footer: (info) => {
					const wkAvgObj = React.useMemo(
						() =>
							info.rows.reduce(
								(dataObj, row) => {
									if (
										row.values['weekAvg'] == null ||
										isNaN(row.values['weekAvg'])
									) {
										//only include numeric in calcs
										return dataObj;
									} else {
										return {
											sum: Number(row.values['weekAvg']) + dataObj.sum,
											count: dataObj.count + 1,
										};
									}
								},
								{ sum: 0, count: 0 }
							),
						[info.rows]
					);
					const avg =
						wkAvgObj.count > 0
							? parseFloat(
									(wkAvgObj.sum / wkAvgObj.count).toFixed(decimals)
								).toLocaleString('en')
							: '';
					return <>{avg}</>;
				},

				accessor: 'weekAvg',
				sortType: 'basic',
				style: { textAlign: 'center' },
				Filter: NoColumnFilter,
			},
		];
		return [...columnsPre, ...columnsXTab, ...columnsPost];
	};

	const columns = useMemo(
		() => [
			{
				Header: `Data for session: ${session.SessionID}`, //, Week ending Sunday ${fdate}`,
				Footer: '',
				columns: buildColumns(xTabFields),
			},
		],
		[session, xTabFields]
	);
	return (
		<TableStyles className="sessionDataTable">
			<LongTermDataTableStyles>
				<TableControl
					columns={columns}
					data={dataWithTotals}
					showRecCount={showRecCount}
					NoDataComponent={NoData}
				/>
			</LongTermDataTableStyles>
		</TableStyles>
	);
};

const LongTermDataTableMonth = (props) => {
	const { session, data, xTabFields, pounds, decimals } = props;
	const buildColumns = (xTabFields) => {
		const columnsPre = [
			{
				Header: 'RFID',
				accessor: 'eid',
				sortType: 'basic',
				style: { textAlign: 'center' },
				Footer: `Average (${pounds ? 'lb' : 'kg'})`,
			},
			{
				Header: 'Visual ID',
				Footer: '',
				accessor: 'visId',
				sortType: 'basic',
				style: { textAlign: 'center' },
			},
		];

		const columnsXTab = xTabFields.map((colIdentifier) => {
			return {
				Header: colIdentifier,
				accessor: colIdentifier,
				sortType: 'basic',
				Filter: NoColumnFilter,
				style: {
					textAlign: 'right',
					maxWidth: '8rem',
					...(colIdentifier.includes('yard weight') ? ORANGE : {}),
				},
				Footer: (info) => {
					const avgObj = React.useMemo(
						() =>
							info.rows.reduce(
								(dataObj, row) => {
									if (
										row.values[colIdentifier] == null ||
										isNaN(row.values[colIdentifier])
									) {
										//only include numeric in calcs
										return dataObj;
									} else {
										return {
											sum: row.values[colIdentifier] + dataObj.sum,
											count: dataObj.count + 1,
										};
									}
								},
								{ sum: 0, count: 0 }
							),
						[info.rows]
					);
					const avg =
						avgObj.count > 0
							? parseFloat(
									(avgObj.sum / avgObj.count).toFixed(decimals)
								).toLocaleString('en')
							: '';
					return (
						<span style={colIdentifier.includes('yard weight') ? ORANGE : {}}>
							{avg}
						</span>
					);
				},
			};
		});

		return [...columnsPre, ...columnsXTab];
	};

	const columns = useMemo(
		() => [
			{
				Header: `Data for session: ${session.SessionID}`, //, Month ending: ${fdate}`,
				Footer: '',
				columns: buildColumns(xTabFields),
			},
		],
		[session, xTabFields]
	);
	return (
		<TableStyles className="sessionDataTable">
			<LongTermDataTableStyles>
				<TableControl
					columns={columns}
					data={data}
					showRecCount={showRecCount}
					NoDataComponent={NoData}
				/>
			</LongTermDataTableStyles>
		</TableStyles>
	);
};

import API from 'services/api.js';
import { URL_GET_SESSION_DATA } from 'services/urls.js';

//export const actionFetchSessionAvg = (sessionId, sessionStartDate, sessionEndDate, showMessage, setLoading) => {
export const actionFetchSessionAvg = (
	sessionId,
	timebase,
	showMessage,
	setLoading
) => {
	let resp = [];
	const params = {
		params: {
			sessionId: sessionId,
			timebase: timebase,
			//clientId: 20,//:FIXME:
		},
		//headers: {
		//'x-opw-format': 'c3',
		//}
	};
	return API.get(URL_GET_SESSION_DATA, params)
		.then((response) => {
			console.log('response actionFetchSessionAvg()', response);
			resp = response.data;
		})
		.catch((error) => {
			showMessage('Err fetching session average data: ' + error);
		})
		.then(() => {
			setLoading(false);
			return resp;
		});
};

export const getDownloadFile = async (sessionId, timebase) => {
	const params = {
		params: {
			sessionId: sessionId,
			timebase: timebase,
		},
		responseType: 'blob',
		timeout: 30000,
		headers: {
			'x-opw-format': 'csv',
		},
	};
	return API.get(URL_GET_SESSION_DATA, params)
		.then((response) => {
			return response;
		})
		.catch((error) => {
			//showMessage('Err fetching session average data: ' + error)
		});
};

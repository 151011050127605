import API from 'services/api.js';
import { URL_CLIENT_PORTAL_SETTING, URL_CLIENT_EMAIL } from 'services/urls.js';

import {
	actionSetMessage,
	actionResetMessages,
} from 'components/messages/actions.js';

import { store } from 'index';

//export const actionFetchSessionAvg = (sessionId, sessionStartDate, sessionEndDate, showMessage, setLoading) => {
//export const actionFetchMessageOfTheDay = () => {
export const actionFetchSetting = (name) => {
	//get setting from server api
	//const state = store.getState();
	store.dispatch(actionResetMessages());
	let resp = '';
	const params = {
		params: {
			name: name,
		},
		//headers: {
		//'x-opw-format': 'c3',
		//}
	};
	return API.get(URL_CLIENT_PORTAL_SETTING, params)
		.then((response) => {
			resp = response.data;
		})
		.catch((error) => {
			store.dispatch(
				actionSetMessage(`Error fetching setting ${name}: ${error}`)
			);
		})
		.then(() => {
			return resp;
		});
};

//export const actionSetMessageOfTheDay = (msg) => {
export const actionSetSetting = (name, value, setLoading) => {
	//const state = store.getState();
	setLoading(true);
	store.dispatch(actionResetMessages());
	let resp = '';
	// const config = {
	// 	headers: {
	// 	  // Overwrite Axios's automatically set Content-Type
	// 	  'Content-Type': 'application/json'
	// 	}
	// }
	const dataPack = {
		name: name,
		value: value,
	};
	return (
		API.post(URL_CLIENT_PORTAL_SETTING, dataPack)
			//return API.post(URL_CLIENT_PORTAL_SETTING, json, config)
			.then((response) => {
				store.dispatch(actionSetMessage(`Successfully saved ${value}`));
				resp = response.data;
			})
			.catch((error) => {
				store.dispatch(
					actionSetMessage('Err fetching actionSetSetting(): ' + error)
				);
			})
			.then(() => {
				setLoading(false);
				return resp;
			})
	);
};

export const actionSendTestClientEmail = (
	recipient,
	clientId,
	codebase,
	setSendLoading
) => {
	//const state = store.getState();
	store.dispatch(actionResetMessages());

	const dataPack = {
		mode: 'send-test',
		codebase: codebase,
		recip: recipient,
		clientId: clientId,
	};
	return (
		API.post(URL_CLIENT_EMAIL, dataPack)
			//return API.post(URL_CLIENT_PORTAL_SETTING, json, config)
			.then((response) => {
				store.dispatch(actionSetMessage(`Success sending test message`));
				setSendLoading(false);
				return response.data;
			})
			.catch((error) => {
				store.dispatch(
					actionSetMessage(`Error sending test message: ${error}`)
				);
				setSendLoading(false);
				return {
					msg: 'Err fetching actionSendTestClientEmail()',
					err: error,
				};
			})
	);
};

export const SET_MESSAGE = 'SET_MESSAGE';
export const RESET_MESSAGES = 'RESET_MESSAGES';

// Sync Action

export const actionSetMessage = (data) => {
	return {
		type: SET_MESSAGE,
		data,
	};
};

export const actionResetMessages = () => {
	return { type: RESET_MESSAGES };
};

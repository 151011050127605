import API from 'services/api.js';
import { URL_GET_WEIGHT_CHART } from 'services/urls.js';

export const actionFetchWeightChartData = (
	sessionId,
	timePeriod,
	includeCurrentDay,
	showOnlySession,
	showMessage,
	setLoading
) => {
	let resp = [];
	const params = {
		params: {
			sessionId: sessionId,
			timePeriod: timePeriod,
			includeCurrentDay: includeCurrentDay,
			onlyShowSession: showOnlySession,
		},
		headers: {
			'x-opw-format': 'c3',
		},
	};
	//console.log ('actionFetchWeightChartData params: ', JSON.stringify(params));
	return API.get(URL_GET_WEIGHT_CHART, params)
		.then((response) => {				
			//console.log('actionFetchWeightChartData(): response.data', response.data);
			resp = response.data;
		})
		.catch((error) => {
			showMessage('Err fetching weight chart data: ' + error);
		})
		.then(() => {
			setLoading(false);
			return resp;
		});
};

export const getDownloadFile = async (
	sessionId,
	timePeriod,
	includeCurrentDay
) => {
	const params = {
		params: {
			sessionId: sessionId,
			timePeriod: timePeriod,
			includeCurrentDay: includeCurrentDay,
		},
		responseType: 'blob',
		timeout: 30000,
		headers: {
			'x-opw-format': 'csv',
		},
	};
	return API.get(URL_GET_WEIGHT_CHART, params)
		.then((response) => {
			return response;
		})
		.catch((error) => {
			//showMessage('Err fetching session average data: ' + error)
		});
};

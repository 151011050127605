import Button from 'react-bootstrap/Button';
import { AiOutlineTeam } from 'react-icons/ai';

export const CrmLink = (props) => {
	return (
		<a href={props.target} target="_blank" rel="noreferrer">
			<Button size="sm" variant="outline-secondary">
				<AiOutlineTeam size={'1.25em'} /> {props.children || 'CRM'}
			</Button>
		</a>
	);
};

import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';

export type GenericModalProps = {
	title: string;
	text: string;
	show: boolean;
	buttons: { [key: string]: { label: string; onClick: () => void } };
};

export const GenericModal = (props: GenericModalProps) => {
	const { title, text, show, buttons } = props;

	return (
		<Modal show={show} backdrop="static" keyboard={false}>
			<Modal.Header closeButton>
				<Modal.Title>{title}</Modal.Title>
			</Modal.Header>
			<Modal.Body>{text}</Modal.Body>
			<Modal.Footer>
				{Object.entries(buttons).map(([key, btn]) => (
					<Button key={key} variant="primary" onClick={btn.onClick}>
						{btn.label}
					</Button>
				))}
			</Modal.Footer>
		</Modal>
	);
};

import { SET_MESSAGE, RESET_MESSAGES } from './actions';

export function messages(state = [], action) {
	switch (action.type) {
		case SET_MESSAGE:
			//return { ...messages, ...action.data }
			//return action.data
			return Object.assign([], state, state.concat(action.data));
		case RESET_MESSAGES:
			return [];
		default:
			return state;
	}
}

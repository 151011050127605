import { useState, useCallback } from 'react';
import { getTimezoneOffset, format } from 'date-fns-tz';
import { addSeconds } from 'date-fns';
import { actionCloseSession } from 'pages/sessionManagement/actions';
import {
	actionSetMessage,
	actionResetMessages,
} from 'components/messages/actions.js';
import {
	actionFetchSessionIds,
	actionResetSessionIds,
} from 'components/clientSessionSelect/sessionSelect/redux';
import { useAppSelector, useAppDispatch } from 'hooks';
import { useMutation } from 'react-query';

export type SessionCloseModel = {
	isLoading: boolean;
	openSessions: {
		SessionID: number;
		SessionName: string;
	}[];
	endTime: Date;
	timezone: string;
	setSessionEndTime: (end: Date) => void;
	onAcceptClicked: () => void;
	onCancelClicked: () => void;
};

export const useSessionCloseModel = (
	openSessions: {
		SessionID: number;
		SessionName: string;
		timezone: string;
	}[],
	onFinished: () => void
): SessionCloseModel => {
	const clientId = useAppSelector((state) => state.clientId);
	const [sessionCloseTime, setSessionCloseTime] = useState(new Date());
	const dispatch = useAppDispatch();

	const timezone =
		openSessions[0] === undefined ? '' : openSessions[0].timezone;

	const post = useMutation({
		mutationFn: async () => {
			dispatch(actionResetMessages());
			for (const session of openSessions) {
				await actionCloseSession(session.SessionID, sessionCloseTime, timezone);
			}
		},
		onSuccess: () => {
			dispatch(actionResetSessionIds());
			if (clientId) dispatch(actionFetchSessionIds(clientId, true));
			onFinished();
		},
	});

	return {
		isLoading: post.isLoading,
		openSessions,
		endTime: sessionCloseTime,
		timezone,
		setSessionEndTime: setSessionCloseTime,
		onAcceptClicked: post.mutate,
		onCancelClicked: onFinished,
	};
};

import React, { useState, useEffect, useCallback } from 'react';

import { useSelector, useDispatch } from 'react-redux';
import {
	actionSetMessage,
	actionResetMessages,
} from 'components/messages/actions.js';
//import { Link } from "react-router-dom";
import { CheckAuth } from 'pages/auth/CheckAuth';
import Header from 'components/header.js';
import Footer from 'components/footer.js';
import Container from 'react-bootstrap/Container';
import Card from 'react-bootstrap/Card';
import OPWBreadcrumb from 'components/breadcrumb.js';
import {
	actionFetchWeightDistributionData,
	getDownloadFile,
} from './actions.js';
import { ClientSessionSelect } from 'components/clientSessionSelect/clientSessionSelect.js';

import c3 from 'c3';
import { format as d3format } from 'd3';
//import { format } from 'date-fns';
import 'c3/c3.min.css';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Alert from 'react-bootstrap/Alert';
import { GenericDictSelect } from 'components/genericDictSelect.js';
import Loading from 'components/loading.js';
import RptBtns from 'components/rptBtns.js';
import { saveAs } from 'file-saver';
import SummaryTimePeriodSelect from 'components/summaryTimePeriodSelect.js';
import { kgToLbs } from 'components/utils/convert-units.js';

const TITLE = 'Weight distribution';

const BC = OPWBreadcrumb;

const yAxisTypes = {
	percentMob: '% of Mob',
	numberEid: 'Actual Numbers',
	estNumberInMob: 'Estimated Number in Mob',
};

const ParamPanel = (props) => {
	const {
		downloading,
		handleChange,
		handleDownload,
		session,
		setAllColumns,
		setTimePeriod,
		timePeriod,
		yAxis,
		yAxisTypes,
		pounds,
	} = props;
	const cols = { lhs: 3, rhs: 9 };

	let yAxisSelect;

	if (!session.UseNoIDs) {
		yAxisSelect = (
			<GenericDictSelect
				id="y-axis"
				label="Display"
				dataDict={yAxisTypes}
				cols={cols}
				controlStyle={{ width: '15em' }}
				handleChange={(evt) => handleChange(evt)}
				value={yAxis}
				required={true}
				selectDotDotDot={false}
				loading={false}
			/>
		);
	} else {
		yAxisSelect = "Actual numbers displayed. Not using EID's in this session.";
	}

	const handleBeforeSessionChange = () => {
		//setLastDayOfWeek('');//clear control or it reloads data from api with wrong parameters
		setAllColumns([]); //clear graph
	};

	const handleTimePeriodChange = (evt) => {
		setTimePeriod(evt.target.value);
	};

	return (
		<Card body>
			<Card.Title>{TITLE}</Card.Title>
			<Card.Body>
				<Row>
					<Col sm={4}>
						<ClientSessionSelect
							layout={'select'}
							hideSessionSelect={false}
							handleBeforeSessionChange={() => handleBeforeSessionChange()}
							lock={false}
							includeYardWeights={true}
						/>
					</Col>
					<Col sm={5}>
						<SummaryTimePeriodSelect
							value={timePeriod}
							required={false}
							label={'Time Period'}
							cols={cols}
							handleChange={(evt) => handleTimePeriodChange(evt)}
							controlStyle={{ width: '15em' }}
							lock={false}
							className="inputRow"
						/>
						{yAxisSelect}
					</Col>
					<Col sm={3}>
						<div className={'text-right report-buttons'}>
							<RptBtns
								enablePrint={true}
								enableHelp={false}
								enableDownload={true}
								//handleDownload = {() => { alert('Optiweigh feature coming soon - thanks for your patience.') }}
								handleDownload={() => handleDownload()}
								downloading={downloading}
							/>
						</div>
						<p className="dt-leftMargin note">
							{pounds
								? `Note: Distribution groups are +/- 50lb, eg. 800lb group is 775lb to 825lb.`
								: 'Note: Distribution groups are +/- 12.5kg, eg. 300kg group is 287.5kg to 312.5kg.'}
						</p>
					</Col>
				</Row>
			</Card.Body>
		</Card>
	);
};
//animals by week use last day of week
//select distinct lattdayofweek from animalsbyweek where sesseionid=dddd

export const WeightDistribution = (props) => {
	const [c3Package, setC3Package] = useState(null); //	package for the c3 graph
	const [allColumns, setAllColumns] = useState([]); //	local data for graph or report
	const [loading, setLoading] = useState(false);
	const [downloading, setDownloading] = useState(false);
	const [yAxis, setYAxis] = useState('estNumberInMob');
	const session = useSelector((state) => state.session);
	const [timePeriod, setTimePeriod] = useState('Past 10 Days');

	const dispatch = useDispatch();

	const pounds = session?.displayUnits === 'i';

	useEffect(() => {
		document.title = TITLE;
	}, []);

	const showMessage = useCallback(
		(data) => dispatch(actionSetMessage(data)),
		[dispatch]
	);

	useEffect(() => {
		const parseAxisData = (data, countTotals) => {
			const weight_bins = data[0].map((w, i) => {
				if (i === 0) return w;
				return pounds ? kgToLbs(w).toFixed(0) : w;
			});
			const ma5EidCountArr = data[1].map((row, idx) => {
				//ma5EidCount
				if (idx === 0) {
					return 'ma5PercentMob';
				} else {
					return ((100 * row) / countTotals.ma5Total).toFixed(0);
				}
			});
			const ma5EstNumberInMobArr = data[1].map((row, idx) => {
				if (idx === 0) {
					return 'ma5EstNumberInMob';
				} else {
					//return (Math.round(row/countTotals.ma5Total*session.Head).toLocaleString('en'));
					return ((row / countTotals.ma5Total) * session.Head).toFixed(0);
				}
			});
			return [weight_bins, data[1], ma5EidCountArr, ma5EstNumberInMobArr];
		};
		const fetchData = async () => {
			const resp = await actionFetchWeightDistributionData(
				session.SessionID,
				timePeriod,
				setLoading,
				showMessage
			);
			//			const resp = await actionFetchWeightDistributionData(session.SessionID, format(dateParam, stdDateFormatStr), setLoading, showMessage);
			const countTotals = getCountTotals(resp);
			const parsedData = parseAxisData(resp, countTotals);
			if (resp !== null) {
				setAllColumns(parsedData);
			}
		};
		if (session.SessionID > 0 && timePeriod !== '') {
			setLoading(true);
			dispatch(actionResetMessages());
			fetchData();
		}
	}, [session, timePeriod, pounds, showMessage, dispatch]);

	const handleDownload = async () => {
		if (session.SessionID > 0 && timePeriod !== '') {
			setDownloading(true);
			const { data } = await getDownloadFile(session.SessionID, timePeriod);
			await saveAs(data, 'weight_distribution.csv');
			setDownloading(false);
		}
	};

	// const size = {height: 240, width: 480}
	// const padding = {top: 40,right: 100,bottom: 40,left: 100,}
	const handleChange = (evt) => {
		evt.persist();
		if (evt.target.id === 'y-axis') {
			setYAxis(evt.target.value);
		} else {
			setTimePeriod(evt.target.value);
		}
	};
	useEffect(() => {
		let yFormat = '.1f';
		let cols = null;
		if (allColumns.length === 4) {
			// all data series are built and available
			try {
				const xList = [...allColumns[0]]; //clean array - weight group series
				xList.shift(); //remove first element which is "WtGroup"

				//force to actual numbers if not using eids in this session
				if (session.UseNoIDs && yAxis !== 'numberEid') {
					setYAxis('numberEid');
				}

				let ma5Series;
				switch (yAxis) {
					case 'numberEid':
						yFormat = ',.0f';
						ma5Series = allColumns[1];
						ma5Series.splice(0, 1, 'Number of EIDs'); //replace first array series description
						cols = [[...ma5Series]];
						break;
					case 'percentMob':
						yFormat = '.0f';
						ma5Series = allColumns[2];
						ma5Series.splice(0, 1, 'Percentage of Mob');
						cols = [[...ma5Series]];
						break;
					case 'estNumberInMob':
						yFormat = ',.0f';
						ma5Series = allColumns[3];
						ma5Series.splice(0, 1, 'Estimated Number in Mob');
						cols = [[...ma5Series]];
						break;
					default:
				}
				const config = {
					//x: 'WtGroup [kg]',
					type: 'bar',
					labels: true,
					//axes: (yAxis === 'percentMob')? {count: 'y'} : {percentMob: 'y'},
					// axes: {
					// 	count: 'y',
					// },
					zoom: {
						enabled: true,
					},
					//names: {
					//WtGroup: 'Weight Group',
					//count: yDesc,
					//},
					transition: {
						duration: 1000,
					},
				};
				if (cols.length === 1 && xList.length > 0) {
					const data = { ...{ columns: cols }, ...config };
					const axis = {
						x: {
							type: 'category',
							categories: xList,
							label: {
								text: (pounds ? '50 lb' : '25 kg') + ' Weight Range Buckets',
								position: 'outer-center',
							},
						},
						y: {
							label: {
								text: yAxisTypes[yAxis],
								position: 'outer-middle',
							},
							tick: {
								format: d3format(yFormat),
							},
						},
					};
					setC3Package({ bindto: '#chart', data: data, axis: axis });
				}
			} catch (error) {
				console.log('err', error);
			}
		} else if (allColumns.length === 0) {
			setC3Package(null); //clear the chart
		}
	}, [allColumns, yAxis, session, pounds]);

	useEffect(() => {
		if (c3Package !== null) {
			try {
				c3.generate(c3Package);
			} catch (error) {
				console.log('ERR initialising chart', error);
				dispatch(
					actionSetMessage('ERR initialising chart: ' + JSON.stringify(error))
				);
			}
		}
	}, [c3Package, dispatch]);

	/**
	 * Get total of array elements
	 */
	const getCountTotals = (data) => {
		let ma5Total = 0;
		for (let i = 0; i < data.length; i++) {
			if (data[i][0] === 'ma5EidCount') {
				for (let j = 1; j < data[i].length; j++) {
					const value = parseInt(data[i][j]);
					if (!isNaN(value)) {
						ma5Total += value;
					}
				}
			}
		}
		return { ma5Total: ma5Total };
	};

	const masterProps = {
		downloading,
		handleChange,
		handleDownload,
		session,
		setAllColumns,
		setTimePeriod,
		timePeriod,
		yAxis,
		yAxisTypes,
		pounds,
	};

	return (
		<Template {...masterProps}>
			{c3Package == null ? (
				<Alert
					className={'text-center'}
					variant={'secondary'}
					style={{ backgroundColor: 'rgb(233,236,239)' }}
				>
					<div className="w-50 mx-auto">
						<Loading loading={loading} />
					</div>
					<h2>
						{timePeriod === ''
							? 'Select period'
							: loading
								? 'Loading data...'
								: 'No data for this session.'}
					</h2>
				</Alert>
			) : (
				<>
					<h2 className={'only-print'}>Optiweigh - Weight distribution</h2>
					<Card body>
						<div className="text-center">
							<Loading loading={loading} />
						</div>
						<div id="chart" />
					</Card>
				</>
			)}
		</Template>
	);
};

const Template = (props) => (
	<div>
		<Header>
			<BC trail='[["Home", "/"], ["reports", "/"], ["weight distribution", ""]]' />
		</Header>
		<Container fluid className="body">
			<CheckAuth groupName={'user'} />
			<ParamPanel {...props} />
			{props.children}
		</Container>
		<Container fluid>
			<Row>
				<Col sm="12">
					<Footer />
				</Col>
			</Row>
		</Container>
	</div>
);

import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { CSSProperties } from 'react';

export type ControlProps = {
	cols: { lhs: number; rhs: number };
	required?: boolean;
	style?: CSSProperties;
	lock?: boolean;
};

const Required = (props: { required: boolean }) => {
	if (props.required) {
		return (
			<span style={{ color: 'red' }}>
				<sup>*</sup>
			</span>
		);
	}
	return null;
};

export const PaddockSize = (
	props: ControlProps & { value?: string; onChange: (a: string) => void }
) => {
	const { cols, value, onChange, style, lock, required } = props;

	return (
		<Row className="inputRow">
			<Col sm={cols.lhs}>
				Paddock size [Ha]
				<Required required={!!required} />:
			</Col>
			<Col sm={cols.rhs}>
				<Form.Control
					id="Paddock"
					placeholder="Enter paddock size..."
					onChange={(evt) => onChange(evt.target.value)}
					style={style}
					value={value}
					{...(lock && { disabled: true })}
				/>
			</Col>
		</Row>
	);
};

export const SessionName = (
	props: ControlProps & { value?: string; onChange?: (u: string) => void }
) => {
	const { cols, value, onChange, style, lock } = props;

	return (
		<Row className="inputRow">
			<Col sm={cols.lhs}>
				Session name
				<Required required={true} />:
			</Col>
			<Col sm={cols.rhs}>
				<Form.Control
					id="SessionName"
					placeholder="Enter session name..."
					onChange={(evt) => {
						if (onChange) onChange(evt.target.value);
					}}
					style={style}
					value={value}
					{...(lock && { disabled: true })}
				/>
			</Col>
		</Row>
	);
};

export const HeadCount = (
	props: ControlProps & { value?: string; onChange?: (u: string) => void }
) => {
	const { cols, value, onChange, style, lock } = props;

	return (
		<Row className="inputRow">
			<Col sm={cols.lhs}>
				Number of head
				<Required required={true} />:
			</Col>
			<Col sm={cols.rhs}>
				<Form.Control
					id="Head"
					placeholder="Enter head count..."
					onChange={(evt) => {
						if (onChange) onChange(evt.target.value);
					}}
					style={style}
					value={value}
					{...(lock && { disabled: true })}
				/>
			</Col>
		</Row>
	);
};

export const ShowOpenSessionsOnly = (props: {
	value: boolean;
	onChange: (a: boolean) => void;
}) => {
	const { value, onChange } = props;

	return (
		<Form.Check
			inline
			label="Hide closed sessions"
			id="show-open-sessions-only"
			onChange={(evt) => onChange(evt.target.checked)}
			checked={value}
			className="sessionListFilterCB"
		/>
	);
};

export const SessionEndDate = (
	props: ControlProps & { value: Date | null; onChange?: (a: Date) => void }
) => {
	const { cols, value, style, onChange, lock } = props;

	return (
		<Row className="inputRow">
			<Col sm={cols.lhs}>Session end:</Col>
			<Col sm={cols.rhs}>
				<DatePicker
					id="sessionEndObj"
					className={'datepickerxx form-control'}
					dateFormat={'dd MMM yyyy HH:mm'}
					selected={value}
					showTimeSelect
					todayButton={'Now'}
					timeIntervals={15}
					scrollableYearDropdown={true}
					showYearDropdown={true}
					onChange={onChange}
					style={style}
					{...(lock && { disabled: true })}
				/>
			</Col>
		</Row>
	);
};

/**
 * Select the date
 * direct edit of calendar
 */
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import DatePicker from 'react-datepicker';

//export
const stdDateFormatStr = 'yyyy-MM-dd';

/*
	change a date to being appropriate for it's time period
	Always returns a valid date. Returns now if given a bad date
*/
//export
export const DateRangeControl = (props) => {
	const { lowerDate, setLowerDate, upperDate, setUpperDate, loading } = props;

	const handleLowerDateChange = (dateIn) => {
		//setDateParam(shoeBoxDate(dateIn, timePeriod, lowerDate, upperDate))
		setLowerDate(dateIn);
	};

	const handleUpperDateChange = (dateIn) => {
		//setDateParam(shoeBoxDate(dateIn, timePeriod, lowerDate, upperDate))
		setUpperDate(dateIn);
	};

	return (
		<Row>
			<Col sm={4}>
				<p style={{ paddingTop: '0.3em' }}>Date range:</p>
			</Col>
			<Col sm={4}>
				<DatePicker
					className={'daterangepicker form-control'}
					dateFormat={stdDateFormatStr}
					selected={lowerDate}
					//startDate = { lowerDate }
					//minDate = { lowerDate }
					maxDate={upperDate}
					scrollableYearDropdown={true}
					showYearDropdown={true}
					onChange={handleLowerDateChange}
					//style = {{textAlign: 'center'}}
					{...(loading && { disabled: true })}
				/>
			</Col>
			<Col sm={4}>
				<DatePicker
					className={'daterangepicker form-control'}
					dateFormat={stdDateFormatStr}
					selected={upperDate}
					startDate={lowerDate}
					minDate={lowerDate}
					//maxDate = { upperDate }
					scrollableYearDropdown={true}
					showYearDropdown={true}
					onChange={handleUpperDateChange}
					//style = {{textAlign: 'center'}}
					{...(loading && { disabled: true })}
				/>
			</Col>
		</Row>
	);
};

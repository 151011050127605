import React, { useState, useEffect, useCallback } from 'react';

import { useSelector, useDispatch } from 'react-redux';

import {
	//actionSetMessage,
	actionResetMessages,
} from 'components/messages/actions.js';

import { ClientSelect } from './clientSelect/clientSelect.js';
import {
	actionSetClientId,
	actionFetchClientIds,
} from './clientSelect/redux';

import { SessionSelect } from './sessionSelect/sessionSelect.js';
import { actionSetSession, actionFetchSessionIds } from './sessionSelect/redux';
import { actionFetchAuthorisation } from 'pages/auth/redux';

import {
	actionFetchWeighersSuccess,
	actionSetWeighersLoaded,
} from 'components/weighers/reduxSlice';

/**
 *
 * Clients are initially selected based on what clients the user is associated with.
 * Often it is just a single client in which case it is displayed but not selectable.
 *
 * Sessions are then fetched based on the client and a single session can be selected.
 * All Session and client selections etc are stored in a global app redux store so it
 * can be accessed from any page as it is frequently used for data retrieval criteria.
 *
 * It has been written as a single unified control set to avoid race conditions and loops etc.
 */

export const ClientSessionSelect = (props) => {
	const {
		layout,
		hideSessionSelect,
		handleBeforeSessionChange,
		lock,
		includeYardWeights,
	} = props;
	const dispatch = useDispatch();
	const session = useSelector((state) => state.session);
	const sessionIds = useSelector((state) => state.sessionIds);
	const clientId = useSelector((state) => state.clientId);
	const clientIds = useSelector((state) => state.clientIds);
	const clientIdsLoaded = useSelector((state) => state.clientIdsLoaded);
	const currentUser = useSelector((state) => state.currentUser);

	const [clientLoading, setClientLoading] = useState(false);

	const [sessionLoading, setSessionLoading] = useState(false);

	useEffect(() => {
		if (currentUser.username.length > 3 && !clientIdsLoaded) {
			// single shot - only ever loaded once
			//to stop it looping where the user has 0 clients
			setClientLoading(true);
			dispatch(actionResetMessages());
			dispatch(actionFetchClientIds(setClientLoading));
		}
	}, [currentUser, dispatch, clientIdsLoaded]);

	useEffect(() => {
		if (currentUser.username.length > 3 && clientId > 0) {
			setSessionLoading(true);
			dispatch(actionResetMessages());
			dispatch(actionFetchAuthorisation());
			dispatch(
				actionFetchSessionIds(clientId, includeYardWeights, setSessionLoading)
			);
		}
	}, [currentUser, clientId, includeYardWeights, dispatch]);

	const isSessionValid = useCallback(
		(session_id) => {
			if (session_id == null || session_id === undefined) return false;
			const session = Number(session_id);
			if (isNaN(session) || session === 0) return false;

			for (const s of sessionIds) {
				if (s.SessionID === session) return true;
			}
			return false;
		},
		[sessionIds]
	);
	// select session
	useEffect(() => {
		if (session && isSessionValid(session.SessionID)) return;

		// load previous settings
		try {
			const cookie_session = JSON.parse(localStorage.getItem('session'));
			if (cookie_session && isSessionValid(cookie_session.SessionID)) {
				dispatch(actionSetSession(cookie_session));
				return;
			}
		} catch (error) {
			console.log('unable to load session from localStorage', error);
		}

		if (sessionIds.length > 0) {
			// set to first available session if exists
			dispatch(actionSetSession(sessionIds[0]));
		} else {
			// reset to empty
			//dispatch(actionSetSession({}));
		}
	}, [sessionIds, session, isSessionValid, dispatch]);

	const handleChangeClient = (evt) => {
		dispatch(actionFetchWeighersSuccess([])); // reset weighers causes a reload as the client is changing
		dispatch(actionSetClientId(evt.target.value));
		dispatch(actionSetWeighersLoaded(false)); //initiate reload
		localStorage.setItem('clientId', evt.target.value);
	};

	const handleChangeSession = (evt) => {
		evt.persist();
		const session = sessionIds.filter((sess) => {
			return sess.SessionID === Number(evt.target.value);
		});
		handleBeforeSessionChange(); //fire this before changing the store value
		if (session[0] !== undefined) {
			dispatch(actionSetSession(session[0]));
			localStorage.setItem('session', JSON.stringify(session[0]));
		}
	};
	const hideSessions = hideSessionSelect ? true : false; //default to false if not set
	return (
		<>
			<ClientSelect
				clientLoading={clientLoading}
				handleChange={handleChangeClient}
				clientId={clientId}
				clientIds={clientIds}
				lock={lock || false}
			/>
			{props.children}
			{!hideSessions && (
				<SessionSelect
					sessionLoading={sessionLoading}
					handleChange={handleChangeSession}
					//handleFilteredChange = { handleFilteredChange }
					session={session}
					sessionIds={sessionIds}
					clientId={clientId}
					layout={layout}
					lock={lock || false}
				/>
			)}
		</>
	);
};

import React from 'react';

import Header from 'components/header.js';
import Footer from 'components/footer.js';
import Container from 'react-bootstrap/Container';
import Breadcrumb from 'react-bootstrap/Breadcrumb';
import Card from 'react-bootstrap/Card';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { Link } from 'react-router-dom';
//import Button from 'react-bootstrap/Button';

const BC = () => {
	return (
		<Breadcrumb>
			<Breadcrumb.Item linkAs={Link} linkProps={{ to: '/' }}>
				Home
			</Breadcrumb.Item>
			<Breadcrumb.Item active>Staff</Breadcrumb.Item>
		</Breadcrumb>
	);
};

export const Staff = (props) => {
	return (
		<Container fluid>
			<Header>
				<BC />
			</Header>
			{/* <h1>Staff Portal - Superuser visability only</h1> */}

			{/* <h3>More to come ....</h3> */}

			<Card body>
				<Card.Title>Staff Portal - Superuser visability only</Card.Title>
				<Card.Body>
					<Row>
						<Col>
							{/* <Button variant='info'><Link to = "/staff/dbsysLog">DBsys Log</Link></Button><br />
						<Button variant='info'><Link to = "/staff/dailyEmail">Daily Email</Link></Button><br />
						<Button variant='info'><Link to = "/staff/yardWeights">Yard Weights</Link></Button><br /> */}

							<ul>
								<li>
									<Link to="/staff/dbsysLog">DBsys Log</Link>
								</li>
								<li>
									<Link to="/staff/dailyEmail">Daily Email</Link>
								</li>
								<li>
									<Link to="/staff/yardWeights">Yard Weights</Link>
								</li>
							</ul>
						</Col>
						<Col></Col>
					</Row>
				</Card.Body>
			</Card>

			<Footer />
		</Container>
	);
};

import { useEffect, useState, useCallback, ReactNode, useMemo } from 'react';
import { AppDispatch } from 'index';
import { useAppDispatch, useAppSelector } from 'hooks';

import Header from 'components/header.js';
import Footer from 'components/footer.js';
import OPWBreadcrumb from 'components/breadcrumb.js';
import Container from 'react-bootstrap/Container';
import Card from 'react-bootstrap/Card';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import Button from 'react-bootstrap/Button';
import {
	AiOutlineCloseCircle,
	AiFillPlusCircle,
	AiOutlineEdit,
	AiFillSave,
} from 'react-icons/ai';
import ListGroup from 'react-bootstrap/ListGroup';
import Form from 'react-bootstrap/Form';
import { ClientSelect } from 'components/clientSessionSelect/clientSelect/clientSelect';
import Loading from 'components/loading';
import {
	actionFetchUserList,
	actionUpdateUserRole,
	actionRemoveUser,
	actionAddUser,
	actionFetchZohoAccount,
	actionFetchAccountDetails,
	actionSyncAccountFromZoho,
	GetUserResult,
	UserDetails,
	ClientDetails,
	actionResetPassword,
} from './actions';
import { actionFetchClientIds } from 'components/clientSessionSelect/clientSelect/redux';
import { actionFetchAuthorisation, isSuperuser } from 'pages/auth/redux';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import {
	actionSetMessage,
	actionResetMessages,
} from 'components/messages/actions.js';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import Alert from 'react-bootstrap/Alert';
import { CrmLink } from 'components/crmLink';

import { QueryClient, QueryClientProvider } from 'react-query';
import { EmailSubsModel, useEmailSubsModel } from 'components/dailyEmail/model';
import { DailyEmailToggle } from 'components/dailyEmail/dailyEmailToggle';
import { GenericModal } from 'components/genericModal';

const ROLE_NAMES: { [key: string]: string } = {
	user: 'User',
	admin: 'Admin',
	owner: 'Owner',
	techsupport: 'Tech Support',
};
// Column widths are set to match ClientSelect
const COLS = {
	lhs: 3,
	rhs: 9,
};

const queryClient = new QueryClient({
	defaultOptions: { queries: { refetchOnWindowFocus: false } },
});

const BlueTick = () => {
	return <span style={{ color: 'blue' }}>&#10003;</span>;
};
const RolePermissionsText = () => {
	return (
		<Card body>
			<Card.Title>Role permissions</Card.Title>
			<Card.Body>
				<table cellPadding={5} style={{ textAlign: 'center' }}>
					<thead>
						<tr
							style={{
								fontWeight: 'bold',
							}}
						>
							<th></th>
							<th>User</th>
							<th>Admin</th>
							<th>Owner</th>
						</tr>
					</thead>
					<tbody>
						<tr>
							<td>View data</td>
							<td>
								<BlueTick />
							</td>
							<td>
								<BlueTick />
							</td>
							<td>
								<BlueTick />
							</td>
						</tr>
						<tr>
							<td>Manage sessions</td>
							<td></td>
							<td>
								<BlueTick />
							</td>
							<td>
								<BlueTick />
							</td>
						</tr>
						<tr>
							<td>Manage account users</td>
							<td></td>
							<td></td>
							<td>
								<BlueTick />
							</td>
						</tr>
					</tbody>
				</table>
			</Card.Body>
		</Card>
	);
};

const server_error = (dispatch: AppDispatch) => {
	dispatch(
		actionSetMessage('A server error occurred. Try again or contact support.')
	);
};

const ActionButton = (props: {
	onClick?: () => void;
	children?: ReactNode;
}) => {
	return (
		<Button variant="outline-primary" size="sm" onClick={props.onClick}>
			{props.children}
		</Button>
	);
};
const RoleOptions = (props: {
	role: string;
	roleOptions: string[];
	roleChanged: (r: string) => void;
}) => {
	const { role, roleChanged, roleOptions } = props;

	return (
		<Form.Control
			as="select"
			value={role}
			onChange={(evt) => roleChanged(evt.target.value)}
		>
			{roleOptions.map((i) => (
				<option value={i} key={i}>
					{ROLE_NAMES[i]}
				</option>
			))}
		</Form.Control>
	);
};
const TextInputRow = (props: {
	title: string;
	value: string;
	valueChanged: (v: string) => void;
}) => {
	const { title, value, valueChanged } = props;
	return (
		<Row className="inputRow">
			<Col sm={COLS.lhs}>{title}:</Col>
			<Col sm={COLS.rhs}>
				<Form.Control
					placeholder={`Enter ${title.toLowerCase()}...`}
					onChange={(evt) => valueChanged(evt.target.value)}
					value={value}
				/>
			</Col>
		</Row>
	);
};
const EditButtons = (props: {
	emailModel?: EmailSubsModel;
	editClick: () => void;
	removeClick: () => void;
	passwordResetClick?: () => void;
}) => {
	const { emailModel, editClick, removeClick, passwordResetClick } = props;
	return (
		<>
			{emailModel && (
				<>
					<label>
						<DailyEmailToggle model={emailModel} />
						<span style={{ verticalAlign: 'top' }}> Daily Email</span>
					</label>
					<br />
				</>
			)}
			<ButtonGroup>
				{passwordResetClick && (
					<ActionButton onClick={passwordResetClick}>
						Resend Invite
					</ActionButton>
				)}
				<ActionButton onClick={editClick}>
					<AiOutlineEdit size={'20'} /> Change role
				</ActionButton>
				<ActionButton onClick={removeClick}>
					<AiOutlineCloseCircle size={'20'} /> Remove
				</ActionButton>
			</ButtonGroup>
		</>
	);
};
const UserItem = (props: {
	user: GetUserResult;
	roleOptions: string[];
	allowEdit?: boolean;
	editing?: boolean;
	editClick: (id: number) => void;
	saveEdit: (email: string, role: string) => void;
	cancelEdit: () => void;
	removeClick: (email: string) => void;
	passwordResetClick?: (email: string) => void;
}) => {
	const { passwordResetClick } = props;
	const can_edit = props.allowEdit && props.user.canEdit;
	const { user, roleOptions } = props;
	const [role, set_role] = useState<string>(props.user.role!);
	const has_email = 'dailyEmail' in user;
	const email_model = useEmailSubsModel(
		has_email ? user.email : null,
		has_email ? { dailyEmail: !!user.dailyEmail } : null
	);

	return (
		<ListGroup.Item
			style={{
				borderRadius: 5,
				marginTop: 5,
				marginBottom: 5,
				boxShadow: '0px 0px 6px -4px #000',
			}}
		>
			<div style={{ float: 'left' }}>
				<h5>{user.name ?? user.email}</h5>
			</div>
			{user.link && (
				<div style={{ float: 'right' }}>
					<CrmLink target={user.link} />
				</div>
			)}
			{user.name ? (
				<p style={{ clear: 'both', color: '#bbb' }}>{user.email}</p>
			) : (
				<div style={{ clear: 'both' }} />
			)}
			<div style={{ float: 'left' }}>
				{props.editing ? (
					<RoleOptions
						role={role}
						roleChanged={set_role}
						roleOptions={roleOptions}
					/>
				) : (
					ROLE_NAMES[role]
				)}
			</div>
			{can_edit && (
				<div style={{ float: 'right' }}>
					<EditButtons
						editClick={() => props.editClick(user.id!)}
						removeClick={() => props.removeClick(user.email)}
						{...(passwordResetClick && {
							passwordResetClick: () => passwordResetClick(user.email),
						})}
						{...(has_email && { emailModel: email_model })}
					/>
				</div>
			)}
			{props.editing && (
				<div style={{ clear: 'both', paddingTop: '1em' }}>
					<ButtonGroup>
						<ActionButton onClick={() => props.saveEdit(user.email, role)}>
							<AiFillSave size="20" /> Save
						</ActionButton>
						<ActionButton onClick={() => props.cancelEdit()}>
							Cancel
						</ActionButton>
					</ButtonGroup>
				</div>
			)}
		</ListGroup.Item>
	);
};
const UserList = (props: {
	userList: GetUserResult[];
	roleOptions: string[];
	saveEdit: (email: string, role: string) => void;
	removeClick: (email: string) => void;
	passwordResetClick?: (email: string) => void;
}) => {
	const { userList, roleOptions, passwordResetClick } = props;

	const [editing_id, set_editing_id] = useState<number | null>(null);
	const allow_edit = editing_id == null;

	return (
		<Row className="inputRow">
			<ListGroup style={{ width: '100%', marginLeft: 10, marginRight: 10 }}>
				{userList.map((user) => (
					<UserItem
						key={user.id}
						user={user}
						roleOptions={roleOptions}
						editing={user.id === editing_id}
						allowEdit={allow_edit}
						editClick={(id) => set_editing_id(id)}
						saveEdit={props.saveEdit}
						cancelEdit={() => set_editing_id(null)}
						removeClick={props.removeClick}
						passwordResetClick={passwordResetClick}
					/>
				))}
			</ListGroup>
		</Row>
	);
};
const CrmContactsList = (props: {
	contacts: GetUserResult[];
	selected: string;
	onChanged: (email: string) => void;
}) => {
	const { contacts, selected, onChanged } = props;

	return (
		<Row className="inputRow">
			<Col sm={COLS.lhs}>CRM Contacts:</Col>
			<Col sm={COLS.rhs}>
				<Form.Control
					as="select"
					style={{ appearance: 'listbox' }}
					value={selected}
					onChange={(evt) => {
						onChanged(evt.target.value);
					}}
				>
					<option value={''} key={''}>
						[New User]
					</option>
					{contacts.map((contact) => (
						<option value={contact.email} key={contact.email}>
							{contact.name} ({contact.email})
						</option>
					))}
				</Form.Control>
			</Col>
		</Row>
	);
};
const UserAdd = (props: {
	suggestions: GetUserResult[];
	roleOptions: string[];
	onSave: (user: UserDetails) => void;
	onCancel: () => void;
}) => {
	const { suggestions, roleOptions, onSave, onCancel } = props;

	const [suggestion_selected, set_suggestion_selected] = useState('');
	const [is_modified, set_modified] = useState(false);
	const [email, set_email] = useState('');
	const [first_name, set_first_name] = useState('');
	const [last_name, set_last_name] = useState('');
	const [phone, set_phone] = useState('');
	const [role, set_role] = useState('user');
	const [validation_error, set_validation_error] = useState('');

	const run_validation = () => {
		const EMAIL_REGEX =
			/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

		if (email.length === 0) return 'Enter an email address';
		if (!email.match(EMAIL_REGEX)) return 'Email address is invalid';
		if (first_name.length === 0) return 'Enter a first name';
		if (last_name.length === 0) return 'Enter a last name';
		if (phone.length === 0) return 'Enter a phone number';
		if (!phone.match(/^\+[1-9]\d{3,14}$/)) return 'Phone number is invalid';
		return '';
	};
	const handle_save = () => {
		if (suggestion_selected) {
			onSave({ email: suggestion_selected, role });
		} else {
			const val_msg = run_validation();
			set_validation_error(val_msg);

			if (val_msg.length === 0)
				onSave({ email, first_name, last_name, phone, role });
		}
	};
	const handle_email = (value: string) => {
		set_email(value);
		set_modified(true);
	};
	const handle_first_name = (value: string) => {
		set_first_name(value);
		set_modified(true);
	};
	const handle_last_name = (value: string) => {
		set_last_name(value);
		set_modified(true);
	};
	const handle_phone = (value: string) => {
		set_phone('+' + value);
		set_modified(true);
	};

	return (
		<>
			{suggestions && suggestions.length > 0 && (
				<CrmContactsList
					contacts={suggestions}
					selected={suggestion_selected}
					onChanged={(email) => {
						set_suggestion_selected(email);
						set_modified(email !== '');
					}}
				/>
			)}
			{!suggestion_selected && (
				<>
					<TextInputRow
						title="Email address"
						value={email}
						valueChanged={handle_email}
					/>
					<TextInputRow
						title="First name"
						value={first_name}
						valueChanged={handle_first_name}
					/>
					<TextInputRow
						title="Last name"
						valueChanged={handle_last_name}
						value={last_name}
					/>
					<Row className="inputRow">
						<Col sm={COLS.lhs}>Phone:</Col>
						<Col sm={COLS.rhs}>
							<PhoneInput
								country={'au'}
								preferredCountries={['au', 'nz', 'gb', 'ca', 'us', 'uy']}
								masks={{ au: '... ... ...' }}
								onChange={handle_phone}
								value={phone}
							/>
						</Col>
					</Row>
				</>
			)}
			<Row className="inputRow">
				<Col sm={COLS.lhs}>Role:</Col>
				<Col sm={COLS.rhs}>
					<RoleOptions
						role={role}
						roleChanged={set_role}
						roleOptions={roleOptions}
					/>
				</Col>
			</Row>
			<Row className="inputRow">
				<Col sm={COLS.lhs} />
				<Col sm={COLS.rhs}>
					{validation_error.length > 0 && (
						<p className="validationError">{validation_error}</p>
					)}
				</Col>
			</Row>
			<Row className="inputRow">
				<Col className="text-right">
					<ButtonGroup>
						<Button
							type="submit"
							variant="outline-primary"
							{...(!is_modified && {
								disabled: true,
								variant: 'outline-secondary',
							})}
							onClick={handle_save}
						>
							<AiFillPlusCircle size={'32'} /> Add user
						</Button>
						<Button variant="outline-primary" onClick={() => onCancel()}>
							Cancel
						</Button>
					</ButtonGroup>
				</Col>
			</Row>
		</>
	);
};
const ZohoSync = (props: {
	account?: ClientDetails;
	handleUpdate: () => void;
}) => {
	const { account, handleUpdate } = props;
	return (
		<Row className="inputRow">
			<Col className="text-right">
				{account?.ZohoNameUpdate ? (
					<Alert variant={'secondary'}>
						<p style={{ textAlign: 'center' }}>
							The client name has been changed to{' '}
							<a href={account.link} target="_blank" rel="noreferrer">
								{account.ZohoNameUpdate}
							</a>{' '}
							in Zoho CRM.
						</p>
						<Button onClick={handleUpdate}>Update name</Button>
					</Alert>
				) : (
					<CrmLink target={account?.link} />
				)}
			</Col>
		</Row>
	);
};
export function AccountManagement() {
	const dispatch = useAppDispatch();
	const authorisation = useAppSelector((state) => state.authorisation);
	const clientIds = useAppSelector((state) => state.clientIds);
	const clientIdsLoaded = useAppSelector((state) => state.clientIdsLoaded);
	const clientId = useAppSelector((state) => state.clientId);
	const currentUser = useAppSelector((state) => state.currentUser);
	const is_superuser = useMemo(() => isSuperuser(currentUser), [currentUser]);

	const [auth_checked, set_auth_checked] = useState(false);
	const [client_list_loading, set_clients_loading] = useState(false);
	const [selected_client, set_selected_client] = useState<number | null>(null);
	const [is_adding, set_is_adding] = useState(false);
	const [userlist_loading, set_userlist_loading] = useState(false);
	const [userlist, set_userlist] = useState<GetUserResult[]>([]);
	const [zoho_loading, set_zoho_loading] = useState(false);
	const [zoho_lookup, set_zoho_lookup] = useState<ClientDetails | null>(null);
	const [pwd_reset_user, set_pwd_reset_user] = useState<string | null>(null);

	const user_owned = useMemo(
		() =>
			authorisation?.filter((auth) =>
				['owner', 'techsupport'].includes(auth.groupCode)
			),
		[authorisation]
	);
	const owner_clients = useMemo(
		() =>
			clientIds?.filter(
				(val) =>
					is_superuser ||
					user_owned?.find((auth) => auth.clientId === val.ClientID)
			),
		[is_superuser, clientIds, user_owned]
	);
	const is_tech_support = useMemo(
		() =>
			is_superuser ||
			!!authorisation?.find(
				(auth) =>
					auth.clientId === selected_client && auth.groupCode === 'techsupport'
			),
		[is_superuser, authorisation, selected_client]
	);
	const assignable_roles = useMemo(
		() => [
			'user',
			'admin',
			...(is_tech_support ? ['owner'] : []),
			...(is_superuser ? ['techsupport'] : []),
		],
		[is_tech_support, is_superuser]
	);

	// need authorisation
	useEffect(() => {
		if (currentUser.username.length <= 3) return;
		if (!auth_checked) {
			dispatch(actionFetchAuthorisation());
			set_auth_checked(true);
		}
		if (!clientIdsLoaded) {
			if (client_list_loading) return;
			set_clients_loading(true);
			dispatch(actionFetchClientIds(set_clients_loading));
		}
	}, [
		currentUser,
		auth_checked,
		clientIdsLoaded,
		client_list_loading,
		dispatch,
	]);
	// search by zoho crm id if query string is present
	useEffect(() => {
		if (currentUser.username.length <= 3) return;
		const url_params = new URLSearchParams(window.location.search);
		const zoho_id = url_params.get('zohoCrmId');
		if (!zoho_id) return;

		set_zoho_loading(true);
		actionFetchZohoAccount(BigInt(zoho_id)).then((data) => {
			if (data.length !== 1) return;

			if (data[0].ClientID) {
				set_zoho_lookup(data[0]);
				set_selected_client(data[0].ClientID);
				set_zoho_loading(false);
			} else {
				// redirect to new account page
				window.location.replace(
					window.location.origin +
						'/manage/accounts/new' +
						window.location.search
				);
			}
		});
	}, [currentUser]);
	// validate/initialise selection
	useEffect(() => {
		if (client_list_loading || zoho_loading) return;

		if (
			selected_client &&
			owner_clients?.find((c) => c.ClientID === selected_client)
		) {
			// selected client is valid
			return;
		}
		if (owner_clients?.length) {
			const prev_client = clientId;
			if (
				!!prev_client &&
				owner_clients.find((c) => c.ClientID === prev_client)
			) {
				// use the last selected client
				set_selected_client(prev_client);
			} else {
				set_selected_client(owner_clients[0].ClientID);
			}
			return;
		}
		if (selected_client) {
			// selected client is not one that the user is owner
			set_selected_client(null);
		}
	}, [
		client_list_loading,
		zoho_loading,
		selected_client,
		owner_clients,
		clientId,
	]);
	// load user list
	useEffect(() => {
		let abort = false;
		if (!selected_client) return;

		set_userlist_loading(true);
		const fetch_fn = async () => {
			const users = await actionFetchUserList(selected_client);
			if (abort) return;

			set_userlist_loading(false);
			set_userlist(users);
		};
		fetch_fn();

		if (!zoho_lookup || zoho_lookup.ClientID !== selected_client) {
			set_zoho_lookup(null);
			set_zoho_loading(true);
			actionFetchAccountDetails(selected_client)
				.then((data) => {
					if (data.length !== 1) return;

					set_zoho_lookup(data[0]);
				})
				.catch((err) => console.log(err))
				.finally(() => set_zoho_loading(false));
		}

		return () => {
			abort = true;
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [selected_client]);

	const handle_change_client = useCallback((evt: any) => {
		const client_id = parseInt(evt.target.value);
		set_selected_client(client_id);
		// clear the query string parameter once the client changes
		const url = window.location.origin + window.location.pathname;
		window.history.pushState({ path: url }, '', url);
	}, []);
	const handle_update_client_name = useCallback(() => {
		if (!zoho_lookup?.ZohoCrmId) return;
		set_clients_loading(true);
		actionSyncAccountFromZoho(BigInt(zoho_lookup.ZohoCrmId))
			.then(() => {
				dispatch(actionFetchClientIds(set_clients_loading));
				const update = Object.assign({}, zoho_lookup);
				delete update.ZohoNameUpdate;
				set_zoho_lookup(update);
			})
			.catch((err) => {
				set_clients_loading(false);
				dispatch(actionSetMessage(err));
			});
	}, [zoho_lookup, dispatch]);
	const handle_add_save = useCallback(
		(details: UserDetails) => {
			if (!selected_client) return;
			dispatch(actionResetMessages());
			set_userlist_loading(true);

			actionAddUser(selected_client, details)
				.then((success) => {
					console.log(`user added ${success}`);
					if (!success) server_error(dispatch);
					return actionFetchUserList(selected_client);
				})
				.then((users) => {
					set_userlist_loading(false);
					set_userlist(users);
				})
				.catch((err) => {
					console.log(`error adding user ${err}`);
					server_error(dispatch);
				});
			set_is_adding(false);
		},
		[selected_client, dispatch]
	);
	const handle_save_edit = useCallback(
		(email: string, new_role: string) => {
			if (!selected_client) return;
			dispatch(actionResetMessages());
			set_userlist_loading(true);
			actionUpdateUserRole(selected_client, email, new_role)
				.then((success) => {
					console.log(`user update ${success}`);
					const new_userlist = userlist.map((u) => {
						if (u.email === email) u.role = new_role;
						return u;
					});
					set_userlist(new_userlist);
				})
				.catch((err) => {
					console.log(`error updating user ${err}`);
					server_error(dispatch);
				})
				.finally(() => {
					set_userlist_loading(false);
				});
		},
		[dispatch, userlist, selected_client]
	);
	const handle_remove = useCallback(
		(email: string) => {
			if (!selected_client) return;
			dispatch(actionResetMessages());
			set_userlist_loading(true);
			console.log(`removing ${email}`);
			actionRemoveUser(selected_client, email)
				.then((success) => {
					console.log(`user remove ${success ? 'ok' : 'failed'}`);

					set_userlist(
						userlist.filter((u) => {
							return u.email !== email;
						})
					);
				})
				.catch((err) => {
					console.log(`error removing user ${err}`);
					server_error(dispatch);
				})
				.finally(() => {
					set_userlist_loading(false);
				});
		},
		[dispatch, selected_client, userlist]
	);
	const passwordResetClick = useCallback(
		(email: string) => {
			if (is_tech_support) set_pwd_reset_user(email);
		},
		[is_tech_support]
	);
	const handle_password_reset = useCallback(() => {
		if (pwd_reset_user) actionResetPassword(pwd_reset_user);
		set_pwd_reset_user(null);
	}, [pwd_reset_user]);

	const show_add_button = selected_client != null && !is_adding;

	return (
		<QueryClientProvider client={queryClient}>
			<Header>
				<OPWBreadcrumb trail='[["Home", "/"], ["Account management", ""]]' />
			</Header>
			<Container fluid className="body">
				<GenericModal
					title="Resend Cognito Invite"
					text="Are you sure you want to reset this users password?"
					show={pwd_reset_user != null}
					buttons={{
						yes: { label: 'Yes', onClick: handle_password_reset },
						no: { label: 'No', onClick: () => set_pwd_reset_user(null) },
					}}
				/>
				<Row>
					<Col sm="8">
						<Card body>
							<Card.Title>Manage Accounts</Card.Title>
							<Card.Body>
								<ClientSelect
									clientLoading={client_list_loading}
									handleChange={handle_change_client}
									clientId={selected_client}
									clientIds={owner_clients}
									lock={is_adding}
								/>
								{zoho_lookup ? (
									<ZohoSync
										account={zoho_lookup}
										handleUpdate={handle_update_client_name}
									/>
								) : (
									<br />
								)}
								<Row className="inputRow">
									<Col className="text-right">
										{show_add_button && (
											<Button
												size="sm"
												variant="outline-primary"
												onClick={() => set_is_adding(true)}
											>
												<AiFillPlusCircle size={'32'} /> Add User
											</Button>
										)}
									</Col>
								</Row>
								<Loading loading={userlist_loading} />
								{is_adding ? (
									<UserAdd
										suggestions={userlist.filter((u) => u.id == null)}
										roleOptions={assignable_roles}
										onSave={handle_add_save}
										onCancel={() => {
											set_is_adding(false);
										}}
									/>
								) : (
									!userlist_loading && (
										<UserList
											userList={userlist.filter((u) => u.id != null)}
											roleOptions={assignable_roles}
											saveEdit={handle_save_edit}
											removeClick={handle_remove}
											{...(is_tech_support && { passwordResetClick })}
										/>
									)
								)}
							</Card.Body>
						</Card>
					</Col>
					<Col sm="4">
						<RolePermissionsText />
					</Col>
				</Row>
			</Container>
			<Container fluid>
				<Footer />
			</Container>
		</QueryClientProvider>
	);
}

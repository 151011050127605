import { AppDispatch } from 'index';
import API from 'services/api.js';
import { URL_GET_TIMEZONES } from 'services/urls.js';

const SET_TIMEZONES = 'SET_TIMEZONES';
const SET_TIMEZONES_LOADED = 'SET_TIMEZONES_LOADED';

// reducers
export function timezones(
	state: string[] = [],
	action: { type?: string; data?: string[] }
) {
	switch (action.type) {
		case SET_TIMEZONES:
			return action.data;
		default:
			return state;
	}
}

export function timezonesLoaded(
	state = false,
	action: { type?: string; data?: boolean }
) {
	switch (action.type) {
		case SET_TIMEZONES_LOADED:
			return action.data;
		default:
			return state;
	}
}

// actions
export const actionFetchTimezonesSuccess = (data: string[]) => {
	return {
		type: SET_TIMEZONES,
		data,
	};
};

export const actionSetTimezonesLoaded = (data: boolean) => {
	// data is true or false
	return {
		type: SET_TIMEZONES_LOADED,
		data,
	};
};

export const actionFetchTimezones = (
	setWloading: (l: boolean) => void,
	showMessage?: (msg: string) => void
) => {
	return (dispatch: AppDispatch) => {
		return API.get(URL_GET_TIMEZONES)
			.then((response) => {
				return response.data;
			})
			.catch((error) => {
				console.log('error', error);
				if (showMessage) showMessage('Err fetching timezones: ' + error);
				return [];
			})
			.then((resp) => {
				setWloading(false);
				dispatch(actionSetTimezonesLoaded(true));
				dispatch(
					actionFetchTimezonesSuccess(resp.map((ts: any) => ts.timezone))
				);
			});
	};
};

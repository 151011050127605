import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { URL_GET_TIMEZONES, URL_WEIGHERS } from 'services/urls';
import API from 'services/api.js';
import { AppState } from 'reducers';

type ClientBrief = {
	id: number | null;
	name?: string;
	link?: string;
};
type HardwareId =
	| { rut: string } // RUT054
	| { swarm: string } // 090DF
	| { wic2mac: string } // 7CDFA15519DE
	| { unknown: string };
type Weigher = {
	WeigherID: number;
	client: ClientBrief;
	Species?: string;
	hardwareId?: HardwareId;
	name?: string;
	defaultTimezone?: string;
};
type WeigherInfo = Weigher & {
	link?: string;
	changes: {
		client?: ClientBrief;
		Species?: string;
		defaultTimezone?: string;
		hardwareId?: HardwareId;
	};
	hardwareChangeTime?: Date;
};

const fetchWeigher = (weigher_id: number): Promise<WeigherInfo> => {
	return API.get(URL_WEIGHERS, { params: { id: weigher_id } }).then((resp) => {
		if (resp.data && resp.data.length === 1) {
			return resp.data[0];
		}
		throw new Error(`Weigher ${weigher_id} not found`);
	});
};

export type ValueDisplay = {
	text: string;
	link?: string;
	error?: string;
};
export type DataItem = {
	label: string;
	current: ValueDisplay;
	update?: ValueDisplay;
};
export type UserEditItem = {
	label: string;
	current: string;
	update: string | null;
	options?: string[];
	setValue: (new_value: string | null) => void;
};

export type WeigherModel = {
	isLoading: boolean;
	isError: boolean;
	error?: string;
	crmLink?: string;
	isNew?: boolean;
	canSave?: boolean;
	crmValues?: DataItem[];
	userValues?: UserEditItem[];
	isSaving?: boolean;
	saveHandler?: () => void;
};

export const useWeigherModel = (weigher_id: number): WeigherModel => {
	const currentUser = useSelector((state: AppState) => state.currentUser);
	const has_api_token = currentUser.username.length > 3;
	const [name, set_name] = useState<string | null>(null);
	const [timezone, set_timezone] = useState<string | null>(null);
	const { isLoading, isError, data, error, isFetching } = useQuery(
		['weigher', weigher_id],
		() => fetchWeigher(weigher_id),
		{ enabled: has_api_token, refetchOnWindowFocus: true }
	);
	const timezones = useQuery(
		'timezone-list',
		() => {
			return API.get(URL_GET_TIMEZONES).then((resp) => {
				if (resp.data && Array.isArray(resp.data)) {
					return resp.data.map((tz) => tz.timezone);
				}
				throw new Error('bad timezones');
			});
		},
		{ enabled: has_api_token }
	);
	const queryClient = useQueryClient();
	const post = useMutation(
		() => {
			const body = {
				...(name != null ? { name } : {}),
				...(timezone != null ? { defaultTimezone: timezone } : {}),
			};
			return API.post(URL_WEIGHERS, body, {
				params: {
					id: weigher_id,
				},
			});
		},
		{
			onSuccess: () => {
				queryClient.invalidateQueries('weigher');
				set_name(null);
				set_timezone(null);
			},
		}
	);
	const isSaving = post.isLoading || isFetching;
	const saveHandler = post.mutate;

	// prefill the name and timezone changes
	useEffect(() => {
		if (data) {
			if (!data.name) set_name(`Unit ${weigher_id}`);

			if (
				data.Species === undefined && // is new
				data.changes?.defaultTimezone
			)
				set_timezone(data.changes.defaultTimezone);
		}
	}, [weigher_id, data]);

	if (!has_api_token)
		return {
			isLoading: true,
			isError: false,
		};

	if (isLoading || isError) {
		return {
			isLoading,
			isError,
			...(error instanceof Error ? { error: error.toString() } : {}),
		};
	}

	if (!data) {
		return {
			isLoading: false,
			isError: true,
			error: 'Loading data failed',
		};
	}

	const crmLink = data.link;
	const isNew = data.Species === undefined;

	const hasChanges =
		name != null || timezone != null || Object.keys(data.changes).length > 0;
	const canSave =
		hasChanges &&
		(data.changes.client === undefined || data.changes.client.id != null);

	const client: DataItem = {
		label: 'Client',
		current: { text: data.client.name ?? '', link: data.client.link },
	};

	if (data.changes.client) {
		client.update = {
			text: data.changes.client.name ?? '',
			link: data.changes.client.link,
		};
		if (data.changes.client.id == null) {
			client.update.error = data.changes.client.link
				? 'Not found in database, click to add'
				: 'Not assigned to a customer/account';
		}
	}
	const device_id_text = (hardware_id: any) => {
		return hardware_id
			? Object.entries(hardware_id)[0].reverse().join(' (') + ')'
			: '';
	};

	const deviceId: DataItem = {
		label: 'Device ID',
		current: { text: device_id_text(data.hardwareId) },
		...(data.changes.hardwareId
			? { update: { text: device_id_text(data.changes.hardwareId) } }
			: {}),
	};

	if (deviceId.update && data.hardwareChangeTime) {
		const dt = new Date(data.hardwareChangeTime);
		const age = (new Date().getTime() - dt.getTime()) / 1000;
		if (age > 24 * 60 * 60)
			deviceId.update.text += `, ${Math.floor(age / 24 / 60 / 60)} days ago`;
		else if (age > 60 * 60)
			deviceId.update.text += `, ${Math.floor(age / 60 / 60)} hours ago`;
		else if (age > 60)
			deviceId.update.text += `, ${Math.floor(age / 60)} minutes ago`;
		else deviceId.update.text += `, ${Math.floor(age)} seconds ago`;
	}

	const crmValues: DataItem[] = [
		client,
		{
			label: 'Species',
			current: { text: data.Species ?? '' },
			update: data.changes.Species ? { text: data.changes.Species } : undefined,
		},
		deviceId,
	];
	const userValues: UserEditItem[] = [
		{
			label: 'Name',
			current: data.name ?? '',
			update: name,
			setValue: (v: string | null) => {
				set_name(v);
			},
		},
		{
			label: 'Timezone',
			current: data.defaultTimezone ?? '',
			options: timezones.data,
			update: timezone,
			setValue: set_timezone,
		},
	];
	return {
		isLoading: false,
		isError: false,
		crmLink,
		isNew,
		canSave,
		crmValues,
		userValues,
		isSaving,
		saveHandler,
	};
};

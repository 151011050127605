import { useCallback, useState, useEffect, useMemo } from 'react';
import { useMutation, useQuery } from 'react-query';
import { GenericModalProps } from 'components/genericModal';
import {
	actionFetchSessionDetail,
	actionUpdateSession,
	actionInsertSession,
	SessionValues,
	SessionInsertValue,
	SessionUpdateValue,
	actionCloseSession,
} from 'pages/sessionManagement/actions';
import { useNavigate } from 'react-router-dom';
import { actionFetchTimezones } from 'components/timezones/redux';
import { useAppDispatch, useAppSelector } from 'hooks';
import { actionResetSessionIds } from 'components/clientSessionSelect/sessionSelect/redux';
import { add } from 'date-fns';

export type InputValue<Type> = {
	value?: Type;
	options?: Type[];
	set?: (a: Type) => void;
};
export type SelectValue = {
	value?: string;
	options: { [key: string]: string };
	set?: (key: string) => void;
};

export type SessionDetailsModel = {
	isLoading: boolean;
	errorMsg?: string;
	autoCloseSessions?: string[];
	modalMessage?: GenericModalProps;

	onSubmit?: () => void;
	onCancel: () => void;

	name: InputValue<string>;
	timezone: InputValue<string>;
	startTime: InputValue<Date>;
	endTime?: InputValue<Date | null>;
	breed?: InputValue<string>;
	sex?: SelectValue;
	age?: InputValue<string>;
	headCount?: InputValue<string>;
	woolGrowth?: SelectValue;
	paddockSize?: InputValue<string>;
	displayUnits: SelectValue;

	weighers: { id: number; name: string; allowed: boolean; checked: boolean }[];
	setWeigher?: (id: number, selected: boolean) => void;
};

const initialise_species_options = (
	species: string | null,
	breed: InputValue<string>,
	sex: SelectValue,
	age: InputValue<string>
) => {
	if (species === 'cattle') {
		breed.options = [
			'British',
			'Euro',
			'British x Euro',
			'Bos Indicus',
			'British x Bos Indicus',
			'Euro x Bos Indicus',
			'Composite',
			'Dairy',
			'Wagyu/Wagyu X',
			'Mixed',
		];
		sex.options = {
			M: 'Bulls',
			N: 'Steers',
			F: 'Heifers / Cows',
			X: 'Mixed',
		};
		age.options = [
			'Calves',
			'Weaners',
			'Yearlings',
			'Young Adults',
			'Mature Adults',
		];
	} else if (species === 'sheep') {
		sex.options = {
			M: 'Rams',
			N: 'Wethers',
			F: 'Ewes',
			X: 'Mixed',
		};
		age.options = ['0.5', '1', '2', '3+'];
	}
};

export const useSessionDetailsModel = (
	session_id?: number
): SessionDetailsModel => {
	const session_list = useAppSelector((state) => state.sessionIds);
	const client_id = useAppSelector((state) => state.clientId);
	const currentUser = useAppSelector((state) => state.currentUser);
	const weighers = useAppSelector((state) => state.weighers);
	const timezone_list = useAppSelector((state) => state.timezones);
	const [timezones_loaded, set_timezones_loaded] = useState(false);
	const has_api_token = currentUser.username.length > 3;
	const new_session = !session_id;

	const [name_value, setName] = useState('');
	const [timezone_value, setTimezone] = useState<string | null>(null);
	const [start_value, setStart] = useState(new Date());
	const [end_value, setEnd] = useState<Date | null>(null);
	const [weigher_selection, set_weigher_selection] = useState<number[]>([]);
	const [breed_value, setBreed] = useState<string | null>(null);
	const [sex_value, setSex] = useState<string | null>(null);
	const [age_value, setAge] = useState<string | null>(null);
	const [units_value, setUnits] = useState('m');
	const [head_value, setHead] = useState('');
	const [paddock_value, setPaddock] = useState('');
	const [wool_value, setWool] = useState<string | null>(null);

	const [user_cancel, setUserCancel] = useState(false); // has the user clicked cancel
	const [errorMsg, setErrorMsg] = useState<string | null>();

	const dispatch = useAppDispatch();
	const navigate = useNavigate();
	const leave = useCallback(() => {
		navigate('/manage/session');
	}, [navigate]);

	const fetch = useQuery(
		['session', session_id],
		() => actionFetchSessionDetail(session_id!, client_id!),
		{ enabled: has_api_token && !!client_id && !!session_id }
	);
	const post = useMutation({
		mutationFn: async (session: SessionValues) => {
			if (new_session) {
				const close = add(session.sessionStart, { seconds: -1 });
				for (const c of sessions_to_close) {
					await actionCloseSession(c.SessionID, close, session.timezone);
				}
				const s: SessionInsertValue = {
					Client: client_id!,
					weighers: weigher_selection,
					...session,
				};
				await actionInsertSession(s);
			} else {
				const s: SessionUpdateValue = { ...session, SessionID: session_id };
				await actionUpdateSession(s);
			}
		},
		onSuccess: () => {
			dispatch(actionResetSessionIds());
			leave();
		},
		onError: (err) => {
			if (err instanceof Error) setErrorMsg(err.message);
		},
	});

	const displayUnits: SelectValue = {
		value: units_value,
		options: { m: 'Metric (kg)', i: 'Imperial (lb)' },
		set: setUnits,
	};
	const name: InputValue<string> = {
		value: name_value,
		set: setName,
	};
	const timezone: InputValue<string> = {
		value: timezone_value ?? undefined,
		set: setTimezone,
		options: timezone_list,
	};
	const startTime: InputValue<Date> = {
		value: start_value,
	};
	const breed: InputValue<string> = {
		value: breed_value ?? undefined,
		set: setBreed,
	};
	const sex: SelectValue = {
		value: sex_value ?? undefined,
		set: setSex,
		options: {},
	};
	const age: InputValue<string> = {
		value: age_value ?? undefined,
		set: setAge,
	};
	const headCount: InputValue<string> = {
		value: head_value ?? undefined,
		set: setHead,
	};
	const paddockSize: InputValue<string> = {
		value: paddock_value ?? undefined,
		set: setPaddock,
	};

	// fetch timezone list
	useEffect(() => {
		if (timezone_list?.length) set_timezones_loaded(true);
		else if (!timezones_loaded) {
			dispatch(
				actionFetchTimezones((loading: boolean) => {
					set_timezones_loaded(!loading);
				})
			);
		}
	}, [timezones_loaded, timezone_list, dispatch]);
	// load current values for edit
	useEffect(() => {
		if (fetch.data) {
			setName(fetch.data.SessionName);
			setStart(fetch.data.sessionStartObj);
			setEnd(fetch.data.sessionEndObj);
			set_weigher_selection(fetch.data.weighers);
			setTimezone(fetch.data.timezone);
			setHead(fetch.data.Head?.toString() ?? '');
			setBreed(fetch.data.BreedType);
			setSex(fetch.data.Sex);
			setAge(fetch.data.Age);
			setWool(fetch.data.WoolGrowth);
			setPaddock(fetch.data.Paddock?.toString() ?? '');
		}
	}, [fetch.data]);
	// auto select weigher if only one
	useEffect(() => {
		if (weighers?.length === 1) set_weigher_selection([weighers[0].WeigherID]);
	}, [weighers]);
	// update timezone based on weigher selection for new session
	useEffect(() => {
		if (!new_session) return;
		if (weigher_selection.length < 1) return;
		if (!session_list) return;
		const s = session_list
			.filter((s) => s.weighers.includes(weigher_selection[0]))
			.sort((a, b) => b.sessionStart.getTime() - a.sessionStart.getTime());
		if (s.length > 0) {
			setTimezone(s[0].timezone);
			setUnits(s[0].displayUnits);
		}
	}, [new_session, weigher_selection, session_list]);
	// open sessions warning based on weigher selection
	const sessions_to_close = useMemo(() => {
		if (!new_session) return [];
		if (!session_list) return [];
		const open_sessions = session_list.filter((s) => {
			return s.isOpen && s.weighers.some((w) => weigher_selection.includes(w));
		});
		if (open_sessions.length === 0) return [];
		return open_sessions;
	}, [new_session, session_list, weigher_selection]);
	// session species determined by weigher selection
	const species = useMemo(() => {
		if (fetch.data) return fetch.data.species;
		if (!new_session) return null;
		if (weigher_selection.length < 1) return null;
		if (!weighers) return null;
		const w = weighers.filter((w) => w.WeigherID === weigher_selection[0]);
		return w[0]?.Species.toLowerCase();
	}, [fetch.data, new_session, weigher_selection, weighers]);

	const is_yard_weights = useMemo(() => {
		return !new_session && fetch.data?.isYardWeights;
	}, [new_session, fetch.data]);

	const weigherChecked = useCallback(
		(id: number, checked: boolean) => {
			if (!weighers) return null;
			const w = weighers.filter((w) => w.WeigherID === id);
			if (w.length !== 1) return;
			if (species != null && w[0].Species.toLowerCase() !== species) return;
			if (checked) set_weigher_selection([...weigher_selection, id]);
			else set_weigher_selection(weigher_selection.filter((w) => w !== id));
		},
		[weigher_selection, species, weighers]
	);
	const any_changes = useMemo(() => {
		if (new_session)
			return (
				name_value.length > 0 ||
				weigher_selection.length > 0 ||
				breed_value != null ||
				sex_value != null ||
				age_value != null ||
				head_value !== '' ||
				paddock_value !== '' ||
				wool_value != null
			);

		if (fetch.data)
			return (
				name_value !== fetch.data.SessionName ||
				timezone_value !== fetch.data.timezone ||
				start_value !== fetch.data.sessionStartObj ||
				end_value !== fetch.data.sessionEndObj ||
				(fetch.data.Head && Number(head_value) !== fetch.data.Head) ||
				breed_value !== fetch.data.BreedType ||
				sex_value !== fetch.data.Sex ||
				age_value !== fetch.data.Age ||
				wool_value !== fetch.data.WoolGrowth ||
				(fetch.data.Paddock && Number(paddock_value) !== fetch.data.Paddock) ||
				units_value !== fetch.data.displayUnits
			);

		return false;
	}, [
		name_value,
		weigher_selection,
		timezone_value,
		start_value,
		end_value,
		breed_value,
		sex_value,
		age_value,
		head_value,
		paddock_value,
		wool_value,
		units_value,
		fetch.data,
		new_session,
	]);
	const onCancel = useCallback(() => {
		if (any_changes) {
			setUserCancel(true);
		} else {
			leave();
		}
	}, [any_changes, leave]);
	const onSubmit = useCallback(() => {
		const validate = (): string | null => {
			if (name_value == null || name_value.length === 0)
				return `Enter a 'Session name'`;
			if (new_session && weigher_selection.length === 0)
				return `Select one or more weighers`;
			if (timezone_value == null || timezone_value === '')
				return "Select 'timezone'";
			if (breed_value == null || breed_value === '') return "Select 'Breed'";
			if (sex_value == null || sex_value === '') return "Select 'Sex'";
			if (age_value == null || age_value === '') return "Select 'Age'";
			if (!is_yard_weights) {
				if (
					paddock_value == null ||
					paddock_value === '' ||
					isNaN(Number(paddock_value))
				)
					return "Enter 'Paddock size' estimate";
				if (
					head_value == null ||
					head_value === '' ||
					isNaN(Number(head_value))
				)
					return "Enter 'Number of head' estimate";
			}
			return null;
		};
		const err = validate();
		setErrorMsg(err);
		if (err) return;

		const session: SessionValues = {
			SessionName: name_value,
			sessionStart: start_value,
			...((end_value || fetch.data?.sessionEndObj) && {
				sessionEnd: end_value,
			}),
			timezone: timezone_value!,
			Sex: sex_value!,
			Age: age_value!,
			BreedType: breed_value!,
			displayUnits: units_value === 'i' ? 'i' : 'm',
			...(wool_value && { WoolGrowth: wool_value }),
			...(!is_yard_weights && {
				Head: Number(head_value),
				Paddock: Number(paddock_value),
			}),
		};

		post.mutate(session);
	}, [
		name_value,
		weigher_selection,
		timezone_value,
		start_value,
		end_value,
		breed_value,
		sex_value,
		age_value,
		head_value,
		paddock_value,
		wool_value,
		units_value,
		fetch.data,
		new_session,
		is_yard_weights,
		post,
	]);

	const model: SessionDetailsModel = {
		isLoading: fetch.isLoading || post.isLoading || !timezones_loaded,
		...(errorMsg && { errorMsg: errorMsg }),
		...(sessions_to_close.length > 0 && {
			autoCloseSessions: sessions_to_close.map(
				(s) => `Session ${s.SessionID} ${s.SessionName}`
			),
		}),
		onCancel,
		name,
		timezone,
		startTime,
		displayUnits,
		weighers: [],
	};

	if (species != null) {
		initialise_species_options(species, breed, sex, age);
		model.breed = breed;
		model.sex = sex;
		model.age = age;
	}

	if (!is_yard_weights) {
		model.startTime.set = setStart;

		if (end_value !== null || fetch.data?.sessionEndObj) {
			model.endTime = {
				...(end_value && { value: end_value }),
				set: setEnd,
			};
		}

		model.headCount = headCount;
		model.paddockSize = paddockSize;
		if (weighers) {
			model.weighers = weighers.map((w) => {
				return {
					id: w.WeigherID,
					name: w.name,
					allowed: species == null || w.Species.toLowerCase() === species,
					checked: weigher_selection.includes(w.WeigherID),
				};
			});
		}

		if (new_session) model.setWeigher = weigherChecked;
		else model.weighers = model.weighers.filter((w) => w.checked);
	}

	if (species === 'sheep') {
		model.woolGrowth = {
			value: wool_value ?? undefined,
			set: setWool,
			options: Object.fromEntries(
				Array(13)
					.fill(0)
					.map((v: any, i: number) => [i, `${i} month${i === 1 ? '' : 's'}`])
			),
		};
	}

	if (any_changes) {
		model.onSubmit = onSubmit;
	}

	if (user_cancel) {
		model.modalMessage = {
			title: 'Unsaved changes',
			text: 'Your changes to this session have not be saved, are you sure you want to exit?',
			show: true,
			buttons: {
				exit: {
					label: 'Exit without saving changes',
					onClick: leave,
				},
				stay: { label: 'Cancel', onClick: () => setUserCancel(false) },
			},
		};
	}

	return model;
};

import React, { useState, useEffect, Fragment } from 'react';

import { useSelector, useDispatch } from 'react-redux';

import {
	actionFetchSummaryData,
	actionResetSessionSummaryData,
} from './actions.js';
import Loading from 'components/loading.js';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Alert from 'react-bootstrap/Alert';
import SummaryTimePeriodSelect from 'components/summaryTimePeriodSelect.js';
import {
	//toDate,
	//zonedTimeToUtc,
	toZonedTime,
	format,
} from 'date-fns-tz';
//import NavbarCollapse from 'react-bootstrap/NavbarCollapse';
import {
	AiOutlineCheckCircle,
	AiOutlineWarning,
	AiOutlineDashboard,
} from 'react-icons/ai';
import { Link } from 'react-router-dom';
import { kgToLbs } from 'components/utils/convert-units.js';

const FormatMa5 = (props) => {
	//a bit of conditional rendering
	if (props.val == null) return null;

	let decimalPoints = 0;
	if (props.species === 'SHEEP') {
		decimalPoints = 1;
	}

	if (props.pounds) return <>{kgToLbs(props.val).toFixed(decimalPoints)}</>;
	return <>{(props.val * 1).toFixed(decimalPoints)}</>;
};

export const SessionSummary = (props) => {
	// Session summary stored in redux store to save home page load speed
	const session = useSelector((state) => state.session);
	const dispatch = useDispatch();
	const [loading, setLoading] = useState(false);
	const [timePeriod, setTimePeriod] = useState('Past 5 Days');
	const sessionSummary = useSelector((state) => state.sessionSummary);

	useEffect(() => {
		if (session?.SessionID > 0) {
			setLoading(true);
			dispatch(actionResetSessionSummaryData());
			dispatch(actionFetchSummaryData(session.SessionID, setLoading));
		}
	}, [session, dispatch]);
	const lhsCol = '5';

	const handleTimePeriodChange = (evt) => {
		setTimePeriod(evt.target.value);
	};
	const pounds =
		sessionSummary &&
		sessionSummary.summ &&
		sessionSummary.summ.displayUnits === 'i';

	let species = 'CATTLE';
	if (
		sessionSummary &&
		sessionSummary.weighers &&
		sessionSummary.weighers.length > 0
	) {
		species = sessionSummary.weighers[0].Species.toUpperCase();
	}

	if (!session || session.SessionID <= 0) {
		return (
			<Alert
				variant={'secondary'}
				style={{ backgroundColor: 'rgb(233,236,239)' }}
			>
				<p style={{ textAlign: 'center', fontWeight: 'bold' }}>
					Select a Session to get summary data.
				</p>
			</Alert>
		);
	}

	if (loading) {
		return (
			<Alert
				className="sessSummary"
				variant={'secondary'}
				style={{ backgroundColor: 'rgb(233,236,239)' }}
			>
				<Loading loading={loading} />
			</Alert>
		);
	}

	if (sessionSummary.summ === undefined) {
		return (
			<Alert
				className="sessSummary"
				variant={'secondary'}
				style={{ backgroundColor: 'rgb(233,236,239)' }}
			>
				No data available for Session summary!
			</Alert>
		);
	}

	return (
		<Alert
			className="sessSummary container"
			variant={'secondary'}
			style={{ backgroundColor: 'rgb(233,236,239)' }}
		>
			<Row>
				<Col className="label" sm={lhsCol}>
					<h5>System Information</h5>
				</Col>
			</Row>
			<Row>
				<Col sm={'7'}>
					<Row>
						<Col className="label" sm={'3'}>
							Session:
						</Col>
						<Col className="value" sm={'9'}>
							{sessionSummary.summ.SessionName} :<span> </span>
							{sessionSummary.summ.Head} head,<span> </span>
							{sessionSummary.summ.BreedType},<span> </span>
							{sessionSummary.summ.Age}
						</Col>
					</Row>
					{sessionSummary.summ.methane ?
					<Row>
						<Col className="label" sm={'3'}>
							</Col>
							<Col className="value" sm={'9'}>
								<Link to="/report/methane">Session methane data</Link>
							</Col>
						</Row>
						: 
						<></>
					}
					<Row>
						<Col className="label" sm={'3'}>
							Last Record:
						</Col>
						<Col className="value" sm={'9'}>
							{sessionSummary.summ.LatestRecord &&
								format(
									toZonedTime(
										sessionSummary.summ.LatestRecord,
										sessionSummary.summ.timezone
									),
									'do MMM yy, HH:mm ',
									{ timeZone: sessionSummary.summ.timezone }
								) +
									' ' +
									sessionSummary.summ.timezone}
						</Col>
					</Row>
					<Row>
						<Col className="label" sm={'3'}>
							Past 24 hours:
						</Col>
						<Col className="value" sm={'9'}>
							{sessionSummary.data1Day.RecCount} Records,<span> </span>
							{sessionSummary.data1Day.AnimCount} Animals
						</Col>
					</Row>
				</Col>
			</Row>

			<Row>
				<Col className="label" sm={'3'}>
					Weigher
				</Col>
				<Col className="label" sm={'2'}>
					<center>Connection</center>
				</Col>
				<Col className="label" sm={'2'}>
					<center>Battery</center>
				</Col>
				<Col className="label" sm={'2'}>
					<center>Load Bars</center>
				</Col>
						
			</Row>

			{sessionSummary.weighers.map((row, idx) => {
				const stats = row.status;

				const Battery =
					stats.Battery == 0 ? (
						<AiOutlineCheckCircle size={'20'} fill={'#7ED321'} />
					) : stats.Battery == 1 ? (
						<AiOutlineWarning size={'20'} fill={'#F5A623'} />
					) : stats.Battery == 2 ? (
						<AiOutlineWarning size={'20'} fill={'rgb(208,2,27)'} />
					) : (
						<AiOutlineCheckCircle size={'20'} fill={'#7f7f7f'} />
					);

				const LoadBars =
					stats.LoadBars == 0 ? (
						<AiOutlineCheckCircle size={'20'} fill={'#7ED321'} />
					) : stats.LoadBars == 1 ? (
						<AiOutlineWarning size={'20'} fill={'#F5A623'} />
					) : stats.LoadBars == 2 ? (
						<AiOutlineWarning size={'20'} fill={'rgb(208,2,27)'} />
					) : (
						<AiOutlineCheckCircle size={'20'} fill={'#7f7f7f'} />
					);

				const Connection =
					stats.Comms == 0 ? (
						<AiOutlineCheckCircle size={'20'} fill={'#7ED321'} />
					) : stats.Comms == 1 ? (
						<AiOutlineWarning size={'20'} fill={'#F5A623'} />
					) : stats.Comms == 2 ? (
						<AiOutlineWarning size={'20'} fill={'rgb(208,2,27)'} />
					) : (
						<AiOutlineCheckCircle size={'20'} fill={'#7f7f7f'} />
					);

				return (
					<Row key={row.SerialNumber}>
						<Col className="value" sm={'3'}>
							{row.Name} [s/n:
							{row.SerialNumber}]{' '}
							{row.WeigherLink ? (
								<a href={row.WeigherLink} target="_blank" rel="noreferrer">
									<AiOutlineDashboard />
								</a>
							) : null}
						</Col>
						<Col className="value" sm={'2'}>
							<center>{Connection}</center>
						</Col>
						<Col className="value" sm={'2'}>
							<center>{Battery}</center>
						</Col>
						<Col className="value" sm={'2'}>
							<center>{LoadBars}</center>
						</Col>
									{/* <Col className="value" sm={'3'}>
							{row.methane_device_id && (
								<Link to="/report/methane">Download</Link>
							)}
									</Col> */}
					</Row>
				);
			})}

			<hr style={{ height: '0.1rem', background: 'black' }} />

			<Row>
				<Col className="label" sm={6}>
					<h5>Session Information</h5>
				</Col>
				<Col sm={6}>
					<SummaryTimePeriodSelect
						value={timePeriod}
						required={false}
						label={''}
						cols={{ lhs: 4, rhs: 8 }}
						handleChange={(evt) => handleTimePeriodChange(evt)}
						controlStyle={{}}
						lock={loading}
						className="inputRow"
					/>
				</Col>
			</Row>

			<hr />

			<SummaryData
				pounds={pounds}
				species={species}
				head={sessionSummary.summ.Head}
				data={
					timePeriod === 'Past 5 Days'
						? sessionSummary.data5Day
						: timePeriod === 'Past 10 Days'
							? sessionSummary.data10Day
							: timePeriod === 'Session Average'
								? sessionSummary.dataAllDay
								: null
				}
			/>

			<AdgTable summary={sessionSummary} pounds={pounds} species={species} />
		</Alert>
	);
};

const SummaryData = (props) => {
	const { pounds, data, head, species } = props;

	const percentMob =
		data.AnimCount !== 0 && head !== 0
			? ((data.AnimCount / head) * 100).toFixed(0) + '%'
			: 'N/A';

	// throw new Error('debug stop here');
	return (
		<Fragment>
			<Row style={{ textAlign: 'left' }}>
				<Col className="label">Records:</Col>
				<Col className="label">Total Records</Col>
				<Col className="value">{data.RecCount}</Col>
			</Row>

			<Row style={{ textAlign: 'left' }}>
				<Col className="label"></Col>
				<Col className="label">Animals Weighed</Col>
				<Col className="value">{data.AnimCount}</Col>
			</Row>

			<Row style={{ textAlign: 'left' }}>
				<Col className="label"></Col>
				<Col className="label">% of Mob</Col>
				<Col className="value">{percentMob}</Col>
			</Row>

			<Row style={{ textAlign: 'left' }}>
				<Col className="label"></Col>
				<Col className="label"></Col>
				<Col className="label"></Col>
			</Row>

			<br />
			<Row style={{ textAlign: 'left' }}>
				<Col className="label">Weight {pounds ? '[lb]' : '[kg]'}:</Col>
				<Col className="label">Min.</Col>
				<Col className="value">
					<FormatMa5 val={data.Min} pounds={pounds} species={species} />
				</Col>
			</Row>

			<Row style={{ textAlign: 'left' }}>
				<Col className="label"></Col>
				<Col className="label">Avg.</Col>
				<Col className="value">
					<FormatMa5 val={data.Avg} pounds={pounds} species={species} />
				</Col>
			</Row>

			<Row style={{ textAlign: 'left' }}>
				<Col className="label"></Col>
				<Col className="label">Max.</Col>
				<Col className="value">
					<FormatMa5 val={data.Max} pounds={pounds} species={species} />
				</Col>
			</Row>

			<hr style={{ height: '0.1rem', background: 'black' }} />
		</Fragment>
	);
};
const AdgRow = (props) => {
	const { label, pounds, data, species } = props;

	let decimalPoints = 1;
	if (species === 'SHEEP') {
		decimalPoints = 2;
	}

	return (
		<Row>
			<Col className="label" style={{ textAlign: 'left' }}>
				{label}
			</Col>
			{data == null ? null : (
				<>
					<Col className="value" style={{ textAlign: 'right' }}>
						{data.ADG &&
							(pounds
								? kgToLbs(data.ADG).toFixed(decimalPoints)
								: data.ADG.toFixed(decimalPoints))}
					</Col>
					<Col className="value" style={{ textAlign: 'right' }}>
						{data.animalCount || null}
					</Col>
					<Col className="value" style={{ textAlign: 'right' }}>
						{data.days}
					</Col>
				</>
			)}
		</Row>
	);
};
const adg_if_different = (adg, ref) => {
	if (
		adg.ADG === ref.ADG &&
		adg.days === ref.days &&
		adg.animalCount === ref.animalCount
	)
		return null;
	return adg;
};
const AdgTable = (props) => {
	const { pounds, summary, species } = props;
	return (
		<>
			<Row>
				<Col className="label">
					<h5>Individual ADG Calculations</h5>
				</Col>
			</Row>

			<Row>
				<Col>
					<span className="startDate">
						(from this and previous sessions for animals weighed in this
						session)
					</span>
				</Col>
			</Row>
			<Row>
				<Col className="label" style={{ textAlign: 'left' }}></Col>
				<Col className="label" style={{ textAlign: 'right' }}>
					ADG [{pounds ? 'lb/day' : 'kg/day'}]
				</Col>
				<Col className="label" style={{ textAlign: 'right' }}>
					Animals
				</Col>
				<Col className="label" style={{ textAlign: 'right' }}>
					Days
				</Col>
			</Row>
			<hr />
			<AdgRow
				label="Last 14 days:"
				pounds={pounds}
				data={summary.adg1}
				species={species}
			/>
			<AdgRow
				label="Last 30 days:"
				pounds={pounds}
				data={adg_if_different(summary.adg2, summary.adg1)}
				species={species}
			/>
			<AdgRow
				label="Last 90 days:"
				pounds={pounds}
				data={adg_if_different(summary.adg3, summary.adg2)}
				species={species}
			/>
			<AdgRow
				label="Last 180 days:"
				pounds={pounds}
				data={adg_if_different(summary.adg4, summary.adg3)}
				species={species}
			/>
			<AdgRow
				label="All Data:"
				pounds={pounds}
				data={summary.adgAll}
				species={species}
			/>
		</>
	);
};

import Form from 'react-bootstrap/Form';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Loading from 'components/loading.js';

export const GenericDictSelect = (props) => {
	const {
		id,
		handleChange,
		value,
		controlStyle,
		label,
		dataDict,
		required,
		cols,
		pattern,
		invalidMsg,
		selectDotDotDot,
		lock,
		loading,
	} = props;
	const handleInvalid = (evt) => {
		evt.persist();
		evt.target.setCustomValidity(invalidMsg);
	};
	return (
		// <Alert variant={'secondary'} style={{backgroundColor: 'rgb(233,236,239)'}}>
		// <Row style={{width: '18em'}}>
		<Row className="inputRow">
			<Col sm={cols.lhs} style={{ paddingTop: '0.3em' }}>
				{props.label}
				<Required required={required} />:
			</Col>
			<Col sm={cols.rhs}>
				<Form.Control
					id={id}
					as="select"
					onChange={(evt) => handleChange(evt)}
					value={value}
					style={{ appearance: 'listbox', ...controlStyle }}
					pattern={pattern}
					onInvalid={handleInvalid}
					{...(lock && { disabled: true })}
				>
					{selectDotDotDot && (
						<option key={-1} value={''}>
							{'Select ' + label.toLowerCase() + '...'}
						</option>
					)}
					{Object.keys(dataDict).map((key, idx) => {
						return (
							<option key={idx} value={key}>
								{dataDict[key]}
							</option>
						);
					})}
				</Form.Control>
				<Loading loading={loading} />
			</Col>
		</Row>
		// </Alert>
	);
};

const Required = (props) => {
	if (props.required) {
		return (
			<span style={{ color: 'red' }}>
				<sup>*</sup>
			</span>
		);
	}
	return null;
};

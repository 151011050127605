import API from 'services/api.js';
import { URL_GET_SESSION_IDS } from 'services/urls.js';
import {
	actionSetMessage,
	actionResetMessages,
} from 'components/messages/actions.js';

const SET_SESSION = 'SET_SESSION';
const SET_SESSION_IDS = 'SET_SESSION_IDS';
const RESET_SESSION_IDS = 'RESET_SESSION_IDS';

export interface Session {
	SessionID: number;
	Client: number | null;
	SessionName: string;
	sessionStart: Date;
	sessionEnd: Date | null;
	timezone: string;
	Head: number | null;
	displayUnits: string;
	isYardWeights: boolean;
	isOpen: boolean;
	status: string;
	weighers: number[];
}

export function session(
	state: Session | null = null,
	action: { type: string; data?: Session | null }
) {
	switch (action.type) {
		case SET_SESSION:
			return action.data;
		default:
			return state;
	}
}

export function sessionIds(
	state: Session[] = [],
	action: { type: string; data?: Session[] }
) {
	switch (action.type) {
		case SET_SESSION_IDS:
			return action.data;
		case RESET_SESSION_IDS:
			return [];
		default:
			return state;
	}
}

// Sync Action

export const actionSetSession = (data: Session) => {
	return {
		type: SET_SESSION,
		data,
	};
};

export const actionFetchSessionIds = (
	clientId: number,
	includeYardWeights: boolean,
	setSessionLoading?: (loading: boolean) => void
) => {
	return (dispatch: any) => {
		dispatch(actionResetMessages());
		const params = {
			params: {
				clientId,
				...(includeYardWeights ? { includeYardWeights: 1 } : {}),
			},
		};
		return API.get(URL_GET_SESSION_IDS, params)
			.then((response) => {
				return response.data;
			})
			.catch((error) => {
				console.log('error', error);
				dispatch(actionSetMessage("Error fetching Session ID's: " + error));
				return [];
			})
			.then((resp) => {
				for (const s of resp) {
					s.sessionStart = new Date(s.sessionStartUtc);
					if (s.sessionEndUtc) s.sessionEnd = new Date(s.sessionEndUtc);
				}
				dispatch(actionFetchSessionIdsSuccess(resp));
				if (setSessionLoading) setSessionLoading(false);
				return resp;
			});
	};
};

export const actionFetchSessionIdsSuccess = (data: Session[]) => {
	return {
		type: SET_SESSION_IDS,
		data,
	};
};

export const actionResetSessionIds = () => {
	return { type: RESET_SESSION_IDS };
};

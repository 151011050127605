/**
 * Put all the API calls here to keep an
 * overview on what data endpoints are available.
 *
 * Import the required url
 *
 * SORT by alphabetical to fund easily
 */

//const hostname = window && window.location && window.location.hostname;

//###########################
//## Amazon cognito
//## Authentication
//###########################

//const authBaseUrl = 'https://opw-client-portal-dev.auth.ap-southeast-2.amazoncognito.com'
const authBaseUrl = 'https://login.optiweigh.io';
const clientId = '2a1hi2emi581frumm7ppq058dk';

let cognitoAuthUrl;
let baseApiUrl;
let owAuthApiUrl;
let BANNER = '';

switch (process.env.REACT_APP_CODEBASE) {
	case 'prod':
		cognitoAuthUrl = 'https://client.optiweigh.io';
		baseApiUrl = 'https://api.optiweigh.io/client-data-prod/';
		owAuthApiUrl = 'https://api.optiweigh.io/user-auth-prod/token';
		break;
	case 'devel':
		cognitoAuthUrl = 'https://client-dev.optiweigh.io';
		baseApiUrl = 'https://api.optiweigh.io/client-data-dev/';
		owAuthApiUrl = 'https://api.optiweigh.io/user-auth-dev/token';
		BANNER = '[development]';
		break;
	default:
		cognitoAuthUrl = 'https://local.optiweigh.io';
		baseApiUrl =
			process.env.REACT_APP_API_URL ??
			'https://api.optiweigh.io/client-data-dev/';
		owAuthApiUrl = 'https://api.optiweigh.io/user-auth-local/token';
		BANNER = '[local]';
		break;
}

export { baseApiUrl, BANNER };

// login
const urlLogin = new URL('login', authBaseUrl);
urlLogin.searchParams.set('client_id', clientId);
//urlLogin.searchParams.set('response_type', 'token');
urlLogin.searchParams.set('response_type', 'code');
urlLogin.searchParams.set('redirect_uri', `${cognitoAuthUrl}/auth/callback`);
export const URL_AUTH_LOGIN = urlLogin.href;
export const URL_OWAUTH_LOGIN = owAuthApiUrl;

//signout
const urlSignout = new URL('logout', authBaseUrl);
urlSignout.searchParams.set('client_id', clientId);
urlSignout.searchParams.set('logout_uri', `${cognitoAuthUrl}/auth/signout`);
// urlSignout.searchParams.set('response_type', 'code');
// urlSignout.searchParams.set('redirect_uri', `${cognitoAuthUrl}/auth/callback`);
// urlSignout.searchParams.set('state', 'STATE');
// urlSignout.searchParams.set('scope', 'openid+profile+aws.cognito.signin.user.admin');
export const URL_AUTH_LOGOUT = urlSignout.href;
//https://docs.aws.amazon.com/cognito/latest/developerguide/logout-endpoint.html

// user authorisation
export const URL_GET_USER_AUTHORISATION = '/user-authorisation';
export const URL_EMAIL_SUBS = '/email-subscription';

// clients
export const URL_GET_CLIENT_IDS = '/clients';
export const URL_CLIENT_USERS = '/client-users';
export const URL_PASSWORD_RESET = '/password-reset';

//## Sessions
export const URL_GET_SESSION_IDS = '/sessions02';

//export const URL_GET_SESSION_SUMMARY = '/api/sessionSummary/'	//	/api/sessionSummary/<SessionID>
export const URL_GET_SESSION_SUMMARY = '/session-summary08';
export const URL_GET_SESSION_WEIGHERS = '/session-weighers';
export const URL_GET_SESSION_CLOSE = '/session-close';

export const URL_SESSION_DETAIL = '/session-detail'; //	GET - get a complete session record
//	PUT - UPDATE an existing session record
//	POST - INSERT a session record

//## Report specific
export const URL_GET_ANIMALS_BY_DAY = '/daily-aggregate';
export const URL_GET_SESSION_AVG = '/animal-wt-avg';
export const URL_GET_SESSION_DATA = '/animal-wt-data04'; // /api/sessionsAdg
export const URL_GET_WEIGHT_CHART = '/weight-chart03';
export const URL_GET_WEEKLY_WT_AVG = '/weekly-wt-avg'; // superceded after weightDistribution is deployed
export const URL_GET_WEIGHT_DISTRIBUTION = '/weight-distribution04';

export const URL_LONG_TERM_DATA = '/long-term-data03';
export const URL_SESSION_LAST_DAY_OF_WK = '/session-last-day-of-wk';
export const URL_SESSION_MONTH_WITH_DATA = '/session-month-with-data';
export const URL_WEIGHERS = '/weighers3';
export const URL_GET_TIMEZONES = '/timezones';
export const URL_GET_METHANE_LIST = '/methane/list'; // sessionId
export const URL_GET_METHANE_DATA = '/methane/data'; // sessionId,date
export const URL_GET_METHANE_REPORT = '/methane/summary'; // sessionId,date

//################# STAFF PAGES ##############
export const URL_GET_DBSYS_LOG = '/dbsys-log'; //superuser access only
export const URL_CLIENT_PORTAL_SETTING = '/client-portal-settings'; //superuser access only
export const URL_CLIENT_EMAIL = '/client-email';

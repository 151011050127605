import './App.css';
import { Home } from 'pages/home.js';
import { BrowserRouter, Routes, Route } from 'react-router-dom';

import { PrivacyPolicy } from 'pages/privacyPolicy';
import { WeightChart } from 'pages/weightChart/weightChart.js';
import { WeightDistribution } from 'pages/weightDistribution/weightDistribution.js';
import { SessionData2 } from 'pages/sessionData2/sessionData2.js';
import { Methane } from 'pages/methane/methane.js';
import { AccountManagement } from 'pages/accountManagement/accountManagement';
import { NewAccount } from 'pages/accountManagement/newAccount';
import { SessionManagement } from 'pages/sessionManagement/sessionManagement';
import { LongTermData } from 'pages/longTermData/longTermData.js';
import { Callback } from 'pages/auth/callback.js';
import { Signout } from 'pages/auth/signout.js';
import { Staff } from 'pages/staff/staff.js';
import { DbsysLog } from 'pagesStaff/dbsysLog/dbsysLog.js';
import { DailyEmail } from 'pagesStaff/dailyEmail/dailyEmail.js';
import { YardWeights } from 'pagesStaff/yardWeights.js';
import { EmailSubscriptions } from 'pages/emailSubscriptions/emailSubscriptions';
import { WeigherAdmin } from 'pages/weigher/weigher';
import { SessionDetail } from 'pages/sessionManagement/sessionDetails';

/**
 * IMPORTANT - need to do this to get react router working on the Cloudfront server
 *
 * SPAs which redirect to URL paths but still want to be handled by the root path
 *
 * refer: https://johnlouros.com/blog/using-CloudFront-to-serve-an-SPA-from-S3
 *
 * in cloudfront distribution
 * create custom error responses:
 *
 * http code: 403
 * ttl: 300
 * custom error response: YES
 * response p[ath: index.html
 * reponse code 200 OK
 *
 * http code: 404
 * ttl: 300
 * custom error response: YES
 * response path: index.html
 * reponse code 200 OK
 *

 */
function App() {
	return (
		<BrowserRouter>
			<Routes>
				<Route path="/auth/callback" element={<Callback />} />
				<Route path="/auth/signout" element={<Signout />} />
				<Route path="/report/weightChart" element={<WeightChart />} />
				<Route
					path="/report/weightDistribution"
					element={<WeightDistribution />}
				/>
				<Route path="/report/sessionData2" element={<SessionData2 />} />
				<Route path="/report/longTermData" element={<LongTermData />} />
				<Route path="/report/methane" element={<Methane />} />
				<Route path="/manage/accounts/new" element={<NewAccount />} />
				<Route path="/manage/accounts" element={<AccountManagement />} />
				<Route path="/manage/session" element={<SessionManagement />} />
				<Route path="/manage/session/edit" element={<SessionDetail />} />
				<Route path="/manage/emails" element={<EmailSubscriptions />} />
				<Route path="/manage/weigher" element={<WeigherAdmin />} />

				<Route path="/staff/dbsysLog" element={<DbsysLog />} />
				<Route path="/staff/dailyEmail" element={<DailyEmail />} />
				<Route path="/staff/yardWeights" element={<YardWeights />} />
				<Route path="/staff" element={<Staff />} />

				<Route path="/privacy" element={<PrivacyPolicy />} />
				<Route path="/" element={<Home />} />
			</Routes>
		</BrowserRouter>
	);
}
// http://localhost:3000/privacy
export default App;

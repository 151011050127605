import { AppDispatch } from 'index';
import API from 'services/api.js';
import { URL_WEIGHERS } from 'services/urls.js';

const SET_WEIGHERS = 'SET_WEIGHERS';
const SET_WEIGHERS_LOADED = 'SET_WEIGHERS_LOADED';

export type WeigherInfo = {
	WeigherID: number;
	name: string;
	defaultTimezone: string | null;
	Species: string;
	client: { id: number };
};

export function weighers(
	state: WeigherInfo[] = [],
	action: { type?: string; data?: WeigherInfo[] }
) {
	switch (action.type) {
		case SET_WEIGHERS:
			return action.data;
		default:
			return state;
	}
}

export function weighersLoaded(
	state = false,
	action: { type?: string; data?: boolean }
) {
	switch (action.type) {
		case SET_WEIGHERS_LOADED:
			return action.data;
		default:
			return state;
	}
}

export const actionFetchWeighersSuccess = (data: WeigherInfo[]) => {
	return {
		type: SET_WEIGHERS,
		data,
	};
};

export const actionSetWeighersLoaded = (data: boolean) => {
	// data is true or false
	return {
		type: SET_WEIGHERS_LOADED,
		data,
	};
};

export const actionFetchWeigherIds = (
	clientId: number,
	setWloading: (l: boolean) => void,
	showMessage: (msg: string) => void
) => {
	return (dispatch: AppDispatch) => {
		const params = {
			params: { clientId: clientId },
			headers: {
				//'x-opw-format': 'list',
			},
		};
		//	/api/weigherIds/<ClientID>
		return API.get(URL_WEIGHERS, params)
			.then((response) => {
				return response.data;
			})
			.catch((error) => {
				console.log('error', error);
				showMessage('Err fetching weigher ids: ' + error);
				return [];
			})
			.then((resp) => {
				setWloading(false);
				dispatch(actionSetWeighersLoaded(true));
				dispatch(actionFetchWeighersSuccess(resp));
			});
	};
};

export const actionUpdateWeighers = (
	weighersObj: WeigherInfo,
	clientId: number,
	setWloading: (l: boolean) => void,
	showMessage: (msg: string) => void
) => {
	/**
	 * PUT = UPDATE
	 */
	return (dispatch: AppDispatch) => {
		const config = {
			headers: {
				// Overwrite Axios's automatically set Content-Type
				'Content-Type': 'application/json',
			},
		};
		const jsonPackage = JSON.stringify(weighersObj);

		return API.put(URL_WEIGHERS, jsonPackage, config)
			.then((response) => {
				console.log('update response', response);
				dispatch(actionFetchWeigherIds(clientId, setWloading, showMessage));
			})
			.catch((error) => {
				console.log('ERR actionUpdateWeighers: ', error);
			});
	};
};

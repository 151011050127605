import { SET_SESSION_SUMMARY, RESET_SESSION_SUMMARY } from './actions';

export function sessionSummary(state = {}, action) {
	switch (action.type) {
		case SET_SESSION_SUMMARY:
			return action.data;
		case RESET_SESSION_SUMMARY:
			return {};
		default:
			return state;
	}
}

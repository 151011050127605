import Form from 'react-bootstrap/Form';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Loading from 'components/loading.js';
import { parseSessionEndDate } from 'components/utils/dateLib.js';
import { isValid, parseISO } from 'date-fns';
import { formatInTimeZone } from 'components/utils/dateLib.js';

const getSelectedCss = (selectedSessionId, val) => {
	const common = {
		listStyleType: 'none',
		padding: '2px 0 2px 0',
		fontWeight: 'bold',
	};
	if (selectedSessionId === val) {
		console.log('selected');
		return { ...{ color: 'blue', border: '2px solid black' }, ...common };
	} else {
		return common;
	}
};

const session_name = (row) => {
	if (row.isYardWeights)
		return `${row.SessionName}: ${row.SessionStartF} Yard weights [id:${row.SessionID}]`;

	return `${row.SessionName}: ${row.SessionStartF} ${row.SessionEndF} [id:${row.SessionID},s/n:${row.weighers}]`;
};

export const SessionSelect = (props) => {
	/**
	 * layout sets the return layout
	 * layout = list - show sessions as a vertical list
	 *
	 * layout = select or
	 * layout = <none> - show as dropdown select control
	 */
	const {
		handleChange,
		sessionLoading,
		session,
		sessionIds,
		clientId,
		layout,
		lock,
	} = props;
	const format = layout === undefined ? 'select' : layout;

	if (clientId === '') {
		return null;
	} else if (sessionIds === undefined) {
		return null;
	} else if (sessionIds.length < 1) {
		return (
			<Template>
				<p style={{ paddingTop: '0.3em' }}>No sessions available for Client!</p>
			</Template>
		);
	} else {
		const data = sessionIds.map((row) => {
			//console.log ('row:', row);
			const obj = { isYardWeights: row.isYardWeights ?? false };
			const dFormatStr = 'd-LLL-yy';
			//const dFormatStr = 'yyyy-MM-dd HH:mm:ss';

			const sessionEnd = parseSessionEndDate(row.SessionEnd);

			if (sessionEnd === null) {
				obj.SessionEndF = ' +';
			} else {
				if (isValid(parseISO(row.sessionEndUtc))) {
					obj.SessionEndF =
						' -> ' +
						formatInTimeZone(row.sessionEndUtc, dFormatStr, row.timezone);
				} else {
					obj.SessionEndF = ' +';
				}
			}
			obj.SessionID = row.SessionID;
			obj.SessionName = row.SessionName;
			//obj.Weigher = row.Weigher;
			if (isValid(parseISO(row.sessionStartUtc))) {
				obj.SessionStartF = formatInTimeZone(
					row.sessionStartUtc,
					dFormatStr,
					row.timezone
				);
			} else {
				obj.SessionStartF = 'ERR';
			}
			if (row.weighers && row.weighers.length > 0) {
				let count = 0;
				obj.weighers = '';
				for (const w of row.weighers) {
					if (count > 0) {
						obj.weighers = obj.weighers + ',';
					}
					count = count + 1;
					if (w) {
					  obj.weighers = obj.weighers + w.toString();
					}
				}
			}
			return obj;
		});

		if (format === 'select') {
			return (
				<Template>
					{sessionLoading ? (
						<Loading loading={sessionLoading} />
					) : (
						<Form.Control
							as="select"
							onChange={(evt) => handleChange(evt)}
							value={session.SessionID}
							style={{ ...props.controlStyle, appearance: 'listbox' }}
							{...(lock && { disabled: true })}
						>
							<option key={-1} value={''}>
								{'select session ...'}
							</option>
							{data.map((row, idx) => {
								return (
									<option key={idx} value={row.SessionID}>
										{session_name(row)}
									</option>
								);
							})}
						</Form.Control>
					)}
				</Template>
			);
		} else if (format === 'list') {
			return (
				<Template>
					{sessionLoading ? (
						<Loading loading={sessionLoading} />
					) : (
						<SessionList {...props} />
					)}
				</Template>
			);
		}
	}
};

const SessionList = (props) => {
	const {
		handleChange,
		sessionIds,
		session,
		//lock,
	} = props;
	const changeBackground = (e) => {
		e.target.style.background = 'rgb(250,255,18, 0.2)';
	};
	const backgroundOff = (e) => {
		e.target.style.background = '';
	};
	return (
		<>
			<p style={{ color: 'blue', paddingTop: '0.3em' }}>
				Click a session to edit!
			</p>
			<ul>
				{sessionIds.map((row, idx) => {
					return (
						<li
							id={idx}
							key={idx}
							value={row.SessionID}
							onClick={handleChange}
							onMouseOver={changeBackground}
							onMouseOut={backgroundOff}
							style={getSelectedCss(session.SessionID, row.SessionID)}
						>
							{row.SessionName} [id:{row.SessionID}]
						</li>
					);
				})}
			</ul>
		</>
	);
};

const Template = (props) => {
	return (
		<Row className="inputRow">
			{/* <Row style={{width: '18em'}}></Row> */}

			{/*<Col sm='3'><p style={{paddingTop: '0.3em'}}>Sessions:</p></Col>*/}
			<Col sm="3">
				<label className="dropdownLabel">Session:</label>
			</Col>

			<Col sm="9">{props.children}</Col>
		</Row>
	);
};

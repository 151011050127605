import API from 'services/api.js';
import { URL_GET_WEIGHT_DISTRIBUTION } from 'services/urls.js';

function getDays(timePeriod) {
	if (timePeriod === 'Past 10 Days') {
		return 10;
	} else if (timePeriod === 'Session Average') {
		return 10000;
	} else {
		return 5;
	}
}

export const actionFetchWeightDistributionData = (
	sessionId,
	timePeriod,
	setLoading,
	showMessage
) => {
	let resp = [];
	const params = {
		params: {
			sessionId: sessionId,
			noDays: getDays(timePeriod),
		},
		headers: {
			'x-opw-format': 'c3',
		},
	};
	console.log('params', params);
	return API.get(URL_GET_WEIGHT_DISTRIBUTION, params)
		.then((response) => {
			console.log('actionFetchWeightDistributionData() response', response);
			//const countTotals = getCountTotals(response.data);
			//const parsedData =  parseAxisData(response.data, total);
			//resp = {countTotals: countTotals, data: parsedData}
			resp = response.data;
		})
		.catch((error) => {
			showMessage('Err fetching distribution by week data: ' + error);
			resp = null;
		})
		.then(() => {
			setLoading(false);
			return resp;
		});
};

export const getDownloadFile = async (sessionId, timePeriod) => {
	const params = {
		params: {
			sessionId: sessionId,
			noDays: getDays(timePeriod),
		},
		responseType: 'blob',
		timeout: 30000,
		headers: {
			'x-opw-format': 'csv',
		},
	};
	return API.get(URL_GET_WEIGHT_DISTRIBUTION, params)
		.then((response) => {
			return response;
		})
		.catch((error) => {
			//showMessage('Err fetching session average data: ' + error)
		});
};

import { useState, useEffect, useMemo, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
	actionSetMessage,
	actionResetMessages,
} from 'components/messages/actions.js';
import { NoColumnFilter } from 'components/utils/tableFilters.js';
import { CheckAuth } from 'pages/auth/CheckAuth';
import Header from 'components/header.js';
import Footer from 'components/footer.js';
import Container from 'react-bootstrap/Container';
import Card from 'react-bootstrap/Card';
import OPWBreadcrumb from 'components/breadcrumb.js';
import Loading from 'components/loading.js';
import RptBtns from 'components/rptBtns.js';

import { ClientSessionSelect } from 'components/clientSessionSelect/clientSessionSelect.js';
import { actionFetchSessionAvg, getDownloadFile } from './actions.js';
import { TableControl, TableStyles } from 'components/tableControl.js';
import { MovingAvgTimeSelect } from 'components/movingAvgTimeSelect/movingAvgTimeSelect.js';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import { saveAs } from 'file-saver';
import styled from 'styled-components';
import { kgToLbs } from 'components/utils/convert-units.js';

const TITLE = 'Session data';
const showRecCount = 50; // default records to display
const BC = OPWBreadcrumb;

//:TODO: add totals on footer https://react-table.tanstack.com/docs/examples/footers

// this is specifically for this table and overwrites TableStyles.
const Session2TableStyles = styled.div`
	th#visId input {
		max-width: 4rem;
	}
	th#eid input {
		max-width: 8rem;
	}
	tr {
		td {
			padding-left: 0.2rem;
			padding-right: 0.2rem;
		}
	}
`;

const ParamPanel = (props) => {
	const { handleChange, timebase, loading, handleDownload, downloading } =
		props;
	const cols = { lhs: 4, rhs: 8 };
	const controlStyle = { width: '15em' };

	return (
		<Card className={'no-print'} body>
			<Card.Title>{TITLE}</Card.Title>
			<Card.Body>
				<Row>
					<Col sm={4}>
						<ClientSessionSelect
							layout={'select'}
							hideSessionSelect={false}
							handleBeforeSessionChange={() => {}}
							includeYardWeights={false}
							lock={false}
						/>
					</Col>
					<Col sm={4}>
						<MovingAvgTimeSelect
							cols={cols}
							size={'sm'}
							controlStyle={controlStyle}
							required={true}
							handleChange={(evt) => handleChange(evt)}
							value={timebase || ''}
						/>
						<div className={'w-50 mx-auto'}>
							<Loading loading={loading} />
						</div>
					</Col>
					<Col sm={4}>
						<div className={'text-right report-buttons'}>
							<RptBtns
								enablePrint={true}
								enableHelp={true}
								enableDownload={true}
								handleDownload={() => handleDownload()}
								downloading={downloading}
							/>
						</div>
						<p className="dt-leftMargin note">
							Click column head ▽ to sort data.
						</p>
					</Col>
				</Row>
			</Card.Body>
		</Card>
	);
};

const NoData = () => <h1>No data</h1>;

// pagination
//https://medium.com/better-programming/an-introduction-to-react-table-6ebd34d8059e
export const SessionData2 = (props) => {
	const [data, setData] = useState([]); //	local data for graph or report
	const [loading, setLoading] = useState(false);
	const [downloading, setDownloading] = useState(false);
	const [timebase, setTimebase] = useState('MA_30');
	const session = useSelector((state) => state.session);
	const dispatch = useDispatch();

	const pounds = session && session.displayUnits === 'i';
	const is_sheep =
		session && session.Species && session.Species.toUpperCase() === 'SHEEP';
	const wt_decimals = is_sheep ? 1 : 0;
	const adg_decimals = is_sheep ? 2 : 1;

	const handleTimebaseChange = (evt) => {
		setTimebase(evt.target.value);
	};

	const handleDownload = async () => {
		setDownloading(true); 

		let csv = 'eid,avWt,visId,n,Days,ADG,LastWtDate,fiveAvWt,session,client\r\n';

		if (data) {
			data.map((row) => {
				csv = csv + row.eid 
					+ ',' + row.avWt
					+ ',' + row.visId
					+ ',' + row.n
					+ ',' + row.Days
					+ ',' + row.ADG
					+ ',' + row.LastWtDate
					+ ',' + row.fiveAvWt
					+ ',' + row.session
					+ ',' + row.client
					+ '\r\n'
				;
			});
		}

		//console.log ('CSV :' + csv)
		const blob = new Blob([csv], {
			type: "text/plain;charset=utf-8"
		  });
		await saveAs(blob, session.SessionName + '.csv');
		setDownloading(false);
	};

	useEffect(() => {
		document.title = TITLE;
	}, []);

	const showMessage = useCallback(
		(data) => dispatch(actionSetMessage(data)),
		[dispatch]
	);

	useEffect(() => {
		async function fetchData() {
			dispatch(actionResetMessages());
			let resp = await actionFetchSessionAvg(
				session.SessionID,
				timebase,
				showMessage,
				setLoading
			);

			if (resp) {
				if (pounds) {
					resp = resp.map((row) => {
						row.avWt = Number(row.avWt)
							? +kgToLbs(row.avWt).toFixed(wt_decimals)
							: row.avWt;
						row.fiveAvWt = Number(row.fiveAvWt)
							? +kgToLbs(row.fiveAvWt).toFixed(wt_decimals)
							: row.fiveAvWt;
						row.ADG = Number(row.ADG) ? +kgToLbs(row.ADG) : row.ADG;
						return row;
					});
				} else {
					resp = resp.map((row) => {
						row.avWt = Number(row.avWt)
							? +row.avWt.toFixed(wt_decimals)
							: row.avWt;
						row.fiveAvWt = Number(row.fiveAvWt)
							? +row.fiveAvWt.toFixed(wt_decimals)
							: row.fiveAvWt;
						row.ADG = Number(row.ADG) ? +row.ADG : row.ADG;
						return row;
					});
				}
			}
			setData(resp);
		}
		if (session.SessionID > 0) {
			setLoading(true);
			fetchData();
		}
	}, [
		session,
		timebase,
		pounds,
		wt_decimals,
		adg_decimals,
		showMessage,
		dispatch,
	]);

	return (
		<Template
			loading={loading}
			handleTimebaseChange={handleTimebaseChange}
			timebase={timebase}
			handleDownload={handleDownload}
			downloading={downloading}
		>
			<h2 className={'only-print'}>Optiweigh - Session data</h2>
			<SessionDataTable
				data={data}
				pounds={pounds}
				wt_decimals={wt_decimals}
				adg_decimals={adg_decimals}
			/>
		</Template>
	);
};

const Template = (props) => (
	<div>
		<Header>
			<BC trail='[["Home", "/"], ["reports", "/"], ["session data", ""]]' />
		</Header>
		<Container fluid className="body">
			<CheckAuth groupName={'user'} />
			<ParamPanel
				handleChange={props.handleTimebaseChange}
				timebase={props.timebase}
				loading={props.loading}
				handleDownload={props.handleDownload}
				downloading={props.downloading}
			/>
			{props.children}
		</Container>
		<Container fluid>
			<Row>
				<Col sm="12">
					<Footer />
				</Col>
			</Row>
		</Container>
	</div>
);

function SessionDataTable(props) {
	const { data, pounds, wt_decimals, adg_decimals } = props;

	const adgAvgObj = useMemo(
		() =>
			data.reduce(
				(dataObj, row) => {
					//console.log('row:' + JSON.stringify(row))
					if (isNaN(row.ADG)) {
						//only include numeric in calcs
						return dataObj;
					} else {
						return {
							sum: Number(row.ADG) + dataObj.sum,
							count: dataObj.count + 1,
						};
					}
				},
				{ sum: 0, count: 0 }
			),
		[data]
	);

	const daysAvgObj = useMemo(
		() =>
			data.reduce(
				(dataObj, row) => {
					//console.log('row:' + JSON.stringify(row))
					if (isNaN(row.Days) || isNaN(row.ADG)) {
						//only include if there is both a number of days and an adg for that animal
						return dataObj;
					} else {
						return {
							sum: parseInt(row.Days) + dataObj.sum,
							count: dataObj.count + 1,
						};
					}
				},
				{ sum: 0, count: 0 }
			),
		[data]
	);

	const columns = useMemo(
		() => [
			{
				Header: 'Animal',
				Footer: '',
				columns: [
					{
						Header: 'RFID',
						Footer: 'Average',
						accessor: 'eid',
						sortType: 'basic',
						style: { textAlign: 'center' },
					},
					{
						Header: 'Visual ID',
						Footer: '',
						accessor: 'visId',
						sortType: 'basic',
						style: { textAlign: 'center' },
					},
				],
			},
			{
				Header: 'Session data',
				Footer: '',
				columns: [
					{
						Header: `Average Weight [${pounds ? 'lb' : 'kg'}]`,
						Footer: (info) => {
							const avgObj = useMemo(
								() =>
									info.rows.reduce(
										(dataObj, row) => {
											if (isNaN(row.values.avWt)) {
												//only include numeric in calcs
												return dataObj;
											} else {
												return {
													sum: row.values.avWt + dataObj.sum,
													count: dataObj.count + 1,
												};
											}
										},
										{ sum: 0, count: 0 }
									),
								[info.rows]
							);
							const avg =
								avgObj.count > 0
									? parseFloat(avgObj.sum / avgObj.count)
											.toFixed(wt_decimals)
											.toLocaleString('en')
									: 'n/a';
							return <>{avg}</>;
						},
						accessor: 'avWt',
						Filter: NoColumnFilter,
						style: { textAlign: 'right' },
					},
					{
						Header: `Current Weight [${pounds ? 'lb' : 'kg'}]`,
						Footer: (info) => {
							const avgObj = useMemo(
								() =>
									info.rows.reduce(
										(dataObj, row) => {
											if (isNaN(row.values.fiveAvWt)) {
												//only include numeric in calcs
												return dataObj;
											} else {
												return {
													sum: Number(row.values.fiveAvWt) + dataObj.sum,
													count: dataObj.count + 1,
												};
											}
										},
										{ sum: 0, count: 0 }
									),
								[info.rows]
							);
							const avg =
								avgObj.count > 0
									? parseFloat(avgObj.sum / avgObj.count)
											.toFixed(wt_decimals)
											.toLocaleString('en')
									: 'n/a';
							return <>{avg}</>;
						},
						accessor: 'fiveAvWt',
						Filter: NoColumnFilter,
						style: { textAlign: 'right' },
					},
					{
						Header: 'Count of Weights',
						Footer: (info) => {
							const avgObj = useMemo(
								() =>
									info.rows.reduce(
										(dataObj, row) => {
											const n = Number(row.values.n);
											if (row.values.n && !isNaN(n)) {
												//only include numeric in calcs
												dataObj.sum += n;
												dataObj.count++;
											}
											return dataObj;
										},
										{ sum: 0, count: 0 }
									),
								[info.rows]
							);
							const avg =
								avgObj.count > 0
									? parseFloat(
											Math.round(avgObj.sum / avgObj.count)
										).toLocaleString('en')
									: 'n/a';
							return <>{avg}</>;
						},
						//https://github.com/tannerlinsley/react-table/blob/master/examples/footers/src/App.js
						//https://react-table.tanstack.com/docs/examples/footers
						accessor: 'n',
						sortType: 'basic',
						style: { textAlign: 'right' },
						Filter: NoColumnFilter,
					},
					{
						Header: 'Last Wt Date',
						accessor: 'LastWtDate',
						sortType: 'basic',
						style: { textAlign: 'right' },
						Filter: NoColumnFilter,
					},
				],
			},
			{
				Header: 'Average Daily Gain',
				Footer: '',
				columns: [
					{
						Header: `ADG [${pounds ? 'lb' : 'kg'}/day]`,
						Footer: (info) => {
							const avgWorking = (adgAvgObj.sum / adgAvgObj.count).toFixed(
								adg_decimals
							);
							const avg =
								adgAvgObj.count > 0
									? Number(avgWorking).toLocaleString('en')
									: 'n/a';
							return <>{avg}</>;
						},
						accessorKey: 'ADG',
						accessor: (obj) => {
							if (!isNaN(obj.ADG)) {
								return obj.ADG.toFixed(adg_decimals);
							} else {
								return obj.ADG;
							}
						},
						Filter: NoColumnFilter,
						style: { textAlign: 'right' },
					},
					{
						Header: 'Gain duration [days]',
						accessor: 'Days',
						Filter: NoColumnFilter,
						style: { textAlign: 'right' },
						Footer: () => {
							console.log('daysAvgObj:' + JSON.stringify(daysAvgObj));
							const avg =
								daysAvgObj.count > 0
									? parseFloat(
											Math.round(daysAvgObj.sum / daysAvgObj.count)
										).toLocaleString('en')
									: 'n/a';
							return <>{avg}</>;
						},
					},
				],
			},
		],
		[pounds, adgAvgObj, daysAvgObj, wt_decimals, adg_decimals]
	);
	return (
		<TableStyles className="sessionDataTable">
			<Session2TableStyles>
				<TableControl
					columns={columns}
					data={data}
					showRecCount={showRecCount}
					NoDataComponent={NoData}
				/>
			</Session2TableStyles>
		</TableStyles>
	);
}

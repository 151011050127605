import {
	SET_NEXT_PATH,
	RESET_NEXT_PATH,
	SET_PREV_PATH,
	RESET_PREV_PATH,
} from './actions.js';

export function next_path(state = '', action) {
	switch (action.type) {
		case SET_NEXT_PATH:
			return action.payload;
		case RESET_NEXT_PATH:
			return {};
		default:
			return state;
	}
}

export function prev_path(state = '', action) {
	switch (action.type) {
		case SET_PREV_PATH:
			return action.payload;
		case RESET_PREV_PATH:
			return {};
		default:
			return state;
	}
}

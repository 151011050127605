/**
 * optiweigh date helper library
 * These are utilities used in calculating and displaying dates in the optiweigh system
 *
 * Time zones https://techsupport.osisoft.com/Documentation/PI-Web-API/help/topics/timezones/iana.html
 		* Australia/Brisbane
		* Australia/Sydney
		* Australia/Melbourne
		* Australia/Canberra
		* Australia/Adelaide
		* Australia/Darwin
		* Australia/Hobart
		* Australia/Perth
		* Canada/Pacific
		* Pacific/Auckland
		* America/Montevideo
 *


 * cjc 10/2020
 */

const {
	format,
	toZonedTime,
	//zonedTimeToUtc
} = require('date-fns-tz');

const add = require('date-fns/add');
const eachDayOfInterval = require('date-fns/eachDayOfInterval');
const eachWeekOfInterval = require('date-fns/eachWeekOfInterval');
//const eachMonthOfInterval = require('date-fns/eachMonthOfInterval')

/**
 * This is a key date format function for working with time zones
 * @param {*} date
 * @param {*} fmt
 * @param {*} tz
 */
const formatInTimeZone = (date, fmt, tz) =>
	format(toZonedTime(date, tz), fmt, { timeZone: tz });
exports.formatInTimeZone = formatInTimeZone;

/**
 * format  = '' returns date object
 * "yyyy-MM-dd" returns formatted string
 */
const getLastDayOfMth = (dateStr, formatStr = null) => {
	const pDate = new Date(dateStr);
	const eomDate = new Date(pDate.getFullYear(), pDate.getMonth() + 1, 0);
	if (formatStr != null) {
		return formatInTimeZone(eomDate, formatStr, 'UTC');
	}
	return eomDate;
};
exports.getLastDayOfMth = getLastDayOfMth;

const getFirstDayOfMth = (dateIn, formatStr = null) => {
	let dateObj = null;
	if (typeof dateIn === 'string') {
		dateObj = new Date(dateIn); // get current date
	} else {
		dateObj = dateIn;
	}
	const somDate = new Date(dateObj.getFullYear(), dateObj.getMonth(), 1);
	if (formatStr != null) {
		return formatInTimeZone(somDate, formatStr, 'UTC');
	}
	return somDate;
};
exports.getFirstDayOfMth = getFirstDayOfMth;

/**
 * last day of week is sunday = 0
 */
const getLastDayOfWk = (dateIn, formatStr = null) => {
	let dateObj = null;
	if (typeof dateIn === 'string') {
		dateObj = new Date(dateIn); // get current date
	} else {
		dateObj = dateIn;
	}
	let first = null;
	if (dateObj.getDay() !== 0) {
		// not sunday
		first = dateObj.getDate() - dateObj.getDay() + 1;
		const last = first + 6; // last day is the first day + 6
		//const lastDayObj = new Date(dateObj.setDate(last))
		dateObj.setDate(last);
	}
	if (formatStr != null) {
		return formatInTimeZone(dateObj, formatStr, 'UTC');
	}
	return dateObj;
};
exports.getLastDayOfWk = getLastDayOfWk;

/**
 * return an array of end of week (sun) dates that
 * fit within the dates.
 * response date objects
 */
const getEndOfWkSeries = (startObj, endObj, formatStr = null) => {
	const interval = {
		start: startObj,
		end: endObj,
		//start: new Date('2020-10-01'),
		//end: new Date('2020-10-17')
	};
	const result = eachWeekOfInterval(
		interval,
		{ weekStartsOn: 1 } //first day of week monday, week end sunday
	); //raw date object array at start of week
	const eowArray = result.map(
		// remap to our end of week
		(row) => {
			return add(row, { days: 7 });
		}
	);
	return eowArray;
};
exports.getEndOfWkSeries = getEndOfWkSeries;

/**
 * return an array of days in a week ending sunday
 * fit within the dates.
 * response date objects
 */
const getDaysInWeekEndingSeries = (startObj, endObj, formatStr = null) => {
	const interval = {
		start: startObj,
		end: endObj,
		//start: new Date('2020-10-01'),
		//end: new Date('2020-10-17')
	};
	const result = eachDayOfInterval(
		interval,
		{ weekStartsOn: 1 } //first day of week monday, week end sunday
	); //raw date object array at start of week
	const daysArray = result.map(
		// remap to our end of week
		(row) => {
			return add(row, { days: 1 });
		}
	);
	return daysArray;
};
exports.getDaysInWeekEndingSeries = getDaysInWeekEndingSeries;

const formatDateObjArray = (eowArray, formatStr = 'yyyy-MM-dd') => {
	return eowArray.map((row) => {
		return formatInTimeZone(row, formatStr, 'UTC');
	});
};
exports.formatDateObjArray = formatDateObjArray;

/**
 * Given a series of week ending dates
 * return the overall start and end dates as array [start. end]
 * start of first week to end of last week
 * @param {*} dateObjSeries
 * @param {*} formatStr
 *
 */
const getStartEndDateFromEowSeries = (dateObjSeries, formatStr) => {
	const startOfFirstWeek = add(dateObjSeries[0], { days: -6 });
	const endOfLastWeek = dateObjSeries[dateObjSeries.length - 1];
	if (formatStr != null) {
		return [
			formatInTimeZone(startOfFirstWeek, formatStr, 'UTC'),
			formatInTimeZone(endOfLastWeek, formatStr, 'UTC'),
		];
	}
	return [startOfFirstWeek, endOfLastWeek];
};
exports.getStartEndDateFromEowSeries = getStartEndDateFromEowSeries;

/**
 * Assemble all dates required for a month view of end of week dates
 * provide a yyyy-mm-dd string date
 * @param {*} dateStr
 * return
 * {firstDateStr: '', lastDateStr: "", [dateSeries of week end dates,'','']
 */
const getMonthViewDates = (dateInMthStr) => {
	//const dateObj = new Date(dateInMthStr)
	const mthStart = getFirstDayOfMth(dateInMthStr);
	const mthEnd = getLastDayOfMth(dateInMthStr);
	const eowSeries = getEndOfWkSeries(mthStart, mthEnd);
	console.log('mthStart', mthStart);
	console.log('mthStart', getFirstDayOfMth(dateInMthStr, 'yyyy-MM-dd'));
	const frontBack = getStartEndDateFromEowSeries(eowSeries, 'yyyy-MM-dd');
	return {
		eowFields: formatDateObjArray(eowSeries, 'yyyy-MM-dd'),
		startDate: frontBack[0],
		endDate: frontBack[1],
	};
};
exports.getMonthViewDates = getMonthViewDates;

/**
 * Assemble all dates required for a week view of days
 * This is every day for a week ending
 * provide a yyyy-mm-dd string date
 * @param {*} dateStr
 * return
 {
  dayOfWkFieldsStr: [
    '2020-10-26',
    '2020-10-27',
    '2020-10-28',
    '2020-10-29',
    '2020-10-30',
    '2020-10-31',
    '2020-11-01'
  ],
  startDateStr: '2020-10-26',
  endDateStr: '2020-11-01'

 */
const getWeekViewDates = (dateInWkStr) => {
	const wkEnd = getLastDayOfWk(dateInWkStr);
	const wkStart = add(wkEnd, { days: -6 });
	const daysOfWkSeries = getDaysInWeekEndingSeries(wkStart, wkEnd);
	const daysOfWkSeriesF = formatDateObjArray(daysOfWkSeries, 'yyyy-MM-dd');
	return {
		dayOfWkFieldsStr: daysOfWkSeriesF,
		startDateStr: daysOfWkSeriesF[0],
		endDateStr: daysOfWkSeriesF[daysOfWkSeriesF.length - 1],
	};
};
exports.getWeekViewDates = getWeekViewDates;

/**
 * get a date xx days prior to today
 * @param {*} intervalDays
 * @param {*} formatStr
 */
const getDateFromDaysAgo = (intervalDays, formatStr = null) => {
	const dateObj = new Date();
	const theDateObj = add(dateObj, { days: -intervalDays });
	if (formatStr != null) {
		return formatInTimeZone(theDateObj, formatStr, 'UTC');
	}
	return theDateObj;
};
exports.getDateFromDaysAgo = getDateFromDaysAgo;

const getYesterday = (dateObj, formatStr = null) => {
	return getDateFromDaysAgo(1, formatStr);
};
exports.getYesterday = getYesterday;

/**
 * This parse is a workaround for legacy code which set a null date as '0000-00-00 00:00:00'.
 * This date string breaks lots of js libraries and code as the standard is null
 * @param {*} sessionEnd - from the mysql sessions.SessionEnd
 *
 * returns Null or input date string
 */
const parseSessionEndDate = (sessionEnd) => {
	if (
		sessionEnd === '0000-00-00 00:00:00' ||
		sessionEnd === undefined ||
		sessionEnd === null
	) {
		return null;
	} else {
		return sessionEnd;
	}
};

exports.parseSessionEndDate = parseSessionEndDate;

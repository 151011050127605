import React from 'react'; //useEffect, //useState,

import Loading from 'components/loading.js';

import Form from 'react-bootstrap/Form';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
//import Alert from 'react-bootstrap/Alert';

/**
 * Clients are one of the first loads on the system as they define authorisation
 * to the data for various businesses.
 *
 * The Client data load is a SINGLE SHOT as the app loads. It will not load again.
 * If the auth settings are changed on the server the changes on the client will not
 * be seen until the user reloads the app and logs in again.
 *
 * @param {*} props
 */

export const ClientSelect = (props) => {
	const { handleChange, clientLoading, clientId, clientIds, lock } = props;

	if (clientIds === undefined) {
		return null;
	} else if (clientIds.length < 1) {
		return <h4>Error: No clients for user account - contact administrator</h4>;
	} else if (clientIds.length === 1) {
		return (
			<Row className="inputRow">
				<Col sm="3">
					<label className="dropdownLabel">Account:</label>
				</Col>
				<Col sm="9">
					<p style={{ paddingTop: '0.375em' }}>{clientIds[0].TradingName}</p>
				</Col>
			</Row>
		);
	} else {
		return (
			<Row className="inputRow">
				<Col sm="3">
					<label className="dropdownLabel">Account:</label>
				</Col>
				<Col sm="9">
					{clientLoading ? (
						<Loading loading={clientLoading} />
					) : (
						<Form.Control
							as="select"
							style={{ appearance: 'listbox' }}
							onChange={(evt) => handleChange(evt)}
							value={clientId}
							{...(lock && { disabled: true })}
						>
							{clientIds.map((row, idx) => {
								return (
									<option key={idx} value={row.ClientID}>
										{row.TradingName} [id:{row.ClientID}]
									</option>
								);
							})}
						</Form.Control>
					)}
				</Col>
			</Row>
		);
	}
};

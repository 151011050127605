import API from 'services/api.js';
import {
	URL_GET_CLIENT_IDS,
	URL_CLIENT_USERS,
	URL_PASSWORD_RESET,
} from 'services/urls.js';

export type ClientDetails = {
	ClientID: number;
	TradingName: string;
	ZohoCrmId?: string;
	link?: string;
	ZohoNameUpdate?: string;
};
export type UserDetails = {
	email: string;
	role: string;
	first_name?: string;
	last_name?: string;
	phone?: string;
	dailyEmail?: boolean;
};
export type GetUserResult = {
	email: string;
	id: number | null;
	role: string | null;
	canEdit: boolean;
	name?: string;
	link?: string;
};

export const actionFetchUserList = (
	client_id: number
): Promise<GetUserResult[]> => {
	return API.get(URL_CLIENT_USERS, {
		params: { clientId: client_id },
	})
		.then((resp) => {
			return resp.data;
		})
		.catch(() => {
			return [];
		});
};

export const actionAddUser = (
	client_id: number,
	details: UserDetails
): Promise<boolean> => {
	return API.post(URL_CLIENT_USERS, details, {
		params: { clientId: client_id },
	}).then((resp) => {
		return resp.status === 200;
	});
};

export const actionUpdateUserRole = (
	client_id: number,
	email: string,
	role: string
): Promise<boolean> => {
	const body = { email, role };
	return API.post(URL_CLIENT_USERS, body, {
		params: { clientId: client_id },
	}).then((resp) => {
		return resp.status === 200;
	});
};

export const actionRemoveUser = (
	client_id: number,
	email: string
): Promise<boolean> => {
	return API.delete(URL_CLIENT_USERS, {
		params: { clientId: client_id },
		data: { email },
	}).then((resp) => {
		console.log(`delete user response ${resp.status} ${resp.data}`);
		return resp.status === 200;
	});
};
export const actionResetPassword = (email: string): Promise<boolean> => {
	return API.post(URL_PASSWORD_RESET, '', {
		params: { user: email },
	}).then((resp) => {
		return resp.status === 200;
	});
};

export const actionFetchAccountDetails = (
	client_id: number
): Promise<ClientDetails[]> => {
	return API.get(URL_GET_CLIENT_IDS, {
		params: { clientId: client_id },
	}).then((resp) => {
		return resp.data;
	});
};
export const actionFetchZohoAccount = (
	zoho_crm_id: bigint
): Promise<ClientDetails[]> => {
	return API.get(URL_GET_CLIENT_IDS, {
		params: { zohoCrmId: zoho_crm_id },
	}).then((resp) => {
		return resp.data;
	});
};
export const actionSyncAccountFromZoho = (
	zoho_crm_id: bigint
): Promise<boolean> => {
	return API.post(URL_GET_CLIENT_IDS, {
		zohoCrmId: zoho_crm_id.toString(),
	}).then((resp) => {
		console.log(`update account name response ${resp.status}`);
		return resp.status === 200;
	});
};

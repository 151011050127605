export const SET_NEXT_PATH = 'SET_NEXT_PATH';
export const RESET_NEXT_PATH = 'RESET_NEXT_PATH';
export const SET_PREV_PATH = 'SET_PREV_PATH';
export const RESET_PREV_PATH = 'RESET_PREV_PATH';

/**
 * use:	import { actionSetNextPath, actionResetNextPath, actionSetPrevPath, actionResetPrevPath} from '#app/compunents/utils/actions.js';
 *
 * Next path is destination of done button etc.
 * Prev path is unwinding from an error etc.
 */
//use:	import { actionSetNextPath, actionResetNextPath, actionSetPrevPath, actionResetPrevPath} from '#app/compunents/utils/actions.js';

// const actionSetNextPath = url => ({
// 	type: 'SET_NEXT_PATH',
// 	payload: url
// })

// const actionResetNextPath = () => ({
// 	type: 'RESET_NEXT_PATH',
// 	payload: ''
// })

// const actionSetPrevPath = url => ({
// 	type: 'SET_PREV_PATH',
// 	payload: url
// })

// const actionResetPrevPath = () => ({
// 	type: 'RESET_PREV_PATH',
// 	payload: ''
// })

import React from 'react';
import Card from 'react-bootstrap/Card';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import Header from 'components/header.js';
import Loading from 'components/loading.js';
import Footer from 'components/footer.js';

const LoginLogoutFlashScreen = (props) => {
	const { action } = props;
	let cardTitle = 'Logging out - please wait....';
	if (action === 'login') {
		cardTitle = 'Logging in - please wait....';
	}
	return (
		<>
			<Header hideMenu={true}>{/* <BC trail="Home" /> */}</Header>
			<Container fluid className="body">
				<Row>
					<Col sm="12" style={{ textAlign: 'center' }}>
						<Card body>
							<Card.Title>{cardTitle}</Card.Title>
							<Card.Body>
								<Loading loading={true} size="" />
							</Card.Body>
						</Card>
					</Col>
				</Row>
			</Container>
			<Container fluid>
				<Row>
					<Col sm="12">
						<Footer />
					</Col>
				</Row>
			</Container>
		</>
	);
};

export default LoginLogoutFlashScreen;

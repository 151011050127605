import axios from 'axios';
import { checkTokenExpiration } from 'pages/auth/redux';
import { baseApiUrl } from './urls.js';
import { store } from 'index';

axios.defaults.xsrfHeaderName = 'X-CSRFTOKEN';
axios.defaults.xsrfCookieName = 'XCSRF-TOKEN';

const API = axios.create({
	baseURL: baseApiUrl,
}); //create instance

API.interceptors.request.use(
	function (config) {
		//const tokens = useSelector(state => state.tokens);
		const state = store.getState();
		checkTokenExpiration();
		const newHeaders = {
			...config.headers,
			Authorization: `Bearer ${state.tokens.access_token}`,
		};
		const newConfig = { ...config, headers: newHeaders };
		return newConfig;
	},
	function (error) {
		return Promise.reject(error);
	}
);

API.interceptors.response.use(
	function (response) {
		// Any status code that lie within the range of 2xx cause this function to trigger
		return response;
	},
	function (error) {
		//:TODO: not getting Access-Control-Allow-Headers back from options call
		console.log('error', error);
		return Promise.reject(error);
	}
);
export default API;

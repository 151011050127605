import API from 'services/api.js';
import { URL_GET_CLIENT_IDS } from 'services/urls.js';
import {
	actionSetMessage,
	actionResetMessages,
} from 'components/messages/actions.js';
import { AppDispatch } from 'index';

const SET_CLIENT_ID = 'SET_CLIENT_ID';
const SET_CLIENT_IDS = 'SET_CLIENT_IDS';
const RESET_CLIENT_IDS = 'RESET_CLIENT_IDS';
const SET_CLIENT_IDS_LOADED = 'SET_CLIENT_IDS_LOADED';

export type ClientItem = {
	ClientID: number;
	TradingName: string | null;
};

//actionFetchWeighersSuccess
export const actionSetClientId = (data: number) => {
	return {
		type: SET_CLIENT_ID,
		data,
	};
};

export const actionFetchClientIds = (
	setClientLoading: (l: boolean) => void
) => {
	return (dispatch: AppDispatch) => {
		dispatch(actionResetMessages());
		return API.get(URL_GET_CLIENT_IDS)
			.then((response) => {
				return response.data;
			})
			.catch((error) => {
				console.log('error', error);
				dispatch(actionSetMessage("Error fetching Client ID's: " + error));
				return [];
			})
			.then((resp: ClientItem[]) => {
				//load previous settings
				dispatch(actionSetClientIdsLoaded());

				if (resp.length > 0) {
					dispatch(actionFetchClientIdsSuccess(resp));
				}

				const clientId = Number(localStorage.getItem('clientId'));

				if (clientId) {
					const doesClientItExist = resp.filter((item) => {
						return item.ClientID === clientId;
					});
					if (doesClientItExist.length > 0) {
						//only set previous default it it exists in our client list
						dispatch(actionSetClientId(clientId));
					} else {
						//last client loaded is no longer authorized for client
						if (resp.length > 0) {
							//	set first item
							dispatch(actionSetClientId(resp[0].ClientID));
							localStorage.setItem('clientId', resp[0].ClientID.toString()); //remember for next session
						}
					}
				} else if (resp.length > 0) {
					//	set first item
					dispatch(actionSetClientId(resp[0].ClientID));
					localStorage.setItem('clientId', resp[0].ClientID.toString()); //remember for next session
				}
				setClientLoading(false);
			});
	};
};

export const actionFetchClientIdsSuccess = (data: ClientItem[]) => {
	return {
		type: SET_CLIENT_IDS,
		data,
	};
};

export const actionResetClientIds = () => {
	return { type: RESET_CLIENT_IDS };
};

export const actionSetClientIdsLoaded = () => {
	return {
		type: SET_CLIENT_IDS_LOADED,
	};
};

/**
 * Can be set but not unset
 */
export function clientIdsLoaded(state = false, action: { type?: string }) {
	switch (action.type) {
		case SET_CLIENT_IDS_LOADED:
			return true;
		default:
			return state;
	}
}

export function clientId(state = 0, action: { type?: string; data?: number }) {
	switch (action.type) {
		case SET_CLIENT_ID:
			return action.data;
		default:
			return state;
	}
}

export function clientIds(
	state: ClientItem[] = [],
	action: { type?: string; data?: ClientItem[] }
) {
	switch (action.type) {
		case SET_CLIENT_IDS:
			return action.data;
		case RESET_CLIENT_IDS:
			return [];
		default:
			return state;
	}
}

import Breadcrumb from 'react-bootstrap/Breadcrumb';
import { Link } from 'react-router-dom';

const OPWBreadcrumb = (props) => {
	let trailObj;
	const breadcrumbItemsArr = [];

	// Was an array of links passed in? Or just free text?
	try {
		trailObj = JSON.parse(props.trail);
	} catch (e) {}
	if (typeof trailObj !== 'undefined') {
		//An array of links was passed in
		// Expected format is [["label", "/link"], ["label2", ""], ["label3", "/link3"], etc]
		// Empty link value results in no link
		// Final value in array will become 'active'
		let crumbItem = '';
		for (let crumbIndex = 0; crumbIndex < trailObj.length; crumbIndex++) {
			//For each crumb of the crumb trail array
			//Construct this breadcrumb item
			const label = trailObj[crumbIndex][0];
			const link = trailObj[crumbIndex][1];
			if (link !== '') {
				//Only add link if there is a link value
				crumbItem = (
					<Breadcrumb.Item
						key={crumbIndex}
						linkAs={Link}
						linkProps={{ to: link }}
						active={crumbIndex === trailObj.length - 1}
					>
						{label}
					</Breadcrumb.Item>
				);
			} else {
				crumbItem = (
					<Breadcrumb.Item
						key={crumbIndex}
						active={crumbIndex === trailObj.length - 1}
					>
						{label}
					</Breadcrumb.Item>
				);
			}

			//Add the item to the breadcrumb items array
			breadcrumbItemsArr.push(crumbItem);
		}
	} else {
		//Free text was passed in
		breadcrumbItemsArr.push(
			<Breadcrumb.Item key="1" active>
				{props.trail}
			</Breadcrumb.Item>
		);
	}
	return (
		<Breadcrumb className="breadcrumb-div">{breadcrumbItemsArr}</Breadcrumb>
	);
};

export default OPWBreadcrumb;

import Spinner from 'react-bootstrap/Spinner';
import Toggle from 'react-toggle';
import { EmailSubsModel } from './model';

export const DailyEmailToggle = (props: { model: EmailSubsModel }) => {
	const { model } = props;
	return (
		<>
			{model.emails && (
				<Toggle
					className="blue-toggle"
					icons={false}
					checked={!!model.emails.dailyEmail}
					disabled={model.isLoading}
					onChange={(evt: any) => {
						model.changeDailyEmail(evt.target.checked);
					}}
				/>
			)}
			&nbsp;
			{model.isLoading && (
				<Spinner
					animation="border"
					style={{
						color: '#007bff',
						width: '1.5rem',
						height: '1.5rem',
					}}
				/>
			)}
		</>
	);
};

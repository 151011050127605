import Header from 'components/header.js';
import Footer from 'components/footer.js';
import OPWBreadcrumb from 'components/breadcrumb.js';
import Container from 'react-bootstrap/Container';
import Card from 'react-bootstrap/Card';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import 'react-toggle/style.css';

import { useSelector } from 'react-redux';
import { QueryClient, QueryClientProvider } from 'react-query';
import { useEmailSubsModel } from 'components/dailyEmail/model';
import { DailyEmailToggle } from 'components/dailyEmail/dailyEmailToggle';

const queryClient = new QueryClient();

const DailyEmailRow = (props) => {
	const currentUser = useSelector((state) => state.currentUser);
	const has_api_token = currentUser.username.length > 3;
	const model = useEmailSubsModel(has_api_token ? currentUser.username : null);
	return (
		<Row className="inputRow">
			<Col sm="4">Daily Email</Col>
			<Col sm="4">
				<DailyEmailToggle model={model} />
			</Col>
		</Row>
	);
};

export function EmailSubscriptions(props) {
	return (
		<QueryClientProvider client={queryClient}>
			<Header>
				<OPWBreadcrumb trail='[["Home", "/"], ["Email subscriptions", ""]]' />
			</Header>
			<Container fluid className="body">
				<Card body>
					<Card.Title>Email Subscriptions</Card.Title>
					<Card.Body>
						<DailyEmailRow />
					</Card.Body>
				</Card>
			</Container>
			<Container fluid>
				<Footer />
			</Container>
		</QueryClientProvider>
	);
}

import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import Header from 'components/header.js';
import Footer from 'components/footer.js';
import Container from 'react-bootstrap/Container';
import Card from 'react-bootstrap/Card';
import OPWBreadcrumb from 'components/breadcrumb.js';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { CheckAuth } from 'pages/auth/CheckAuth';
import { ClientSessionSelect } from 'components/clientSessionSelect/clientSessionSelect.js';
import { TableStyles } from 'components/tableControl.js';
import Loading from 'components/loading.js';
import Button from 'react-bootstrap/Button';
import { AiOutlineDownload } from 'react-icons/ai';
import {
	actionGetMethaneList,
	actionDownloadMethaneData,
	actionDownloadMethaneReport,
} from './actions';
import { saveAs } from 'file-saver';
import Spinner from 'react-bootstrap/Spinner';
import { actionSetMessage } from 'components/messages/actions';

const MetaInfoTable = (props) => {
	const { weigherId, timezone } = props;

	return (
		<table cellPadding={3}>
			<tbody>
				<tr>
					<td>Weigher:</td>
					<td>{weigherId}</td>
				</tr>
				<tr>
					<td>Timezone:</td>
					<td>{timezone}</td>
				</tr>
			</tbody>
		</table>
	);
};
const MethaneDayTable = (props) => {
	const { rows, downloadActiveDate, handleDownloadRaw, handleDownloadReport } =
		props;

	const table_rows = rows.map((r) => {
		return (
			<tr>
				<td>{r.date}</td>
				<td>{r.records}</td>
				<td>
					{downloadActiveDate === r.date ? (
						<Spinner
							as="span"
							animation="border"
							size="sm"
							role="status"
							aria-hidden="true"
						/>
					) : (
						<>
							<Button
								variant="outline-primary"
								size="sm"
								disabled={downloadActiveDate}
								onClick={() => handleDownloadRaw(r.date)}
							>
								<AiOutlineDownload /> Raw data
							</Button>
							&nbsp;
							<Button
								variant="outline-primary"
								size="sm"
								disabled={downloadActiveDate}
								onClick={() => handleDownloadReport(r.date)}
							>
								<AiOutlineDownload /> Metrics report
							</Button>
						</>
					)}
				</td>
			</tr>
		);
	});

	return (
		<TableStyles className="sessionDataTable">
			<table style={{ margin: '0 auto' }}>
				<thead>
					<tr>
						<th>Date</th>
						<th>Records</th>
						<th>Download</th>
					</tr>
				</thead>
				<tbody>
					{table_rows.length > 0 ? (
						table_rows
					) : (
						<tr>
							<td colSpan={3} style={{ textAlign: 'center' }}>
								No data
							</td>
						</tr>
					)}
				</tbody>
			</table>
		</TableStyles>
	);
};

export const Methane = (props) => {
	const session = useSelector((state) => state.session);
	const [loading, setLoading] = useState(false);
	const [data, setData] = useState(null);
	const [downloading, setDownloading] = useState(null);

	useEffect(() => {
		document.title = 'Methane download';
	});
	// fetch methane list when session changes
	useEffect(() => {
		if (!session) return;

		setLoading(true);
		setData(null);
		actionGetMethaneList(session.SessionID)
			.then((response) => {
				if (response.data.length > 0) setData(response.data[0]);
				else setData(null);
			})
			.catch((err) => {
				console.log(err);
			})
			.finally(() => {
				setLoading(false);
			});
	}, [session]);

	const handle_download_raw = async (date) => {
		setDownloading(date);
		try {
			const { data } = await actionDownloadMethaneData(session.SessionID, date);
			await saveAs(data, `methane data ${session.SessionID} ${date}.csv`);
		} catch (err) {
			actionSetMessage('Server error');
		} finally {
			setDownloading(null);
		}
	};
	const handle_download_report = async (date) => {
		setDownloading(date);
		try {
			const { data } = await actionDownloadMethaneReport(
				session.SessionID,
				date
			);
			await saveAs(data, `methane report ${session.SessionID} ${date}.csv`);
		} catch (err) {
			actionSetMessage('Server error');
		} finally {
			setDownloading(null);
		}
	};

	return (
		<>
			<Header>
				<OPWBreadcrumb trail='[["Home", "/"], ["reports", "/"], ["methane", ""]]' />
			</Header>
			<Container fluid className="body">
				<CheckAuth groupName={'user'} />
				<Card className={'no-print'} body>
					<Card.Title>Methane download</Card.Title>
					<Card.Body>
						<Row>
							<Col sm={6}>
								<ClientSessionSelect
									lock={loading}
									layout={'select'}
									hideSessionSelect={false}
									handleBeforeSessionChange={() => {}}
									includeYardWeights={false}
								/>
							</Col>
							<Col sm={4}>
								<Loading loading={loading} />
								{data && <MetaInfoTable {...data} />}
							</Col>
						</Row>
					</Card.Body>
				</Card>
				{data && (
					<MethaneDayTable
						rows={data.methane}
						downloadActiveDate={downloading}
						handleDownloadRaw={handle_download_raw}
						handleDownloadReport={handle_download_report}
					/>
				)}
				<div>&nbsp;</div>
			</Container>
			<Container fluid>
				<Row>
					<Col sm="12">
						<Footer />
					</Col>
				</Row>
			</Container>
		</>
	);
};

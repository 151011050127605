import Form from 'react-bootstrap/Form';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Loading from 'components/loading.js';

export const GenericListSelect = (props) => {
	/*
	Select from an array/list
	The description and the key are the same.

	*/
	const {
		id,
		handleChange,
		value,
		controlStyle,
		label,
		dataArray,
		cols,
		required,
		pattern,
		invalidMsg,
		loading,
		lock,
	} = props;
	const handleInvalid = (evt) => {
		evt.persist();
		evt.target.setCustomValidity(invalidMsg);
	};
	if (dataArray === undefined || dataArray.length < 1) {
		return (
			<Row className="inputRow">
				<Col sm={cols.lhs}>
					{label}
					<Required required={required} />:
				</Col>
				<Col sm={cols.rhs}>No {label.toLowerCase()} data available</Col>
			</Row>
		);
	} else {
		return (
			// <Alert variant={'secondary'} style={{backgroundColor: 'rgb(233,236,239)'}}>
			// <Row style={{width: '18em'}}>
			<Row className="inputRow">
				{label.length > 0 && (
					<Col sm={cols.lhs}>
						{label}
						<Required required={required} />:
					</Col>
				)}
				<Col sm={cols.rhs}>
					<Form.Control
						id={id}
						as="select"
						onChange={(evt) => handleChange(evt)}
						value={value}
						//defaultValue = { value }
						style={{ appearance: 'listbox', ...controlStyle }}
						pattern={pattern}
						onInvalid={handleInvalid}
						{...(lock && { disabled: true })}
					>
						<option key={-1} value={''}>
							{'Select ' + label.toLowerCase() + '...'}
						</option>
						{dataArray.map((val) => {
							return (
								<option key={val} value={val}>
									{val}
								</option>
							);
						})}
					</Form.Control>
					<Loading loading={loading} />
				</Col>
			</Row>
			// </Alert>
		);
	}
};

const Required = (props) => {
	if (!props.required) return null;

	return (
		<span style={{ color: 'red' }}>
			<sup>*</sup>
		</span>
	);
};

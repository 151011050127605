import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';

import { CheckAuth } from 'pages/auth/CheckAuth';
import Header from 'components/header.js';
import Footer from 'components/footer.js';
import Container from 'react-bootstrap/Container';
import Breadcrumb from 'react-bootstrap/Breadcrumb';
import Button from 'react-bootstrap/Button';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import Spinner from 'react-bootstrap/Spinner';

import Loading from 'components/loading.js';
import Form from 'react-bootstrap/Form';
import { ClientSessionSelect } from 'components/clientSessionSelect/clientSessionSelect.js';

//import { TableControl, TableStyles } from 'components/tableControl.js'

import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Alert from 'react-bootstrap/Alert';
import { Link } from 'react-router-dom';
import {
	actionFetchSetting,
	actionSetSetting,
	actionSendTestClientEmail,
} from './actions.js';

const TITLE = 'Manage Daily Email';
//const showRecCount = 50	// default records to display
const BC = () => {
	return (
		<Breadcrumb>
			<Breadcrumb.Item linkAs={Link} linkProps={{ to: '/' }}>
				Home
			</Breadcrumb.Item>
			<Breadcrumb.Item linkAs={Link} linkProps={{ to: '/staff' }}>
				Staff
			</Breadcrumb.Item>
			<Breadcrumb.Item active>Daily Email</Breadcrumb.Item>
		</Breadcrumb>
	);
};

// pagination
//https://medium.com/better-programming/an-introduction-to-react-table-6ebd34d8059e
export const DailyEmail = (props) => {
	/**
	 *
	 *
	 *
	 */

	//const [loading, setLoading] = useState(false);
	//const dispatch = useDispatch()

	useEffect(() => {
		document.title = TITLE;
	}, []);

	const cols = { lhs: 3, rhs: 9 };

	return (
		<Template
		//loading = { loading }
		// handleReload = { handleReload }
		>
			<MessageOfTheDay cols={cols} />
			<TestDailyEmail cols={cols} />
		</Template>
	);
};

const Template = (props) => (
	<Container fluid>
		<CheckAuth groupName={'superuser'} />
		<Header>
			<BC />
		</Header>
		{/* <ParamPanel
			loading = { props.loading }
			handleReload = { props.handleReload }
		/> */}
		{props.children}
		<Footer />
	</Container>
);

const TestDailyEmail = (props) => {
	const { cols } = props;
	const [recipEmail, setRecipEmail] = useState(''); //	local data for graph or report
	const [response, setResponse] = useState('');
	const [sendLoading, setSendLoading] = useState(false);
	const [sendLoadingDev, setSendLoadingDev] = useState(false);
	const [loading, setLoading] = useState(false);

	const clientId = useSelector((state) => state.clientId);
	const KEY = 'dailyClientEmailTestRecipient';

	useEffect(() => {
		// run once in intial page load
		async function fetchData() {
			const resp = await actionFetchSetting(KEY);
			handleResponse(resp);
		}
		fetchData();
	}, []);

	const handleRecipChange = (evt) => {
		evt.persist();
		setRecipEmail(evt.target.value);
	};

	const handleResponse = (response) => {
		setRecipEmail(response[0].value);
	};

	const handleSend = (codebase) => {
		async function send() {
			await actionSetSetting(KEY, recipEmail, setLoading); //save the recipient to the db
			//dispatch(actionResetMessages())
			let resp = null;
			if (codebase === 'dev') {
				resp = await actionSendTestClientEmail(
					recipEmail,
					clientId,
					codebase,
					setSendLoadingDev
				); // send the email
			} else {
				//prod
				resp = await actionSendTestClientEmail(
					recipEmail,
					clientId,
					codebase,
					setSendLoading
				); // send the email
			}
			console.log('resp', resp);
			//setResponse(JSON.stringify(resp));
			try {
				setResponse(JSON.parse(resp[0].msg));
			} catch (error) {
				console.log('error'.toString, error);
			}
			setResponse(resp);
		}

		setResponse('');
		if (codebase === 'dev') {
			setSendLoadingDev(true);
		} else {
			setSendLoading(true);
		}
		send();
	};
	return (
		<Alert
			className="sessSummary container"
			variant={'secondary'}
			style={{ backgroundColor: 'rgb(233,236,239)' }}
		>
			<h5>Test Daily Email</h5>
			{/* <span className="startDate">sssssss</span> */}

			<Row>
				<Col sm={cols.lhs}>Recipient email</Col>
				<Col sm={cols.rhs}>
					<Form.Control
						onChange={(evt) => handleRecipChange(evt)}
						value={recipEmail}
						// { ...(lock && { disabled: true }) }
						required
					/>
					<Loading loading={loading} />
				</Col>
			</Row>
			<ClientSessionSelect
				layout="filtered-list"
				showOpenSessionsOnly={false}
				weigherId={null}
				//handleFilteredChange = { handleFilteredChange }
				handleBeforeSessionChange={() => {}}
				hideSessionSelect={true}
				lock={false}
			/>
			<Row>
				<Col sm={cols.lhs}>Response: {JSON.stringify(response)}</Col>
				<Col sm={cols.rhs}></Col>
			</Row>
			<Row>
				<Col style={{ textAlign: 'right' }}>
					<ButtonGroup>
						<Button
							variant="info"
							onClick={() => handleSend('prod')}
							{...(sendLoading && { disabled: true })}
						>
							{sendLoading && (
								<>
									<Spinner
										as="span"
										animation="border"
										size="sm"
										role="status"
										aria-hidden="true"
									/>
									&nbsp;
								</>
							)}
							Send Test Email (prod)
						</Button>
						<br />
						<br />
						<Button
							variant="info"
							onClick={() => handleSend('dev')}
							{...(sendLoadingDev && { disabled: true })}
						>
							{sendLoadingDev && (
								<>
									<Spinner
										as="span"
										animation="border"
										size="sm"
										role="status"
										aria-hidden="true"
									/>
									&nbsp;
								</>
							)}
							Send Test Email (dev)
						</Button>
					</ButtonGroup>
				</Col>
			</Row>
		</Alert>
	);
};

const MessageOfTheDay = (props) => {
	const { cols } = props;
	const [msgOfTheDay, setMsgOfTheDay] = useState(''); //	local data for graph or report
	const [modified, setModified] = useState(false);
	const [loading, setLoading] = useState(false);
	const KEY = 'dailyClientEmailMsg';

	useEffect(() => {
		//	run on intial page load
		async function fetchData() {
			const resp = await actionFetchSetting(KEY);
			handleResponse(resp);
		}
		fetchData();
	}, []);

	console.log('loading', loading);
	// useEffect(() => {
	// 	async function fetchData() {
	// 		const resp = await actionFetchSetting(KEY)
	// 		handleResponse(resp)
	// 	}
	// 	if (msgOfTheDay === ''){
	// 		fetchData();
	// 	}
	// },[msgOfTheDay])
	const handleMsgChange = (evt) => {
		evt.persist();
		setModified(true);
		setMsgOfTheDay(evt.target.value);
	};

	const handleResponse = (response) => {
		setMsgOfTheDay(response[0].value);
	};

	const handleReload = () => {
		async function fetchData() {
			const resp = await actionFetchSetting(KEY);
			handleResponse(resp);
		}
		fetchData();
	};

	const handleSave = () => {
		actionSetSetting(KEY, msgOfTheDay, setLoading);
		setModified(false);
	};

	return (
		<Alert
			className="sessSummary container"
			variant={'secondary'}
			style={{ backgroundColor: 'rgb(233,236,239)' }}
		>
			<h5>Email message of the day</h5>
			<p>
				This message will be printed at the top of the client daily email. It is
				highly recommended to send a test email and check the formatting after
				changes. These emails are sent automatically every night.
			</p>
			{/* <span className="startDate">sssssss</span> */}

			<Row>
				<Col sm={cols.lhs}>Message</Col>
				<Col sm={cols.rhs}>
					<Form.Control
						id="msgOfTheDay"
						as="textarea"
						rows={3}
						onChange={(evt) => handleMsgChange(evt)}
						value={msgOfTheDay}
					/>
					<br />
				</Col>
			</Row>
			<Row>
				<Col sm={cols.lhs}></Col>
				<Col sm={cols.rhs} style={{ textAlign: 'right' }}>
					<Button
						variant="info"
						onClick={() => handleSave()}
						{...(!modified && { disabled: true })}
					>
						Save Message
						<Loading loading={loading} />
					</Button>
					<br />
					<br />
					<Button variant="info" onClick={() => handleReload()}>
						Reload data
					</Button>
					<br />
					<br />
				</Col>
			</Row>
		</Alert>
	);
};

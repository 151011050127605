import React from //useState,
//useEffect,
//useMemo,
'react';
import { GenericListSelect } from './genericListSelect';

const TimePeriodSelect = (props) => {
	const { value, cols, handleChange, required, controlStyle, lock } = props;
	const types = ['day', 'week', 'month'];
	return (
		<>
			<GenericListSelect
				id="timePeriod"
				label="View data by"
				dataArray={types}
				cols={cols}
				controlStyle={controlStyle}
				handleChange={(evt) => handleChange(evt)}
				value={value}
				required={required}
				lock={lock}
				//pattern = {'^\d+$'}	// integer
				//pattern = {'^[a-zA-Z]+[a-zA-Z0-9_ -]+$'}
				//invalidMsg = 'Select a Breed type!'
			/>
		</>
	);
};

const TimePeriodSelectV2 = (props) => {
	const { value, cols, handleChange, required, controlStyle, lock } = props;
	const types = [
		'Last Day',
		'Last 7 Days',
		'Last 14 Days',
		'Last 30 Days',
		'Last 90 Days',
		'Last 180 Days',
		'Custom',
	];
	return (
		<>
			<GenericListSelect
				id="timePeriodV2"
				label="Time Period"
				dataArray={types}
				cols={cols}
				controlStyle={controlStyle}
				handleChange={(evt) => handleChange(evt)}
				value={value}
				required={required}
				lock={lock}
				//pattern = {'^\d+$'}	// integer
				//pattern = {'^[a-zA-Z]+[a-zA-Z0-9_ -]+$'}
				//invalidMsg = 'Select a Breed type!'
			/>
		</>
	);
};

// export TimePeriodSelectV2;
// export default TimePeriodSelect;
export { TimePeriodSelect, TimePeriodSelectV2 };

import React from 'react';
import { createRoot } from 'react-dom/client';
import 'custom.scss';
import App from 'App.js';
import * as serviceWorker from 'serviceWorker.js';
import { Provider } from 'react-redux'; // provider passes store to every container component
import { createStore, applyMiddleware } from 'redux';
import { thunk } from 'redux-thunk';
import multi from 'redux-multi'; //allows multi actions array
import 'bootstrap/dist/css/bootstrap.min.css';
import { rootReducer, initialState } from 'reducers';

export const store = createStore(
	rootReducer,
	initialState,
	applyMiddleware(thunk, multi)
);

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;

const container = document.getElementById('root');
const root = createRoot(container!);
root.render(
	<Provider store={store}>
		<App />
	</Provider>
);

serviceWorker.unregister();
